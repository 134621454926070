import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { IconButton, Tooltip } from '@mui/material';
import { IconDeleteStyle } from 'ui-component/style/button';
import { IconTrashX } from '@tabler/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function KonfirmasiDelete({ onClick, title, deskripsi }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (onClick) {
            onClick();
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Tooltip placement="top" title="Hapus">
                <IconButton size="small" sx={IconDeleteStyle} onClick={handleClickOpen}>
                    <IconTrashX color="#FFFFFF" size={19} />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{title || 'Konfirmasi penghapusan data !'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {deskripsi || 'Yakin ingin mengapus data ini ???'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Batal</Button>
                    <Button onClick={() => handleClose()}>Hapus</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
