/* eslint-disable react/prop-types */
import { Box, Card, CardContent, CardHeader, CircularProgress, Collapse, Fade, Modal, Stack } from '@mui/material';
import { useState } from 'react';
import { IconSend } from '@tabler/icons';
import { ModalStyle1 } from 'ui-component/style/modal';

import ButtonBatalOnModal from 'ui-component/button/ButtonBatalOnModal';
import GridSignedOptions from 'ui-component/grid/GridSignedOptions';
import StackSignedOptions from 'ui-component/stack/StackSignedOptions';
import Buttons from 'ui-component/button/Buttons';
import Loader from 'ui-component/Loader';

const ModalConfSigned = ({
    open,
    payload,
    setPayload,
    disable,
    handleClose,
    handleSubmitTTD,
    handleSubmitTTE,
    handleClick,
    handleDelete,
    onClickForm,
    loading
}) => {
    const [form, setForm] = useState('');

    return (
        <Modal open={open} closeAfterTransition>
            <Fade in={open}>
                <Card sx={{ ...ModalStyle1(), width: '590px' }}>
                    {loading && <Loader />}
                    <Box display="flex" justifyContent="center">
                        <CardHeader title="Konfirmasi Terima" sx={{ paddingBottom: 0 }} />
                    </Box>
                    <CardContent>
                        <GridSignedOptions
                            form={form}
                            onClickTTE={() => {
                                setPayload({
                                    ...payload,
                                    nik: '',
                                    passphrase: '',
                                    dokumen_ttd_basah: ''
                                });
                                setForm('elektronik');
                            }}
                            onClickTTD={() => {
                                setPayload({
                                    ...payload,
                                    nik: '',
                                    passphrase: '',
                                    dokumen_ttd_basah: ''
                                });
                                setForm('basah');
                            }}
                        />
                        <Collapse in={form !== ''} timeout="auto" unmountOnExit>
                            <StackSignedOptions
                                form={form}
                                payload={payload}
                                setPayload={setPayload}
                                handleClick={handleClick}
                                handleDelete={handleDelete}
                                onClickForm={onClickForm}
                            />
                            <Stack direction="row" spacing={1} justifyContent="end" paddingTop={2}>
                                <ButtonBatalOnModal onClick={handleClose} />
                                <Buttons
                                    title="Konfirmasi"
                                    disable={disable}
                                    onClick={form === 'basah' ? handleSubmitTTD : form === 'elektronik' ? handleSubmitTTE : null}
                                    startIcon={
                                        loading ? <CircularProgress size="16px" style={{ color: 'white' }} /> : <IconSend size="16px" />
                                    }
                                />
                            </Stack>
                        </Collapse>
                        {form === '' && <ButtonBatalOnModal onClick={handleClose} />}
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    );
};

export default ModalConfSigned;
