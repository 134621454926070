import React from 'react';
import PropTypes from 'prop-types';
import { ButtonStyle2 } from 'ui-component/style/button';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

// Komponen CustomButton
const CustomButton = ({ variant, onClick, disabled, loading, sx, type, children }) => {
    const utility = useSelector((state) => state.utility);

    return (
        <LoadingButton
            disabled={disabled}
            loading={loading ? utility.loading.button : false}
            variant={variant}
            onClick={onClick}
            sx={sx || ButtonStyle2}
            type={type}
        >
            {children}
        </LoadingButton>
    );
};

CustomButton.propTypes = {
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    onClick: PropTypes.func,
    sx: PropTypes.object,
    type: PropTypes.string,
    children: PropTypes.node.isRequired
};

CustomButton.defaultProps = {
    variant: 'text',
    onClick: () => {},
    sx: {},
    type: 'button'
};

export default CustomButton;
