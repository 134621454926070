import React from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { ClearIcon, OpenCalendarIcon } from 'assets/icons';

const RenderDate = ({ input, label, required = false, meta: { touched, error }, disabled, ...rest }) => {
    const handleChange = (date) => {
        if (date && date.isValid()) {
            input.onChange(date.format('YYYY-MM-DD'));
        } else {
            input.onChange('');
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={label}
                value={input.value ? dayjs(input.value) : null}
                onChange={handleChange}
                format="D MMMM YYYY"
                disabled={disabled}
                slots={{ clearIcon: ClearIcon, openPickerIcon: OpenCalendarIcon }}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        required : required,
                        error: touched && Boolean(error),
                        helperText: touched && error,
                        disabled: disabled,
                        sx: disabled ? { color: 'text.disabled' } : {color: 'inherit'},
                    },
                    actionBar: { actions: ['today', 'clear'] }
                }}
                localeText={{ todayButtonLabel: 'Hari Ini' }}
                {...rest}
            />
        </LocalizationProvider>
    );
};

RenderDate.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    meta: PropTypes.object.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default RenderDate;