/* eslint-disable no-unused-vars */

import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Modal, Stack, TextField, Typography } from '@mui/material';
import { ModalStyle1 } from 'ui-component/style/modal';
import { TextFieldStyle1 } from 'ui-component/style/textfield';
import { IconX } from '@tabler/icons';
import { StyleTitleOnModal } from 'ui-component/style/typography';
import { useTheme } from '@mui/styles';

import ButtonBatalOnModal from 'ui-component/button/ButtonBatalOnModal';
import Buttons from 'ui-component/button/Buttons';
import Loader from 'ui-component/Loader';

const ModalReject2 = ({ open, payload, setPayload, handleSubmit, handleClose, loading = false }) => {
    const theme = useTheme();
    const [disable, setDisable] = useState(true);

    const handleDisable = () => {
        if (payload.alasan_ditolak === '' || !payload.alasan_ditolak) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    };

    useEffect(() => {
        handleDisable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.alasan_ditolak]);

    return (
        <Modal open={open}>
            <Card sx={{ ...ModalStyle1(), width: '500px' }}>
                {loading && <Loader />}
                <CardHeader
                    title={<Typography sx={{ ...StyleTitleOnModal(), mb: 0 }}>Tolak Pengajuan</Typography>}
                    sx={{ paddingBottom: 0 }}
                />
                <CardContent>
                    <Stack spacing={2} direction="column">
                        <TextField
                            autoFocus
                            required
                            multiline
                            minRows={4}
                            maxRows={4}
                            type="text"
                            label="Alasan Penolakan"
                            value={payload.alasan_ditolak}
                            onChange={(e) => setPayload({ ...payload, alasan_ditolak: e.target.value })}
                            sx={TextFieldStyle1}
                        />
                        <Stack direction="row" spacing={1} justifyContent="end" paddingTop={4}>
                            <ButtonBatalOnModal onClick={handleClose} />
                            <Buttons
                                title="Tolak"
                                disable={disable}
                                onClick={() => {
                                    handleSubmit();
                                    handleClose();
                                }}
                                startIcon={loading ? <CircularProgress size="18px" style={{ color: 'white' }} /> : <IconX size="18px" />}
                                sx={{
                                    backgroundColor: theme.palette.error.main,
                                    color: 'white',
                                    '&:hover': { backgroundColor: theme.palette.error.main, color: 'white' }
                                }}
                            />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default ModalReject2;
