/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import axios from 'axios';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import useScriptRef from 'hooks/useScriptRef';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ForgotPassword from 'ui-component/card/ForgotPassword';
import AnimateButton from 'ui-component/extended/AnimateButton';

import { borderRadius } from 'reduxStore/constant';
import { Formik } from 'formik';
import { storageKey } from 'reduxStore/constant';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';

// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ open3, setOpen, setOpen2, setOpen3, handleClose3, isUnderMaintenance, ...others }) => {
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const theme = useTheme();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validationSchema = Yup.object().shape({
        nip: Yup.string().max(255).required('NIP harus diisi'),
        password: Yup.string().max(255).required('Kata Sandi harus diisi')
    });

    // const emailValidation = (value) => {
    //     if (!value.includes('@')) {
    //         return true;
    //     }
    //     return Yup.string().email('Harus alamat email yang valid').validate(value);
    // };

    // const validationSchema = Yup.object().shape({
    //     email: Yup.string()
    //         .test('email', 'Harus alamat email yang valid', emailValidation)
    //         .max(255)
    //         .required('Alamat email diperlukan. Dapat ditulis tanpa domain (@kemlu.go.id)'),
    //     password: Yup.string().max(255).required('Kata sandi diperlukan')
    // });

    return (
        <>
            {open3 === 'lupaKataSandi' && <ForgotPassword open={open3 === 'lupaKataSandi'} handleClose={handleClose3} />}

            {/* <Typography variant="subtitle1" sx={{ color: 'rgba(64, 64, 65, 1)', textAlign: 'center' }}>
                Masuk
            </Typography> */}

            <Formik
                initialValues={{
                    nip: '1101',
                    password: 'Rahasia@1',
                    submit: null
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (!navigator.onLine) {
                            setErrors({
                                submit: 'Tidak ada koneksi internet. Periksa koneksi Anda dan coba lagi.'
                            });
                            setSubmitting(false);
                            setOpen2(true);
                            return;
                        }
                        if (isUnderMaintenance) {
                            setErrors({
                                submit: 'Sistem saat ini sedang dalam perbaikan. Silahkan coba lagi nanti.'
                            });
                            setSubmitting(false);
                            setOpen(true);
                            return;
                        }
                        await axios
                            .post(
                                `${process.env.REACT_APP_API_URL}/api/v1/auth/login`,
                                { nip: values.nip, password: values.password },
                                {
                                    headers: {
                                        'Access-Control-Allow-Origin': '*',
                                        'Content-Type': 'application/json'
                                    }
                                }
                            )
                            .then(async (res) => {
                                if (res && res.data) {
                                    const responseData = res?.data?.data;
                                    const userInfo = jwtDecode(responseData.token_access);
                                    if (userInfo) {
                                        localStorage.setItem(storageKey.USER_TOKEN, responseData?.token_access);
                                        localStorage.setItem(storageKey.IS_LOGGED_ID, true);
                                        localStorage.setItem(storageKey.USER_ID, res?.data?.data?.role?.id);
                                        localStorage.setItem(storageKey.USER_NAME, res?.data?.data?.name);
                                        localStorage.setItem(storageKey.USER_ROLE, res?.data?.data?.role?.name);
                                        localStorage.setItem(
                                            storageKey.USER_INFO,
                                            JSON.stringify(jwtDecode(res?.data?.data?.token_access))
                                        );
                                    }
                                    setTimeout(() => {
                                        navigate('/dashboard', { replace: true });
                                    }, [1000]);
                                }
                                // WARNING: do not set any formik state here as formik might be already destroyed here. You may get following error by doing so.
                                // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
                                // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
                                // github issue: https://github.com/formium/formik/issues/2430
                            })
                            .catch((err) => {
                                console.error(err);
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err?.response?.data?.message });
                                    setSubmitting(false);
                                }
                            });
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err?.response?.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <FormControl fullWidth error={Boolean(touched.nip && errors.nip)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">NIP</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="nip"
                                value={values.nip}
                                name="nip"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                                disabled={isSubmitting}
                            />
                            {touched.nip && errors.nip && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.nip}
                                </FormHelperText>
                            )}
                        </FormControl>
                        {/* <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">Email</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="email"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Email Address / Username"
                                inputProps={{}}
                            />
                            {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl> */}

                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login">Kata Sandi</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                inputProps={{}}
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Typography
                            variant="subtitle1"
                            onClick={() => setOpen3('lupaKataSandi')}
                            sx={{ cursor: 'pointer', color: theme.palette.secondary.light, textAlign: 'right' }}
                        >
                            Lupa kata sandi?
                        </Typography>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting || values.email === '' || values.password === ''}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{ borderRadius: borderRadius }}
                                >
                                    Masuk
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

FirebaseLogin.propTypes = {
    loginProp: PropTypes.number
};

export default FirebaseLogin;
