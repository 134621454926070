import { Grid, IconButton, Tooltip } from '@mui/material';
import { IconButtonStyle } from 'ui-component/style/button';

import IconFileTypeExcel from 'assets/icons/IconFileTypeExcel';

const ExportExcel = () => {
    return (
        <Grid item>
            <Tooltip placement="top" title="Ekspor ke Excel">
                <IconButton size="small" sx={IconButtonStyle}>
                    <IconFileTypeExcel size={18} />
                </IconButton>
            </Tooltip>
        </Grid>
    );
};

export default ExportExcel;
