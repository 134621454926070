/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Field } from 'redux-form';
import ReduxFormAutocomplete from '../field/renderSelect'; // Adjust the path as needed
import useDebounce from 'hooks/useDebounce';
import Loader from 'ui-component/Loader';
import { getMasterPangkat } from 'services/api/master/master-pangkat';

const PangkatGolonganSelector = (props) => {
    const { submitting, onChange, label = '', name = '', disabled = false} = props;
    const [listData, setListData] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [searchData, setsearchData] = useState('');
    const search = useDebounce(searchData, 300);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getMasterPangkat();

            if (res && res.status) {
                setListData(res.data.list);
            }
        } catch (e) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [search]);

    const handleInputChange = useCallback(
        (e, newInput) => {
            const searchData = newInput.split('-')[0]?.trim();
            setsearchData(searchData);

            if (onChange) {
                const foundData = listData.find((item) => item.keterangan_jabatan === searchData);
                if (foundData) {
                    onChange(foundData);
                }
            }
        },
        [listData, onChange]
    );

    const handleClose = useCallback(() => {
        // console.log("Dropdown closed");
        setsearchData("");
        setListData([])
       setTimeout(() => {
        fetchData();
       }, 300); // Fetch new data if needed
    }, [fetchData]);

    return (
        <>
            {Loading && <Loader />} {/* Show loader only when loading */}
            <Field
                name={name || "pangkat_golongan"}
                component={ReduxFormAutocomplete}
                options={listData.map((item) => ({
                    label: `${item?.nama_pangkat} / ${item?.golongan}`,
                    value: item.kd_pangkat
                }))}
                label={label || 'Pangkat/Golongan'}
                loading={submitting || Loading}
                onInputChange={handleInputChange}
                onClose={handleClose}
                disabled={disabled}
            />
        </>
    );
};

export default PangkatGolonganSelector;
