import { borderRadius } from 'reduxStore/constant';
import { Button } from '@mui/material';

import PropTypes from 'prop-types';

const Buttons = ({ title, onClick, color, disable, type, startIcon, endIcon, sx, outlined }) => (
    <Button
        disableElevation
        variant={outlined ? 'outlined' : 'contained'}
        type={type ?? 'submit'}
        color={color ?? 'primary'}
        size="small"
        sx={{ borderRadius: borderRadius, textTransform: 'none', px: 2, ...sx }}
        onClick={onClick}
        disabled={disable}
        startIcon={startIcon}
        endIcon={endIcon}
    >
        {title}
    </Button>
);

Buttons.propTypes = {
    title: PropTypes.string.isRequired,
    color: PropTypes.string
};

export default Buttons;
