import React from 'react';
import MainCard from 'ui-component/card/MainCard';

import { useDispatch, useSelector } from 'react-redux';
import { ActionsTable, DataTable, ButtonStatus, ModalGlobal } from 'components';
import { servicePemberianAnumerta } from 'services/api/gelar-diplomatik/pemeberian-anumerta';
import FormPemberianAnumerta from './FormPemberianAnumerta';
import ViewDetailPemberianAnumerta from './ViewDetailPemberianAnumerta';
import { openModal, convertDate } from 'utils';
const PemberianAnumerta = () => {
    const dispatch = useDispatch();
    const service = servicePemberianAnumerta();

    const columns = [
        {
            label: 'No',
            field: 'No',
            formatter: (_cell, _row, index) => <span>{index + 1}</span>
        },
        {
            label: 'NIP',
            field: 'nip'
        },
        {
            label: 'Nama Pegawai',
            field: 'nama',
            formatter: (cell, row) => <>{row.pegawai?.nama || ''}</>
        },
        {
            label: 'Gelar Sebelumnya',
            field: 'gelar_asal',
            formatter: (cell, row) => <>{row.gelar_asal?.gelar_pdln || ''}</>
        },
        {
            label: 'Gelar Kenaikan',
            field: 'gelar_usulan',
            formatter: (cell, row) => <>{row.gelar_usulan?.gelar_pdln || ''}</>
        },

        {
            label: 'Tmt Gelar',
            field: 'tmt_gelar',
            formatter: (field) => {
                return convertDate(field);
            }
        },
        {
            label: 'Status',
            field: 'status',
            formatter: (_, row) => (
                <div>
                    <ButtonStatus status={row?.status?.status || ''} />
                </div>
            )
        },

        {
            label: 'Action',
            field: 'action',
            formatter: (_, row) => {
                return (
                    <ActionsTable
                        handleClickView={() =>
                            dispatch(
                                openModal({
                                    data: row,
                                    fullscreen: true,
                                    form: 'ViewDetailPemberianAnumerta',
                                    title: 'Data Pemberian Anumerta'
                                })
                            )
                        }
                        handleClickEdit={() =>
                            dispatch(
                                openModal({
                                    isEdit: true,
                                    data: row,
                                    form: 'FormPemberianAnumerta',
                                    title: 'Edit Data'
                                })
                            )
                        }
                        handleClickDelete={() => dispatch(service.removePemberianAnumerta(row.id))}
                        module="anumerta"
                    />
                );
            }
        }
    ];

    const data = useSelector((state) => state.gelarDiplomatik.getPemberianAnumerta);
    const modal = useSelector((state) => state.utility.modal);
    const userRole = localStorage.getItem('userRole');
    
    return (
        <MainCard title="Gelar Diplomatik Pemberian Anumerta" content={false}>
            <DataTable
                dataTable={data?.list}
                totalDataCount={data?.total}
                columns={columns}
                onChangePage={(value) => {
                    dispatch(service.getPemberianAnumerta(value));
                }}
                handleSearch={(value) => {
                    dispatch(service.getPemberianAnumerta(value));
                }}
                titleButton={'Tambah Data'}
                handleClickButton={userRole === "Admin Satker" || userRole === "Admin BSDM" || userRole === "Admin AJGP" || userRole === "Super Admin" ? () =>
                    dispatch(
                        openModal({
                            title: 'Tambah Data',
                            form: 'FormPemberianAnumerta'
                        })
                    ) : undefined
                }
            />
            <ModalGlobal title={modal.title}>
                {modal.form === 'ViewDetailPemberianAnumerta' ? <ViewDetailPemberianAnumerta /> : <FormPemberianAnumerta />}
            </ModalGlobal>
        </MainCard>
    );
};

export default PemberianAnumerta;
