/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import './../../views/pages/style.css';

import { useEffect, useState } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { getAllUnitKerja } from 'services/api/unit-organisasi';
import { TextFieldStyle1 } from 'ui-component/style/textfield';

const FilterUnitOrg = ({ setSearchUnit }) => {
    const [unit, setUnit] = useState(null);
    const [listUnit, setListUnit] = useState([]);
    const [load, setLoad] = useState(false);
    const [active, setActive] = useState(false);

    const fetchUnitOrganisasi = async () => {
        setLoad(true);
        await getAllUnitKerja()
            .then((res) => {
                if (res && res.status) {
                    setListUnit(res.data.list);
                }
            })
            .catch((e) => console.error(e))
            .finally(() => setLoad(false));
    };

    useEffect(() => {
        if (active) {
            fetchUnitOrganisasi();
        }
    }, [active]);

    return (
        <Grid item>
            <Autocomplete
                className="filter-unker"
                sx={{ width: 250 }}
                disablePortal
                open={active}
                onOpen={() => setActive(true)}
                onClose={() => setActive(false)}
                value={unit}
                options={listUnit.map((item) => {
                    return {
                        label: item?.ket_unker,
                        value: item?.kd_unker
                    };
                })}
                onChange={(e, newValue) => {
                    if (newValue) {
                        setUnit(newValue.label);
                        setSearchUnit(newValue.value);
                    } else {
                        setUnit(null);
                        setSearchUnit('');
                    }
                }}
                renderInput={(params) => <TextField {...params} sx={TextFieldStyle1} placeholder="Unit Organisasi" />}
            />
        </Grid>
    );
};

export default FilterUnitOrg;
