import { Stack } from '@mui/material';
import { styled, useTheme } from '@mui/styles';

export const StackContainer = styled(Stack)({
    alignItems: 'center',
    justifyContent: 'space-between'
});
export const StackStyleBorder = () => ({
    borderRadius: '8px',
    border: 'solid',
    borderColor: 'rgba(64, 64, 65, 0.2)',
    padding: 1,
    borderWidth: 1,
    display: 'flex',
    alignItems: 'center'
});
export const StackBorderDashedStyle = () => {
    const theme = useTheme();

    return {
        borderRadius: 5,
        border: 'dashed',
        borderColor: theme.palette.secondary.main,
        padding: 2,
        borderWidth: 1
    };
};
