import React, { useState, useEffect } from 'react'
import ModalConfirmTerima from 'ui-component/modal/ModalConfirmTerima'
import { useDispatch, useSelector } from 'react-redux';
import ModalRevision2 from 'ui-component/modal/ModalRevision2';
import ModalReject2 from 'ui-component/modal/ModalReject2';
import { hideModalKonfirmasi } from 'reduxStore';
import ModalConfSigned from 'ui-component/modal/ModalConfSigned';
import { isEmpty } from 'lodash';

const ModalKonfirmasi = ({ data, handleUpdateApproval, link_sk = '' }) => {
    const utility = useSelector((state) => state.utility);
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);

    const [payload, setPayload] = useState({
        id: data.id,
        nip: data.nip,
        alasan_revisi: '',
        alasan_ditolak: '',
        nik: '',
        passphrase: '',
        dokumen_ttd_basah: ''
    });

    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        if (payload.nik !== '' && payload.passphrase !== '') {
            setDisable(false);
        } else {
            setDisable(true);
        }
        if (payload.nik !== '' && payload.passphrase !== '' && payload.dokumen_ttd_basah !== '') {
            setDisable(true);
        } else if (payload.nik === '' && payload.passphrase === '' && payload.dokumen_ttd_basah !== '') {
            setDisable(false);
        }
        if (isSubmit) {
            setDisable(true);
        }
    }, [payload, isSubmit]);

    console.log(data);

    const handleOpenFormPengajuan = () => {
        window.open(data?.link_sk || data?.document_url, '_blank', 'noopener noreferrer');
    };

    if (!isEmpty(payload.dokumen_ttd_basah)) {
        setPayload(prevPayload => ({
            ...prevPayload,
            files: payload?.dokumen_ttd_basah
        }));
    }

    console.log(payload);

    return (
        <>
            <ModalConfirmTerima
                loading={utility.loading.screen}
                open={utility.modalkonfirmasi.title === 'Disetujui'}
                handleClose={() =>
                    dispatch(hideModalKonfirmasi())
                }
                handleSubmit={(e) => handleUpdateApproval('Disetujui', payload)}
                data={data.pegawai ? `${data.pegawai?.nip} - ${data.pegawai?.nama}` : `${data?.nip} - ${data?.nama}`}
            />

            <ModalRevision2
                loading={utility.loading.screen}
                open={utility.modalkonfirmasi.title === 'Revisi'}
                payload={payload}
                setPayload={setPayload}
                handleSubmit={() => handleUpdateApproval('Revisi', payload)}
                handleClose={() =>
                    dispatch(hideModalKonfirmasi())
                }
            />
            <ModalReject2
                loading={utility.loading.screen}
                open={utility.modalkonfirmasi.title === 'Ditolak'}
                payload={payload}
                setPayload={setPayload}
                handleSubmit={(e) => handleUpdateApproval('Ditolak', payload)}
                handleClose={() =>
                    dispatch(hideModalKonfirmasi())
                }
            />
            <ModalConfSigned
                loading={utility.loading.screen}
                open={utility.modalkonfirmasi.title === 'Signed'}
                payload={payload}
                disable={disable}
                setPayload={setPayload}
                onClickForm={handleOpenFormPengajuan}
                handleSubmitTTD={(e) => {
                    e.preventDefault();
                    setIsSubmit(true);
                    const formData = new FormData();
                    formData.append('files', payload?.dokumen_ttd_basah);
                    handleUpdateApproval('Signed_TTD', {"id": payload?.id, "files": formData})
                }
                }
                handleSubmitTTE={(e) => {
                    setIsSubmit(true);
                    handleUpdateApproval('Signed_TTE', payload)
                }
                }
                handleClose={() =>
                    dispatch(hideModalKonfirmasi())
                }
            />
        </>
    )
}

export default ModalKonfirmasi