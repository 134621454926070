/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { TextFieldStyle1 } from 'ui-component/style/textfield';

const StackSignedElectronic = ({ payload, setPayload }) => {
    const [showPassphrase, setShowPassphrase] = useState(false);

    const handleClickShowPassphrase = () => {
        setShowPassphrase(!showPassphrase);
    };

    const handleMouseDownPassphrase = (event) => {
        event.preventDefault();
    };

    return (
        <Stack direction="column" spacing={1.5} sx={{ width: '100%' }}>
            <TextField
                type="text"
                required
                label="NIK"
                value={payload.nik}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                        setPayload({ ...payload, nik: value });
                    }
                }}
                sx={TextFieldStyle1}
            />
            <TextField
                required
                type={showPassphrase ? 'text' : 'password'}
                label="Passphrase"
                value={payload.passphrase}
                onChange={(e) => setPayload({ ...payload, passphrase: e.target.value })}
                sx={TextFieldStyle1}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={handleClickShowPassphrase} onMouseDown={handleMouseDownPassphrase} edge="end" size="small">
                            {showPassphrase ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    )
                }}
            />
        </Stack>
    );
};
export default StackSignedElectronic;
