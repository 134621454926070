// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';

//actionUtility
export const LOADING = 'LOADING';
export const MODAL = 'MODAL';
export const GET_DATA_TMP = 'GET_DATA_TMP';
export const CLEAR_DATA_TMP = 'CLEAR_DATA_TMP';
export const MODAL_KONFIRMASI = 'MODAL_KONFIRMASI';

export const GET_DATA_KENAIKAN_ATASE = 'GET_DATA_KENAIKAN_ATASE';
export const GET_DATA_KENAIKAN_NON_ATASE = 'GET_DATA_KENAIKAN_NON_ATASE';
export const GET_DATA_PROMOSI_KONSULER = 'GET_DATA_PROMOSI_KONSULER';
export const GET_DATA_PROMOSI_DUBES = 'GET_DATA_PROMOSI_DUBES';
export const GET_PEMBERIAN_DUBES_DEWATAP = 'GET_PEMBERIAN_DUBES_DEWATAP';
export const GET_DATA_PEMBERIAN_ANUMERTA = 'GET_DATA_PEMBERIAN_ANUMERTA';

export const GET_DATA_PROMOSI_DCM = 'GET_DATA_PROMOSI_DCM';
export const GET_DATA_PROMOSI_KONJEN = 'GET_DATA_PROMOSI_KONJEN';
export const GET_DATA_PROMOSI_TITULER = 'GET_DATA_PROMOSI_TITULER';

export const GET_DATA_ADMIN_PENGAWAS = 'GET_DATA_ADMIN_PENGAWAS';
export const GET_DATA_PEMBERHENTIAN_ADMIN_PENGAWAS = 'GET_DATA_PEMBERHENTIAN_ADMIN_PENGAWAS';
export const GET_DATA_PROMOSI_PENGABDIAN = 'GET_DATA_PROMOSI_PENGABDIAN';

export const GET_DATA_JPT_PRATAMA = 'GET_DATA_JPT_PRATAMA';
export const GET_DATA_JPT_MADYA = 'GET_DATA_JPT_MADYA';
