function IconFileTypePdf({ size, ...props }) {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.62 12.104h-.482v.482h.482v-.482zM7.276 12.104h-.483v.482h.483v-.482zM10.716 10.462a.681.681 0 01-.02-.048h-.043l.063.048zM7.034 9.931a.242.242 0 100 .483.242.242 0 000-.483zM7.718 10.414a.694.694 0 01-.02.048l.063-.048h-.043zM11.62 10.172a.242.242 0 10-.483 0 .242.242 0 00.483 0z"
                fill="#E4EAF6"
            />
            <path
                d="M12.103 3.897a.724.724 0 01-.724-.725V1H4.862c-.4 0-.724.324-.724.724v1.69h6.034c.267 0 .483.216.483.483v3.379a.483.483 0 01-.483.483H4.138v6.517c0 .4.324.724.724.724h8.69c.4 0 .724-.324.724-.724V3.896h-2.173z"
                fill="#E4EAF6"
            />
            <path d="M12.103 3.897h2.172L11.38 1v2.172c0 .4.324.725.724.725z" fill="#D2DCF0" />
            <path
                d="M10.656 7.276v-3.38a.483.483 0 00-.483-.482H2.208a.483.483 0 00-.483.482v3.38c0 .266.216.482.482.482h7.966a.483.483 0 00.483-.482z"
                fill="#D95845"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.2 4.617c0-.13.105-.234.234-.234h.66a1.206 1.206 0 010 2.413h-.66a.233.233 0 01-.234-.233V4.617zm.467.233v1.48h.427a.74.74 0 000-1.48h-.427z"
                fill="#E4EAF6"
            />
            <path
                d="M4.078 4.383H2.99a.241.241 0 00-.241.242v1.93a.241.241 0 10.483 0v-.482h.845a.846.846 0 000-1.69zm0 1.207h-.845v-.724h.845a.362.362 0 010 .724z"
                fill="#E4EAF6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.767 4.783c0-.22.179-.4.4-.4H9.4a.233.233 0 010 .467H8.233v.506h.887a.233.233 0 010 .467h-.887v.74a.233.233 0 11-.466 0v-1.78z"
                fill="#E4EAF6"
            />
            <path
                d="M9.495 10.617l-.047.148c.12.223.271.416.45.582.454-.077.778-.096.827-.098h.068c.37 0 .672.301.672.672 0 .377-.29.672-.659.672a.81.81 0 01-.263-.043 3.216 3.216 0 01-.833-.418 6.029 6.029 0 00-.982.291c-.22.402-.422.705-.555.893a.61.61 0 01-1.107-.356c0-.182.058-.333.171-.45.247-.252.57-.473.963-.658.179-.348.335-.703.463-1.056a3.356 3.356 0 01-.253-1.135.61.61 0 011.221 0c0 .38-.11.85-.136.956z"
                fill="#D95845"
            />
            <path
                d="M7.555 12.934c0 .148.16.183.219.097a7.58 7.58 0 00.393-.612 2.551 2.551 0 00-.58.432.12.12 0 00-.032.083zM9.144 9.66a.122.122 0 00-.245 0c0 .032.004.356.14.768.03-.132.105-.49.105-.767zM9.078 11.084c-.112.296-.233.57-.353.815.275-.098.552-.173.808-.232a2.741 2.741 0 01-.455-.583zM10.792 11.739h-.061a6.8 6.8 0 00-.626.072c.172.107.369.202.594.277.032.012.07.018.106.018a.17.17 0 00.17-.17c0-.115-.082-.197-.183-.197z"
                fill="#E4EAF6"
            />
        </svg>
    );
}

export default IconFileTypePdf;
