import { Typography } from '@mui/material';
import { styled } from '@mui/styles';

export const StyleTitleOnModal = () => ({
    fontWeight: 600,
    color: '#404041',
    mb: 2,
    fontSize: '21px'
});
export const Title = styled(Typography)({
    fontWeight: 700,
    size: '14px',
    color: '#979797'
});
export const Subtitle = styled(Typography)({
    fontWeight: 700,
    size: '14px',
    color: '#404041',
    textAlign: 'right'
});
