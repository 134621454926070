/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Field } from 'redux-form';
import ReduxFormAutocomplete from '../field/renderSelect'; // Adjust the path as needed
import { getAllPegawai } from 'services/api/pegawai';
import useDebounce from 'hooks/useDebounce';
import Loader from 'ui-component/Loader';

const PegawaiSelector = (props) => {
    const {
        submitting,
        readOnly,
        label = '',
        getValue,
        module = 'jabatan-fungsional',
        type = 'pengangkatan-pertama',
        required = false,
        input= { value:"", onChange:()=>{},name:"nip" },
        disabled=false
    } = props;

    const [searchNIP, setSearchNIP] = useState(input.value || input.value?.nip || '');
    const searchDataNIP = useDebounce(searchNIP, 300);
    

    const [listNIP, setListNIP] = useState([]);
    const [loadingNIP, setLoadingNIP] = useState(false);
    const [pageNIP, setPageNIP] = useState(1);
    const [limit, setLimit] = useState(20);

    const fetchNIPPegawai = useCallback(async () => {
        setLoadingNIP(true);
        try {
            // if (listNIP.length > 0 && listNIP.length % 20 !== 0) {
            //     setLoadingNIP(false);
            //     return;
            // }

            const res = await getAllPegawai({
                page: pageNIP,
                limit: limit,
                search: searchDataNIP,
                type: type,
                module: module
            });

            if (res && res.status) {
                const newData = res.data.list;
                // if (newData.length >= 20) {
                //     setPageNIP((prevPage) => prevPage + 1);
                // }
                setListNIP((prevList) => {
                    const newItems = newData.filter((newItem) => !prevList.some((prevItem) => prevItem.nip === newItem.nip));
                    return [...prevList, ...newItems];
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoadingNIP(false);
        }
    }, [listNIP.length, module, pageNIP, searchDataNIP, type, limit]);

    useEffect(() => {
        fetchNIPPegawai();
    }, [searchDataNIP]);

    const handleScroll = useCallback(
        (event) => {
            const listboxNode = event.currentTarget;
            const position = listboxNode.scrollTop + listboxNode.clientHeight;
            const isAtBottom = listboxNode.scrollHeight - position <= 1;
            if (isAtBottom && !loadingNIP) {
                setLimit((prevLimit) => prevLimit + 20);
                fetchNIPPegawai();
            }
        },
        [fetchNIPPegawai, loadingNIP]
    );

    const handleInputChange = useCallback(
        (e, newInput) => {
            const searchNIP = newInput.split('-')[0]?.trim();
            setSearchNIP(searchNIP);
            if (input.onChange) {
                fetchNIPPegawai();
                const foundPegawai = listNIP.find((item) => item.nip === searchNIP);
                // console.log(foundPegawai);
                if (foundPegawai) {
                    input.onChange(foundPegawai.nip);
                    getValue(foundPegawai);
                }
            }
        },
        [listNIP, input.onChange, getValue, input.value]
    );

    const handleClose = useCallback(() => {
        console.log('Dropdown closed');
        setLoadingNIP(true);
        input.onChange(null);
        getValue(null);
        setSearchNIP('');
        setPageNIP(1);
        setLimit(20);
        setListNIP([]);
        setTimeout(() => {
            fetchNIPPegawai(); 
        }, 300);// Fetch new data if needed
    }, [fetchNIPPegawai]);

    return (
        <>
            {loadingNIP && <Loader />}
            <Field
                name={input.name}
                component={ReduxFormAutocomplete}
                options={listNIP.map((item) => ({
                    label: `${item.nip} - ${item.nama}`,
                    value: item?.nip,
                    labelUnker: item.unit_kerja?.ket_unker,
                    valueUnker: item?.unit_kerja?.kd_unker
                }))}
                onClose={handleClose}
                readOnly={readOnly}
                label={`${label || 'Nip - Pegawai'} ${required ? '*' : ''}`}
                loading={submitting || loadingNIP}
                onInputChange={handleInputChange}
                ListboxProps={{
                    onScroll: handleScroll
                }}
                disabled={disabled}
            />
        </>
    );
};

export default PegawaiSelector;

