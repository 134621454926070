/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import PemberianDubesDewatap from 'views/pages/gelar-diplomatik/promosi/pemberian-dubes-dewatap';
import PemberianAnumerta from 'views/pages/gelar-diplomatik/promosi/pemberian-anumerta';

// Jabatan Fungsional
const PengangkatanPertamaPage = Loadable(lazy(() => import('views/pages/jabatan-fungsional/pengangkatan-pertama')));
const PengangkatanKembaliPage = Loadable(lazy(() => import('views/pages/jabatan-fungsional/pengangkatan-kembali')));
const PemberhentianFungsionalPage = Loadable(lazy(() => import('views/pages/jabatan-fungsional/pemberhentian')));
const KenaikanJenjangPage = Loadable(lazy(() => import('views/pages/jabatan-fungsional/kenaikan-jenjang')));

// Jabatan Struktural
const PengangkatanAP = Loadable(lazy(() => import('views/pages/jabatan-struktural/pengangkatan/administrator-pengawas/index')));
const PengangkatanJPTPratama = Loadable(lazy(() => import('views/pages/jabatan-struktural/pengangkatan/jpt-pratama/index')));
const PengangkatanJPTMadya = Loadable(lazy(() => import('views/pages/jabatan-struktural/pengangkatan/jpt-madya/index')));
const PemberhentianAP = Loadable(lazy(() => import('views/pages/jabatan-struktural/pemberhentian/administrator-pengawas/index')));
const SPPSPMT = Loadable(lazy(() => import('views/pages/jabatan-struktural/spp-spmt/index')));
const PengangkatanStrukturalPage = Loadable(lazy(() => import('views/pages/jabatan-struktural/pengangkatan')));
const PemberhentianStrukturalPage = Loadable(lazy(() => import('views/pages/jabatan-struktural/pemberhentian')));

// PPPK
const PengangkatanPPPKPage = Loadable(lazy(() => import('views/pages/pppk/pengangkatan-pppk')));
const PengangkatanCPPPKPage = Loadable(lazy(() => import('views/pages/pppk/pengangkatan-cpppk')));

// Pelantikan
const BAPPage = Loadable(lazy(() => import('views/pages/pelantikan/bap/index')));
const SPMTSPPPage = Loadable(lazy(() => import('views/pages/pelantikan/spmt-spp/index')));

// Gelar Diplomatik
const KenaikanRegulerPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/kenaikan-reguler/index')));
const EfektifKonsulPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/efektif-konsul/index')));
const EfektifIstimewaPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/efektif-istimewa/index')));
const EfektifPengabdianPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/efektif-pengabdian/index')));
const EfektifDCMPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/efektif-dcm/index')));
const EfektifKonjenPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/efektif-konjen/index')));
const EfektifDubesPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/efektif-dubes/index')));
const EfektifAnumertaPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/efektif-anumerta/index')));
const AtaseSekdiluPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/atase-sekdilu/index')));
const DubesDewatapPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/dubes-dewatap/index')));
const PromosiDCMPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/promosi/promosi-dcm/index')));
const PromosiKonjenPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/promosi/promosi-konjen/index')));
const PromosiTitulerPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/promosi/promosi-tituler/index')));
const PengabdianPage = Loadable(lazy(() => import('views/pages/gelar-diplomatik/promosi/pengabdian/index')));

// Penugasan PNS Kemlu
const PenugasanPemerintahPage = Loadable(lazy(() => import('views/pages/penugasan-pns/penugasan-pemerintah')));
const PenugasanLuarInstansiPage = Loadable(lazy(() => import('views/pages/penugasan-pns/penugasan-luar-instansi')));
const PenugasanNomenklaturPage = Loadable(lazy(() => import('views/pages/penugasan-pns/penugasan-nomenklatur')));
const PenugasanRotasiPromosiPage = Loadable(lazy(() => import('views/pages/penugasan-pns/penugasan-rotasi')));

// Tunjangan Pengaman Persandian
const PemberianPengamanPage = Loadable(lazy(() => import('views/pages/tunjangan-pengaman/pemberian')));
const PenyesuaianPengamanPage = Loadable(lazy(() => import('views/pages/tunjangan-pengaman/penyesuaian')));
const PemberhentianPengamanPage = Loadable(lazy(() => import('views/pages/tunjangan-pengaman/pemberhentian')));

// User Management
const RoleManagementPage = Loadable(lazy(() => import('views/pages/user-management/role-management/index')));
const CreateRolePage = Loadable(lazy(() => import('views/pages/user-management/role-management/CreateRole')));
const UserListPage = Loadable(lazy(() => import('views/pages/user-management/users/index')));

// Master
const MasterJabatanPage = Loadable(lazy(() => import('views/pages/master/master-jabatan')));
const MasterTunjanganPage = Loadable(lazy(() => import('views/pages/master/master-tunjangan')));
const MasterPPPKPage = Loadable(lazy(() => import('views/pages/master/master-pppk')));
const MasterTemplatePage = Loadable(lazy(() => import('views/pages/master/master-template')));
// const MasterRolePage = Loadable(lazy(() => import('views/pages/master/master-role')));

// Under Construction
const UnderConstruction = Loadable(lazy(() => import('views/pages/maintenance/UnderConstruction')));
const ComingSoon = Loadable(lazy(() => import('views/pages/maintenance/ComingSoon/ComingSoon')));

const PromosiDubes = Loadable(lazy(() => import('views/pages/gelar-diplomatik/promosi/promosi-dubes')));

const PromosiAtase = Loadable(lazy(() => import('views/pages/gelar-diplomatik/kenaikan-reguler/atase')));
const PromosiNonAtase = Loadable(lazy(() => import('views/pages/gelar-diplomatik/kenaikan-reguler/non-atase')));

const PromosiKonsuler = Loadable(lazy(() => import('views/pages/gelar-diplomatik/promosi/promosi-konsuler')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <ComingSoon />
        },
        {
            path: '/dashboard',
            element: <ComingSoon />
        },
        {
            path: '/jabatan-fungsional/pengangkatan-pertama',
            element: <PengangkatanPertamaPage />
        },
        {
            path: '/jabatan-fungsional/pemberhentian/karena-struktural',
            element: <PemberhentianFungsionalPage submodul="Karena Struktural" />
        },
        {
            path: '/jabatan-fungsional/pemberhentian/karena-cltn',
            element: <PemberhentianFungsionalPage submodul="Karena CLTN" />
        },
        {
            path: '/jabatan-fungsional/pemberhentian/karena-tubel',
            element: <PemberhentianFungsionalPage submodul="Karena Tubel" />
        },
        {
            path: '/jabatan-fungsional/pemberhentian/karena-hukuman',
            element: <PemberhentianFungsionalPage submodul="Karena Hukdis" />
        },
        {
            path: '/jabatan-fungsional/pengangkatan-kembali/berhenti-struktural',
            element: <PengangkatanKembaliPage submodul="Berhenti Struktural" />
        },
        {
            path: '/jabatan-fungsional/pengangkatan-kembali/selesai-cltn',
            element: <PengangkatanKembaliPage submodul="Selesai CLTN" />
        },
        {
            path: '/jabatan-fungsional/pengangkatan-kembali/selesai-tubel',
            element: <PengangkatanKembaliPage submodul="Selesai Tubel" />
        },
        {
            path: '/jabatan-fungsional/kenaikan-jenjang',
            element: <KenaikanJenjangPage />
        },
        {
            path: '/jabatan-struktural/pengangkatan-administrator-pengawas',
            element: <PengangkatanAP />
        },
        {
            path: '/jabatan-struktural/pengangkatan-jpt-pratama',
            element: <PengangkatanJPTPratama />
        },
        {
            path: '/jabatan-struktural/pengangkatan-jpt-madya',
            element: <PengangkatanJPTMadya />
        },
        {
            path: '/jabatan-struktural/pemberhentian-administrator-pengawas',
            element: <PemberhentianAP />
        },
        {
            path: '/jabatan-struktural/spp-spmt',
            element: <SPPSPMT />
        },
        // {
        //     path: '/jabatan-struktural/pengangkatan',
        //     element: <PengangkatanStrukturalPage />
        // },
        // {
        //     path: '/jabatan-struktural/pemberhentian',
        //     element: <PemberhentianStrukturalPage />
        // },
        {
            path: '/pppk/pengangkatan-pppk',
            element: <PengangkatanPPPKPage />
        },
        {
            path: '/pppk/pengangkatan-cpppk',
            element: <PengangkatanCPPPKPage />
        },
        {
            path: '/pelantikan/bap',
            element: <BAPPage />
        },
        {
            path: '/pelantikan/spmt-spp',
            element: <SPMTSPPPage />
        },

        {
            path: '/gelar-diplomatik/kenaikan-reguler',
            element: <KenaikanRegulerPage />
        },
        {
            path: '/gelar-diplomatik/efektif-promosi-konsul',
            element: <EfektifKonsulPage />
        },
        {
            path: '/gelar-diplomatik/efektif-istimewa',
            element: <EfektifIstimewaPage />
        },
        {
            path: '/gelar-diplomatik/efektif-pengabdian',
            element: <EfektifPengabdianPage />
        },
        {
            path: '/gelar-diplomatik/efektif-promosi-dcm',
            element: <EfektifDCMPage />
        },
        {
            path: '/gelar-diplomatik/efektif-promosi-konjen',
            element: <EfektifKonjenPage />
        },
        {
            path: '/gelar-diplomatik/efektif-promosi-dubes',
            element: <EfektifDubesPage />
        },
        {
            path: '/gelar-diplomatik/efektif-anumerta',
            element: <EfektifAnumertaPage />
        },
        {
            path: '/gelar-diplomatik/atase-sekdilu',
            element: <AtaseSekdiluPage />
        },
        {
            path: '/gelar-diplomatik/dubes-dewatap',
            element: <DubesDewatapPage />
        },
        {
            path: '/penugasan-pns/penugasan-pemerintah',
            element: <PenugasanPemerintahPage />
        },
        {
            path: '/penugasan-pns/penugasan-luar-instansi',
            element: <PenugasanLuarInstansiPage />
        },
        {
            path: '/penugasan-pns/penugasan-nomenklatur',
            element: <PenugasanNomenklaturPage />
        },
        {
            path: '/penugasan-pns/penugasan-rotasi',
            element: <PenugasanRotasiPromosiPage />
        },
        {
            path: '/tunjangan-pengamanan/pemberian',
            element: <PemberianPengamanPage />
        },
        {
            path: '/tunjangan-pengamanan/penyesuaian',
            element: <PenyesuaianPengamanPage />
        },
        {
            path: '/tunjangan-pengamanan/pemberhentian',
            element: <PemberhentianPengamanPage />
        },
        {
            path: '/user/role-list',
            element: <RoleManagementPage />
        },
        {
            path: '/user/role-management/form',
            element: <CreateRolePage />
        },
        {
            path: '/user/user-list',
            element: <UserListPage />
        },
        {
            path: '/master/jabatan',
            element: <MasterJabatanPage />
        },
        {
            path: '/master/tunjangan',
            element: <MasterTunjanganPage />
        },
        // {
        //     path: '/master/role',
        //     element: <MasterRolePage />
        // },
        {
            path: '/master/golongan-pppk',
            element: <MasterPPPKPage />
        },
        {
            path: '/master/template',
            element: <MasterTemplatePage />
        },
        {
            path: '/gelar-diplomatik/promosi/promosi-dubes',
            element: <PromosiDubes />
        },
        {
            path: '/gelar-diplomatik/promosi/gelar-diplomatik-dubes-dewatap',
            element: <PemberianDubesDewatap />
        },
        {
            path: '/gelar-diplomatik/promosi/pemberian-anumerta',
            element: <PemberianAnumerta />
        },
        {
            path: '/gelar-diplomatik/promosi/promosi-dcm',
            element: <PromosiDCMPage />
        },
        {
            path: '/gelar-diplomatik/promosi/promosi-konjen',
            element: <PromosiKonjenPage />
        },
        {
            path: '/gelar-diplomatik/promosi/promosi-tituler',
            element: <PromosiTitulerPage />
        },
        {
            path: '/gelar-diplomatik/kenaikan-reguler/atase',
            element: <PromosiAtase />
        },
        {
            path: '/gelar-diplomatik/kenaikan-reguler/non-atase',
            element: <PromosiNonAtase />
        },
        {
            path: '/gelar-diplomatik/promosi/pengabdian',
            element: <PengabdianPage />
        },
        {
            path: '/gelar-diplomatik/promosi/promosi-konsuler',
            element: <PromosiKonsuler />
        },
    ]
};

export default MainRoutes;
