/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { SET_MENU } from 'reduxStore/actions';
import { drawerWidth } from 'reduxStore/constant';

import Header from './Header';
import Sidebar from './Sidebar';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderRadius: 0,
        marginRight: 0,
        marginLeft: 0,
        marginTop: '55px',
        backgroundColor: '#FFFFFF',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginTop: '55px',
            marginLeft: 0,
            marginRight: 0
        },
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginTop: '55px',
            marginLeft: 0,
            marginRight: 0
        }
    }),
    ...(open && {
        backgroundColor: '#FFFFFF',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        borderRadius: 0,
        marginRight: 0,
        marginLeft: 0,
        marginTop: '55px',
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
    }, [matchDownMd]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    // background: `linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(49, 114, 143, 1) 20%)`,
                    background: 'rgba(49, 114, 143, 1)',
                    // bgcolor: theme.palette.secondary.light,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar sx={{ padding: 0 }}>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            <Main theme={theme} open={leftDrawerOpened}>
                {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                <Outlet />
            </Main>
        </Box>
    );
};

export default MainLayout;
