import { Card, CardContent, CircularProgress, Fade, Modal, Stack, Typography } from '@mui/material';
import { ModalStyle1 } from 'ui-component/style/modal';
import { IconCircleCheckFilled } from '@tabler/icons-react';
import ButtonBatalOnModal from 'ui-component/button/ButtonBatalOnModal';
import RoundIconCard from 'ui-component/card/RoundIconCard';
import Buttons from 'ui-component/button/Buttons';
import Loader from 'ui-component/Loader';

const ModalConfirmTerima = ({ open, handleClose, handleSubmit, loading, data = null }) => (
    <Modal open={open} closeAfterTransition>
        <Fade in={open}>
            <Card sx={ModalStyle1()}>
                {loading && <Loader />}
                <CardContent>
                    <Stack alignItems="center" spacing={3}>
                        <Stack alignItems="center">
                            <RoundIconCard iconPrimary={IconCircleCheckFilled} color="#00c853" />
                            <Typography variant="h5" sx={{ color: 'rgba(57, 57, 57, 0.5)', paddingTop: 1 }}>
                                Anda akan menerima data ini
                            </Typography>
                        </Stack>

                        <Typography variant="h5" sx={{ textAlign: 'center' }}>
                            Terima Data
                            <br />
                            {data}?
                        </Typography>

                        <Stack direction="row" spacing={1} justifyContent="end" alignItems="center">
                            <ButtonBatalOnModal onClick={handleClose} />
                            <Buttons
                                title="Terima"
                                onClick={handleSubmit}
                                startIcon={loading && <CircularProgress size="18px" style={{ color: 'white' }} />}
                                sx={{
                                    backgroundColor: '#00c853',
                                    color: 'white',
                                    '&:hover': { backgroundColor: '#00c853', color: 'white' }
                                }}
                            />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Fade>
    </Modal>
);

export default ModalConfirmTerima;
