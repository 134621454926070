import { storageKey } from 'reduxStore/constant';
import ApiService from 'services/ApiService';

export const getMasterPerwakilan = async ({ page = 1, limit = 10, search = '', type = '', module = '' }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    let url = `${process.env.REACT_APP_API_URL}/api/v1/master-data/perwakilan?type=${type}&module=${module}`;

    if (search) {
        url += `&filter=${search}`;
    } else if (page && limit) {
        url += `&filter=&page=${page}&limit=${limit}&sortBy=kd_unker,desc`;
    }
    return ApiService.get(url, config);
};
