/* eslint-disable no-unused-vars */
import { Box, Button, CardHeader, Divider, IconButton, Modal, Stack, Typography } from '@mui/material';
import { ButtonStyle2 } from 'ui-component/style/button';

import React from 'react';
import styled from 'styled-components';
import { CardHeaderModalStyle2 } from 'ui-component/style/card';
import { StyleTitleOnModal } from 'ui-component/style/typography';
import CloseIcon from '@mui/icons-material/Close';

export const Container = styled.div`
    position: absolute;
    overflow: auto;
    max-height: ${({ fullScreen }) => (fullScreen ? '100vh' : '90vh')};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: inherit;
    background: #f2f2f2;
    border-radius: ${({ fullScreen }) => (fullScreen ? '0px' : '20px')};

    &::-webkit-scrollbar {
        width: 0; /* Remove scrollbar width */
        background: transparent; /* Optional: Set background color of the scrollbar track */
    }
    @media only screen and (max-width: 865px) {
        width: 450px;
    }
`;

function ModalForm({ open, onClose, fullScreen, sx, title, subHeader, children, onClick, loading, disable, disableButton }) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={sx}
            // sx={{ zIndex: 1300 }}
        >
            <Container fullScreen={fullScreen}>
                {/* <Stack padding='10px 10px 0 10px' direction='row' alignItems='end' justifyContent='end'>
                    <IconButton color='default' aria-label='close' size='small' onClick={close}>
                        <Close sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </Stack> */}
                {subHeader ? (
                    <>
                        <CardHeader
                            title={
                                <Typography
                                    fontSize="21px"
                                    fontWeight={500}
                                    paddingBottom="10px"
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    color="#404041"
                                >
                                    {title}
                                </Typography>
                            }
                            subheader={
                                <Typography
                                    color="#404041"
                                    paddingBottom="10px"
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderBottom: '1px solid darkGray'
                                    }}
                                >
                                    {subHeader}
                                </Typography>
                            }
                            style={{ position: 'sticky', top: 0, zIndex: 1000, background: '#F2F2F2' }}
                        />
                    </>
                ) : fullScreen ? (
                    <Box
                        sx={{
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1000,
                            backgroundColor: 'white'
                        }}
                    >
                        <CardHeader
                            title={
                                <Stack direction="row" sx={CardHeaderModalStyle2()}>
                                    <Typography sx={{ ...StyleTitleOnModal(), mb: 0 }}>{title}</Typography>
                                    <Box>
                                        <IconButton size="small" onClick={onClose}>
                                            <CloseIcon sx={{ fontSize: '1.5rem' }} />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            }
                        />
                        <Divider />
                    </Box>
                ) : (
                    <CardHeader
                        title={
                            <Typography
                                fontSize="21px"
                                fontWeight={500}
                                paddingBottom="10px"
                                sx={{
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                    textAlign: 'center'
                                }}
                                color="#404041"
                            >
                                {title}
                            </Typography>
                        }
                        style={{
                            position: 'sticky',
                            top: 0,
                            zIndex: 1000,
                            background: '#F2F2F2'
                        }}
                    />
                )}

                {fullScreen && children}

                <Stack direction="column" padding="5px 33px">
                    {!fullScreen && children}
                    {!disableButton && (
                        <Stack direction="row" alignItems="end" justifyContent="flex-end" spacing={2} padding="30px 0 25px 0px">
                            <Button variant="outlined" sx={{ color: '#317190', borderColor: '#317190' }} onClick={onClose}>
                                Batal
                            </Button>
                            <Button variant="contained" sx={ButtonStyle2} onClick={onClick} loading={loading} disabled={disable}>
                                Simpan
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </Container>
        </Modal>
    );
}

export default ModalForm;
