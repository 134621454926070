const validasiPemberianAnumertaAjgp = (value) => {
    const errors = {};

    if (!value.no_sk) {
        errors.no_sk = 'No SK wajib diisi';
    }

    if (!value.tanggal_sk) {
        errors.tanggal_sk = 'Tanggal SK wajib diisi';
    }
    
    return errors;
};

export default validasiPemberianAnumertaAjgp;
