const PaginationFunc = ({ setPage, setRowsPerPage }) => {
    const handleChangePage = (e, newPage) => {
        setPage(newPage)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event?.target.value, 10))
        setPage(0)
    }

    return { handleChangePage, handleChangeRowsPerPage }
}

export default PaginationFunc
