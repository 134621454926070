/* eslint-disable react/prop-types */
import { Button, Grid, Stack, Typography } from '@mui/material';

const GridSignedOptions = ({ form, onClickTTE, onClickTTD }) => (
    <Grid container sx={{ justifyContent: 'center', mb: 4 }} gap={1.5}>
        <Grid item>
            <Button
                variant="outlined"
                sx={{
                    width: '100%',
                    border: '1px solid',
                    borderColor: form === 'elektronik' ? '#31708F' : '#3939391a',
                    backgroundColor: form === 'elektronik' ? 'rgba(49, 112, 143, 0.1)' : 'white',
                    borderRadius: '4px',
                    justifyContent: 'left'
                }}
                onClick={onClickTTE}
            >
                <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Typography sx={{ color: 'rgba(57, 57, 57, 1)', fontWeight: 600, fontSize: '16px' }}>
                        Tanda Tangan Elektronik
                    </Typography>
                    <Typography sx={{ color: 'rgba(57, 57, 57, 0.5)', fontWeight: 400, fontSize: '12px' }}>
                        Verifikasi dengan NIK dan Passphrase
                    </Typography>
                </Stack>
            </Button>
        </Grid>
        <Grid item>
            <Button
                variant="outlined"
                sx={{
                    width: '100%',
                    border: '1px solid',
                    borderColor: form === 'basah' ? '#31708F' : '#3939391a',
                    backgroundColor: form === 'basah' ? 'rgba(49, 112, 143, 0.1)' : 'white',
                    borderRadius: '4px',
                    justifyContent: 'left'
                }}
                onClick={onClickTTD}
            >
                <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Typography sx={{ color: 'rgba(57, 57, 57, 1)', fontWeight: 600, fontSize: '16px' }}>Tanda Tangan Basah</Typography>
                    <Typography sx={{ color: 'rgba(57, 57, 57, 0.5)', fontWeight: 400, fontSize: '12px' }}>
                        Verifikasi dengan Unduh dan Unggah Dokumen
                    </Typography>
                </Stack>
            </Button>
        </Grid>
    </Grid>
);

export default GridSignedOptions;
