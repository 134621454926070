// action - state management

import { LOADING, MODAL, MODAL_KONFIRMASI,GET_DATA_TMP, CLEAR_DATA_TMP } from './actions';

const initialState = {
    loading: {
        screen: false,
        button: false,
        table: false,
        form: false
    },
    modalkonfirmasi : {
        title : "",
        loading : false
    },

    datatmp : {},
    modal: {
        isOpen: false,
        disable: false,
        isEdit: false,
        fullscreen: false,
        title: '',
        form: '',
        data: []
    }
};

// ==============================|| SNACKBAR REDUCER ||============================== //

const utilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA_TMP:
            return {
                ...state,
                datatmp: action.payload
            };
        case CLEAR_DATA_TMP:
            return {
                ...state,
                datatmp: {}
            };
        case LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case MODAL:
            return {
                ...state,
                modal: action.payload
            };
        case MODAL_KONFIRMASI:
            return {
                ...state,
                modalkonfirmasi: action.payload
            };

        default:
            return state;
    }
};

export default utilityReducer;
