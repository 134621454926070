import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const RenderField = ({ input, label, meta: { touched, error }, required, disabled, ...rest }) => {
    return <TextField 
        {...input} 
        {...rest} 
        label={label} 
        error={touched && Boolean(error)} 
        helperText={touched && error} 
        fullWidth 
        required={required}
        disabled={disabled}/>;
};

RenderField.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    meta: PropTypes.object.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool
};

export default RenderField;
