/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { storageKey } from 'reduxStore/constant';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */

const AuthGuard = ({ children }) => {
    const [exp, setExp] = useState();
    const [formatExp, setFormatExp] = useState();
    const [currentTime, setCurrentTime] = useState(new Date());
    const userInfo = JSON.parse(localStorage.getItem(storageKey.USER_INFO));

    const navigate = useNavigate();

    useEffect(() => {
        if (userInfo && userInfo.exp) {
            setExp(userInfo.exp);
            const expDate = new Date(exp * 1000);
            setFormatExp(expDate.toLocaleString());
        }
    }, [exp, userInfo]);

    useEffect(() => {
        const currentTimestamp = Math.floor(currentTime / 1000);

        if (!localStorage.getItem('isLoggedIn')) {
            navigate('/login', { replace: true });
        } else if (formatExp && currentTimestamp >= exp) {
            navigate('/login', { replace: true });
        }
    }, [currentTime, formatExp, exp, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
