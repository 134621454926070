import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Field, reduxForm } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { RenderField, RenderDate, Col, Row, ModalGlobal, CustomButton, RenderUploadFile, PegawaiSelector, GelarUsulanSelector, JabatanSelector } from 'components';
import { hideModal } from 'reduxStore';

import validasiPemberianDubesDewatap from './validate';
import { servicePemberianDubesDewatap } from 'services/api/gelar-diplomatik/pemberian-dubes-dewatap';
import { formatDate } from 'utils';
import PerwakilanSelector from 'components/selector/perwakilanSelector';

const FormPemberianDubesDewatap = ({ handleSubmit, pristine, submitting, isEdit, title }) => {
    const dispatch = useDispatch();
    const service = servicePemberianDubesDewatap();

    const submit = (values) => {
        dispatch(service.prosesData(values));
    };

    const TitleLeft = ({ children }) => (
        <div style={{ textAlign: 'left' }}>{children}</div>
    );
    const [kdUnker, setKdUnker] = useState('');

    return (
        <ModalGlobal title={<TitleLeft>{title}</TitleLeft>} namaForm="FormPemberianDubesDewatap">
            <form onSubmit={handleSubmit(submit)}>
                <Box width="710px" sx={{ flexGrow: 1 }}>
                    <Field name="id" component={'input'} type="hidden" />
                    <Row spacing={2}>
                        <Col width="50%">
                            <Field label="No Sk" name="no_sk" component={RenderField} />
                        </Col>
                        <Col width="50%">
                            <Field label="Tanggal Sk" name="tgl_sk" views={['day', 'month', 'year']} openTo="day" component={RenderDate} />
                        </Col>
                    </Row>
                    <Row spacing={2}>
                        <Col width="100%">
                            <Field
                                label="NIP"
                                type="pemberian-dubes-dewatap"
                                module="gelar-diplomatik"
                                required={true}
                                getValue={(pegawai) => {
                                    dispatch(service.getDetailPegawai(pegawai?.nip));
                                    // if (!isEdit && pegawai?.nip) {
                                    // }
                                }}
                                name="nip"
                                component={PegawaiSelector}
                            />
                        </Col>
                    </Row>
                    <Row spacing={2} alignItems="center" justifyContent="center">
                        <Col width="50%">
                            {/* <GelarUsulanSelector label="Gelar Kenaikan" name="gelar_kenaikan"  /> */}
                            <Field
                                label="Gelar Kenaikan"
                                type="pemberian-dubes-dewatap"
                                module="gelar-diplomatik"
                                required={true}
                                getValue={(gelar_kenaikan) => {
                                    dispatch({ type: "SET_VALUE", payload: gelar_kenaikan?.kd_tkt_pdln });
                                    // if (!isEdit) {
                                    // }
                                }}
                                name="gelar_kenaikan"
                                component={GelarUsulanSelector}
                            />
                        </Col>
                        <Col width="50%">
                            <Field
                                label="TMT Gelar"
                                name="tmt_gelar"
                                views={['day', 'month', 'year']}
                                openTo="day"
                                component={RenderDate}

                            />
                        </Col>
                    </Row>
                    <Row spacing={2} alignItems="center" justifyContent="center">
                        <Col width="50%">
                            <Field
                                label="Perwakilan"
                                type="pemberian-dubes-dewatap"
                                module="gelar-diplomatik"
                                required={true}
                                getValue={(item) => {
                                    dispatch({ type: "SET_VALUE", payload: item?.kd_unker });
                                    setKdUnker(item?.kd_unker);
                                    // if (!isEdit && item?.kd_unker) {
                                    // }
                                }}
                                name="perwakilan"
                                component={PerwakilanSelector}
                            />
                        </Col>
                        <Col width="50%" spacing={2}>
                            <Field
                                label="Jabatan"
                                type="promosi-dubes-dewatap"
                                module="gelar-diplomatik"
                                required={true}
                                getValue={(item) => {

                                }}
                                name="kd_jabatan"
                                component={JabatanSelector}
                                unker={kdUnker}
                            />
                        </Col>

                    </Row>
                    <Row spacing={2}>
                        <Col width="50%">
                            <Field label="Nomor Keputusan" name="no_keputusan" component={RenderField} />
                        </Col>
                        <Col width="50%">
                            <Field
                                label="Tanggal Keputusan"
                                name="tgl_keputusan"
                                views={['day', 'month', 'year']}
                                openTo="day"
                                component={RenderDate}

                            />
                        </Col>
                    </Row>
                    <Row spacing={2}>
                        <Col width="100%">
                            <Field label={`Surat Keputusan ${isEdit ? '' : '*'}`} name="dokumen_keputusan" component={RenderUploadFile} />
                        </Col>
                    </Row>
                    <Row justifyContent="flex-end" spacing={2} padding="30px 0 25px 0px">
                        <CustomButton onClick={() => dispatch(hideModal('FormPromosiDubesDewatap'))} type="submit" variant="outlined">
                            Batal
                        </CustomButton>
                        <CustomButton loading disabled={pristine || submitting} type="submit" variant="contained">
                            {isEdit ? 'Update' : 'Tambah'}
                        </CustomButton>
                    </Row>
                </Box>
            </form>
        </ModalGlobal>
    );
};

const mapState = (state) => {
    let modal = state.utility.modal;
    if (modal.isEdit) {
        return {
            isEdit: modal.isEdit,
            title: modal.title,
            initialValues: {
                id: modal?.data?.id,
                no_sk: modal?.data?.no_sk,
                tgl_sk: formatDate(modal?.data?.tanggal_sk),
                nip: modal?.data?.nip,
                gelar_kenaikan: modal?.data?.gelar_kenaikan?.kd_tkt_pdln,
                perwakilan: modal?.data?.perwakilan?.kd_unker,
                no_keputusan: modal?.data?.no_keputusan,
                kd_jabatan: modal?.data?.kd_jabatan,
                tgl_keputusan: formatDate(modal?.data?.tanggal_keputusan),
                tmt_gelar: formatDate(modal?.data?.tmt_gelar)
            }
        };
    } else {
        return {
            isEdit: modal.isEdit,
            title: modal.title
        };
    }
};
const connector = connect(mapState);
const config = {
    form: 'FormPemberianDubesDewatap',
    enableReinitialize: true,
    destroyOnUnmount: true,
    validate: validasiPemberianDubesDewatap
};

export default connector(reduxForm(config)(FormPemberianDubesDewatap));
