/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Field } from 'redux-form';
import ReduxFormAutocomplete from '../field/renderSelect'; // Adjust the path as needed
import useDebounce from 'hooks/useDebounce';
import Loader from 'ui-component/Loader';
import { gelarUsulan } from 'services/api/master/master-gelar-usulan/index';

const GelarTerakhirSelector = (props) => {
    const {
        submitting,
        readOnly,
        label = '',
        getValue,
        required = false,
        input = { value: "", onChange: () => { }, name: "gelar_terakhir" },
        disabled = false
    } = props;

    const [searchTktPDLN, setSearchTktPDLN] = useState(input.value || input.value?.gelar_terakhir || '');
    const searchDataTktPDLN = useDebounce(searchTktPDLN, 300);


    const [listTktPDLN, setlistTktPDLN] = useState([]);
    const [loadingTktPDLN, setLoadingTktPDLN] = useState(false);
    const [pageTktPDLN, setPageTktPDLN] = useState(1);

    const fetchTktPDLN = useCallback(async () => {
        setLoadingTktPDLN(true);
        try {
            if (listTktPDLN.length > 0 && listTktPDLN.length % 20 !== 0) {
                setLoadingTktPDLN(false);
                return;
            }

            const res = await gelarUsulan({
                page: pageTktPDLN,
                limit: 20,
                search: searchDataTktPDLN || input.value || input.value?.gelar_terakhir
            });

            if (res && res.status) {
                const newData = res.data.list;
                // if (newData.length > 20) {
                //     setPageTktPDLN((prevPage) => prevPage + 1);
                // }
                setlistTktPDLN((prevList) => {
                    const newItems = newData.filter((newItem) => !prevList.some((prevItem) => prevItem?.kd_tkt_pdln === newItem?.kd_tkt_pdln));
                    return [...prevList, ...newItems];
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoadingTktPDLN(false);
        }
    }, [listTktPDLN.length, pageTktPDLN, searchDataTktPDLN,input.value]);

    useEffect(() => {
        fetchTktPDLN();
    }, [searchDataTktPDLN,input.value]);

    const handleScroll = useCallback(
        (event) => {
            const listboxNode = event.currentTarget;
            const position = listboxNode.scrollTop + listboxNode.clientHeight;
            const isAtBottom = listboxNode.scrollHeight - position <= 1;
            setPageTktPDLN((prevPage) => prevPage + 1);
            if (isAtBottom && !loadingTktPDLN) {
                fetchTktPDLN();
            }
        },
        [fetchTktPDLN, loadingTktPDLN]
    );

    const handleInputChange = useCallback(
        (e, newInput) => {
            const searchTktPDLN = newInput.split('-')[0]?.trim();
            setSearchTktPDLN(searchTktPDLN);
            if (input.onChange) {
                const foundTktPDLN = listTktPDLN.find((item) => item?.kd_tkt_pdln === searchTktPDLN);
                // console.log(foundTktPDLN);
                if (foundTktPDLN) {
                    input.onChange(foundTktPDLN?.kd_tkt_pdln);
                    getValue(foundTktPDLN);
                }
            }


        },
        [listTktPDLN, input.onChange, getValue]
    );

    const handleClose = useCallback(() => {
        console.log('Dropdown closed');
        setLoadingTktPDLN(true);
        input.onChange(null);
        getValue(null);
        setSearchTktPDLN('');
        setlistTktPDLN([]);
        setTimeout(() => {
            fetchTktPDLN();
        }, 300); // Fetch new data if needed
    }, [fetchTktPDLN]);

    return (
        <>
            {loadingTktPDLN && <Loader />}
            <Field
                name={input.name}
                component={ReduxFormAutocomplete}
                options={listTktPDLN.map((item) => ({
                    // label: `${item.kd_tkt_pdln} - ${item.gelar_pdln}`,
                    label: `${item.gelar_pdln}`,
                    value: item?.kd_tkt_pdln,
                }))}
                onClose={handleClose}
                readOnly={readOnly}
                label={`${label || 'kd_tkt_pdln - Gelar Diplomatik'} ${required ? '*' : ''}`}
                loading={submitting || loadingTktPDLN}
                onInputChange={handleInputChange}
                ListboxProps={{
                    onScroll: handleScroll
                }}
                disabled={disabled}
            />
        </>
    );
};

export default GelarTerakhirSelector;

