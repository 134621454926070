/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import './../../views/pages/style.css';

import { useEffect, useState } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { TextFieldStyle1 } from 'ui-component/style/textfield';
import { getAllJF } from 'services/api/jabatan-fungsional';
import useDebounce from 'hooks/useDebounce';

const FilterJabatanFungsional = ({ setJabatanSelection }) => {
    const [pageJabatan, setPageJabatan] = useState(1);
    const [searchJabatan, setSearchJabatan] = useState('');
    const [openJabatan, setOpenJabatan] = useState(false);
    const [listJabatan, setListJabatan] = useState([]);
    const [loadingJabatan, setLoadingJabatan] = useState(false);

    const searchDataJabatan = useDebounce(searchJabatan, 300);

    const fetchJabatanFungsional = async () => {
        setLoadingJabatan(true);
        try {
            // if (listJabatan.length > 0 && listJabatan.length % 100 !== 0) {
            //     setLoadingJabatan(false);
            // } else {
            const res = await getAllJF({ page: pageJabatan, limit: 100, search: searchDataJabatan });
            if (res && res.status) {
                const newData = res.data.list;
                if (newData.length >= 100) {
                    setPageJabatan(pageJabatan + 1);
                }
                // setListJabatan((prevList) => {
                //     const newItems = newData.filter((newItem) => !prevList.some((prevItem) => prevItem.kd_jabatan === newItem.kd_jabatan));
                //     return [...prevList, ...newItems];
                // });
                setListJabatan(newData);
            }
            // }
        } catch (e) {
            console.error(e);
        } finally {
            setLoadingJabatan(false);
        }
    };

    const handleScroll = (event) => {
        const listboxNode = event.currentTarget;
        const position = listboxNode.scrollTop + listboxNode.clientHeight;
        const currentScroll = listboxNode.scrollTop;

        if (listboxNode.scrollHeight - position <= 1) {
            if (loadingJabatan === false) {
                fetchJabatanFungsional().then(() => {
                    listboxNode.scrollTop = currentScroll;
                    setOpenJabatan(true);
                });
            }
        }
    };

    useEffect(() => {
        // if (openJabatan) {
        fetchJabatanFungsional();
        // }
    }, [searchDataJabatan]);

    return (
        <Grid item>
            <Autocomplete
                disablePortal
                className="filter-unker"
                sx={{ width: 250 }}
                open={openJabatan}
                onOpen={() => setOpenJabatan(true)}
                onClose={() => setOpenJabatan(false)}
                loading={loadingJabatan}
                onChange={(e, newValue) => {
                    setJabatanSelection(newValue ? newValue.kd_jabatan : '');
                }}
                onInputChange={(e, newInput) => {
                    const isAlpha = (input) => /^[a-zA-Z\s]*$/.test(input);
                    if (isAlpha(newInput)) {
                        setSearchJabatan(newInput);
                    }
                }}
                options={listJabatan}
                getOptionLabel={(option) => option.ket_jabatan}
                getOptionKey={(option) => option.kd_jabatan}
                renderInput={(params) => <TextField {...params} placeholder="Jabatan Fungsional" sx={TextFieldStyle1} />}
                ListboxProps={{
                    onScroll: handleScroll
                }}
            />
        </Grid>
    );
};

export default FilterJabatanFungsional;
