import React from 'react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { IconCheck, IconUpload } from '@tabler/icons';
import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';

const RenderUploadFile = ({ input, onChange, label, disabled = false, meta: { touched, error }, ...rest }) => {
    const idRef = useRef(null);

    const [NamaFile, setNamaFile] = useState('Upload File');
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const handleChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (onChange) {
                onChange(file);
            }
            input.onChange(file);
            setNamaFile(file.name);
            setIsFileUploaded(true);
        }
    };

    return (
        <Stack alignItems="center">
            <Typography textAlign="center">{label}</Typography>

            <Button
                fullWidth
                component="label"
                disableElevation
                variant="contained"
                type="button"
                startIcon={isFileUploaded ? <IconCheck size="16px" /> : <IconUpload size="16px" />}
                sx={{ borderRadius: rest.borderRadius }}
                disabled={disabled}
            >
                <Typography textAlign="center">{NamaFile || 'Upload File'}</Typography>
                <input
                    ref={idRef}
                    type="file"
                    name={input.name}
                    accept="application/pdf"
                    style={{ display: 'none' }}
                    onChange={handleChange}
                    {...rest}
                />
            </Button>
            {touched && error && (
                <Typography variant="caption" color="error" sx={{ mt: 1 }}>
                    {error}
                </Typography>
            )}
        </Stack>
    );
};

RenderUploadFile.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    meta: PropTypes.object.isRequired
};

export default RenderUploadFile;
