const validasiPemberianAnumerta = (value) => {
    const errors = {};

    // if (!value.no_sk) {
    //     errors.no_sk = 'No SK wajib diisi';
    // }

    if (!value.nip) {
        errors.nip = 'NIP wajib dipilih';
    }

   

    // if (!value.gelar_asal) {
    //     errors.gelar_asal = 'Gelar asal wajib diisi';
    // }

    if (!value.gerlar_terakhir) {
        errors.gerlar_terakhir = 'Gelar terakhir wajib diisi';
    }

    if (!value.gelar_kenaikan) {
        errors.gelar_kenaikan = 'Gelar kenaikan wajib diisi';
    }

    // if (!value.kd_jabatan) {
    //     errors.kd_jabatan = 'Jabatan wajib diisi';
    // }

    if (!value.no_keputusan) {
        errors.no_keputusan = 'No Surat Keputusan wajib diisi';
    }

    if (!value.tanggal_keputusan) {
        errors.tanggal_keputusan = 'Tanggal Suart Keputusan wajib diisi';
    }
    // if (!value.perwakilan) {
    //     errors.perwakilan = 'Perwakilan wajib diisi';
    // }

    if (!value.dokumen_keputusan) {
        errors.dokumen_keputusan = 'Surat keputusan wajib diisi';
    }

    return errors;
};

export default validasiPemberianAnumerta;
