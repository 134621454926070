// gelar-usulan

import ApiService from 'services/ApiService';
import { storageKey } from 'reduxStore/constant';

export const gelarUsulan = async (row) => {
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
            params: {
                filter: row.search,
                page: row.search ? undefined : row.page,
                limit: row.search ? undefined : row.rowsPerPage
            }
        }
    };

    let url = `${process.env.REACT_APP_API_URL}/api/v1/promosi-diplomatik/gelar-usulan`;

    return ApiService.get(url, config);
};
