import { Box, CardContent, Grid, Stack, Typography } from '@mui/material';
import { IconChecks, IconDownload, IconFile, IconFiles, IconPaperclip, IconPencil, IconUser, IconX } from '@tabler/icons';
import IconFileTypePdf from 'assets/icons/IconFileTypePdf';
import IconFileTypeWord from 'assets/icons/IconFileTypeWord';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import React, { useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Buttons from 'ui-component/button/Buttons';
import {
    StackHeaderOnModalDetail,
    StackOnModalDetail,
    StackOnModalDetail2,
    StackOnModalDetail3,
    StackOnModalDetail4
} from 'ui-component/stack/StackOnModalDetail';
import { ButtonError, ButtonSuccess, ButtonWarning, ButtonInfo } from 'ui-component/style/button';
import { convertDate } from 'utils';
import PDFViewer from 'views/pages/PDFViewer';
import DocxViewer from 'views/pages/DocxViewer';
import { servicePemberianDubesDewatap } from 'services/api/gelar-diplomatik/pemberian-dubes-dewatap';
import { showModalKonfirmasi } from 'reduxStore';
import {ModalKonfirmasi} from 'components';

const ViewPemberianDubesDewatap = (props) => {
    const role = localStorage.getItem('userRole');
    const [, copy] = useCopyToClipboard();
    const [url, setUrl] = useState(props?.data?.link_sk || props?.data?.dokumen_keputusan_file?.url || null);
    const service = servicePemberianDubesDewatap();
    
    const fileInputRef = useRef(null);

    // Function to trigger the file input click
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const uploadsk = (event) => {
        const file = event.target.files[0];
        let data = {
            dokumen_keputusan: file,
            id: props.data.id
        };

        dispatch(service.uploadSK(data));
    };

    const handleUpdateApproval = async (action,payload) => {
        dispatch(service.approval(action,payload))
    }

    const dispatch = useDispatch();

    return (
        <Box width="100%" sx={{ backgroundColor: 'white' }}>
            <ModalKonfirmasi
               data={props?.data}
               handleUpdateApproval={(action,payload)=>handleUpdateApproval(action,payload)} />
            <Box>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <StackHeaderOnModalDetail
                                title="SK"
                                Icon1={IconFile}
                                children={
                                    <Stack direction="row" spacing={1} sx={{ width: '100%', justifyContent: 'space-between' }}>
                                        <StackOnModalDetail title="Nomor SK" value={props?.data?.no_sk || '-'} />
                                        <StackOnModalDetail title="Tanggal SK" value={convertDate(props?.data?.tanggal_sk) || '-'} />
                                    </Stack>
                                }
                            />
                            <StackHeaderOnModalDetail
                                title="Data Pegawai"
                                Icon1={IconUser}
                                children={
                                    <>
                                        <Stack direction="row" spacing={1}>
                                            <Stack direction="column" sx={{ width: '100%' }}>
                                                <Typography sx={{ fontWeight: 700, fontSize: '24px', color: 'rgba(64, 64, 65, 1)' }}>
                                                    {props?.data?.pegawai?.nama}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <StackOnModalDetail2
                                                title="NIP"
                                                value={props?.data?.pegawai?.nip}
                                                onClickIcon={() => {
                                                    copy(`${props?.data?.pegawai?.nip}`);
                                                }}
                                                Icon={IconFiles}
                                            />
                                        </Stack>
                                    </>
                                }
                            />
                            <Grid container spacing={1} sx={{ mt: 1 }}>
                                <Grid item xs={4}>
                                    <StackOnModalDetail3 title="Gelar Kenaikan" value={props?.data?.gelar_kenaikan?.gelar_pdln || '-'} />
                                </Grid>
                                <Grid item xs={4}>
                                    <StackOnModalDetail3 title="TMT Gelar" value={convertDate(props?.data?.tmt_gelar) || '-'} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ mt: 1 }}>
                                <Grid item xs={4}>
                                    <StackOnModalDetail3 title="Perwakilan" value={props?.data?.perwakilan?.nama_unker || '-'} />
                                </Grid>
                                <Grid item xs={4}>
                                    <StackOnModalDetail3 title="Jabatan" value={props?.data?.jabatan?.ket_jabatan || '-'} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ mt: 1 }}>
                                <Grid item xs={4}>
                                    <StackOnModalDetail3 title="No Keputusan" value={props?.data?.no_keputusan || '-'} />
                                </Grid>
                                <Grid item xs={4}>
                                    <StackOnModalDetail3 title="Tanggal Keputusan" value={convertDate(props?.data?.tanggal_keputusan) || '-'} />
                                </Grid>
                            </Grid>
                            <Grid sx={{ mt: 3 }}>
                                <StackHeaderOnModalDetail
                                    title="Dokumen Upload"
                                    subtitle="Unduh Semua"
                                    Icon1={IconPaperclip}
                                    Icon2={IconDownload}
                                    onClickSub={() => window.open(props?.data?.dokumen_keputusan_file?.url, '_blank', 'noopener noreferrer')}
                                    children={
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <StackOnModalDetail4
                                                // Icon={props.data.status?.status === 'Selesai' ? IconFileTypePdf : IconFileTypeWord}
                                                Icon={IconFileTypePdf}
                                                title={'Surat Keputusan'}
                                                sub1={props?.data?.dokumen_keputusan_file?.file_size || '0'}
                                                sub2="Unduh"
                                                onClick={() => setUrl(props?.data?.dokumen_keputusan_file?.url)}
                                                onClickSub2={() => window.open(props?.data?.dokumen_keputusan_file?.url, '_blank', 'noopener noreferrer')}
                                            />
                                        </Grid>
                                    }
                                />
                            </Grid>
                            {
                                props?.data?.link_sk !== null ?
                                    <Grid sx={{ mt: 3 }}>
                                        <StackHeaderOnModalDetail
                                            title="Dokumen Final"
                                            subtitle="Unduh Semua"
                                            Icon1={IconPaperclip}
                                            Icon2={IconDownload}
                                            onClickSub={() => window.open(props?.data?.link_sk, '_blank', 'noopener noreferrer')}
                                            children={
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <StackOnModalDetail4
                                                        // Icon={props.data.status?.status === 'Selesai' ? IconFileTypePdf : IconFileTypeWord}
                                                        Icon={props?.data?.link_sk.endsWith(".pdf") ? IconFileTypePdf : IconFileTypeWord}
                                                        title={'Draft SK'}
                                                        sub1={props?.data?.file_sk_size || '0'}
                                                        sub2="Unduh"
                                                        onClick={() => setUrl(props?.data?.link_sk)}
                                                        onClickSub2={() => window.open(props?.data?.link_sk, '_blank', 'noopener noreferrer')}
                                                    />
                                                </Grid>
                                            }
                                        />
                                    </Grid> 
                                : null
                            }
                            {role !== 'Admin AJGP' &&(
                                <StackHeaderOnModalDetail
                                    title="Verifikasi"
                                    Icon1={IconChecks}
                                    sx={{ backgroundColor: 'rgba(242, 242, 242, 1)', borderRadius: '8px', p: 1 }}
                                    children={
                                        <>
                                            <Stack direction="row" spacing={1.5}>
                                                <Buttons
                                                    sx={ButtonSuccess(true)}
                                                    title={'Terima'}
                                                    startIcon={<IconChecks size="16px" />}
                                                    onClick={() =>
                                                       dispatch(showModalKonfirmasi({
                                                        title: 'Disetujui'
                                                    }))
                                                    }
                                                    disable={
                                                        role === 'Kasubag BSDM'
                                                            ? !(
                                                                props?.data?.status?.status === 'Pengusulan' ||
                                                                props?.data?.status?.status === 'Telah Dilakukan Perbaikan Data'
                                                              )
                                                            : role === 'Kabag BSDM'
                                                            ? !(
                                                                props?.data?.status?.status === 'Disetujui Kasubag' ||
                                                                props?.data?.status?.status === 'Telah Dilakukan Perbaikan Data'
                                                              )
                                                            : true
                                                    }
                                                />
                                                <Buttons sx={ButtonWarning(true)} 
                                                    onClick={() =>
                                                        dispatch( showModalKonfirmasi({
                                                            title: 'Revisi'
                                                        }))
                                                    }
                                                    title="Revisi" startIcon={<IconPencil size="16px" />} 
                                                    disable={
                                                        role === 'Kasubag BSDM'
                                                            ? !(
                                                                props?.data?.status?.status === 'Pengusulan' ||
                                                                props?.data?.status?.status === 'Telah Dilakukan Perbaikan Data'
                                                            )
                                                            : role === 'Kabag BSDM'
                                                            ? !(
                                                                props?.data?.status?.status === 'Disetujui Kasubag' ||
                                                                props?.data?.status?.status === 'Telah Dilakukan Perbaikan Data'
                                                            )
                                                            : true
                                                    }
                                                />
                                                <Buttons
                                                    onClick={() =>
                                                        dispatch( showModalKonfirmasi({
                                                            title: 'Ditolak'
                                                        }))
                                                    }
                                                    sx={ButtonError(true)} title="Tolak" startIcon={<IconX size="16px" />} 
                                                    disable={
                                                        role === 'Kasubag BSDM'
                                                            ? !(
                                                                props?.data?.status?.status === 'Pengusulan' ||
                                                                props?.data?.status?.status === 'Telah Dilakukan Perbaikan Data'
                                                            )
                                                            : role === 'Kabag BSDM'
                                                            ? !(
                                                                props?.data?.status?.status === 'Disetujui Kasubag' ||
                                                                props?.data?.status?.status === 'Telah Dilakukan Perbaikan Data'
                                                            )
                                                            : true
                                                    }
                                                />
                                            </Stack>
                                        </>
                                    }
                                />
                            )}
                            {(role === 'Admin AJGP' || role === 'Kasubag BSDM' || role === 'Super Admin') && (props?.data?.status.status==='Disetujui Kabag') &&(
                                <StackHeaderOnModalDetail
                                    title="Upload SK dari Menteri Luar Negeri"
                                    Icon1={IconChecks}
                                    sx={{ backgroundColor: 'rgba(242, 242, 242, 1)', borderRadius: '8px', p: 1 }}
                                    children={
                                        <>
                                            <Field
                                                name="upload_sk"
                                                component={({ input }) => (
                                                    <input
                                                        ref={fileInputRef}
                                                        type="file"
                                                        accept=".pdf"
                                                        style={{ display: 'none' }} // Hide the input
                                                        onChange={(event) => uploadsk(event, input)} // Handle file selection
                                                    />
                                                )}
                                            />

                                            <Stack direction="row" spacing={1.5}>
                                                <Buttons
                                                    sx={ButtonInfo(true)}
                                                    title={'Uplaod SK'}
                                                    type={'button'}
                                                    startIcon={<IconChecks size="16px" />}
                                                    onClick={handleButtonClick} // Handle button click
                                                />
                                            </Stack>
                                        </>
                                    }
                                />
                            )}
                        </Grid>
                        <Grid item sm={6} sx={{ minHeight: 'calc(100vh - 105.95px)' }}>
                        {url ? 
                            url.endsWith('.pdf') ? 
                                <PDFViewer fileUrl={url} />
                            : 
                                <DocxViewer fileUrl={url} />
                            : null
                         }
                        </Grid>
                    </Grid>
                </CardContent>
            </Box>
        </Box>
    );
};

const mapState = (state) => {
    let modal = state.utility.modal;
    return {
        isEdit: modal.isEdit,
        data: modal.data
    };
};
const connector = connect(mapState);
const config = {
    form: 'ViewPemberianDubesDewatap',
    enableReinitialize: true,
    destroyOnUnmount: true
};

export default connector(reduxForm(config)(ViewPemberianDubesDewatap));
