function IconFileTypeWord({ size, ...props }) {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M8.725 10.414a.242.242 0 10.483 0 .242.242 0 00-.483 0zM7.738 12.586h1.249l-.625-.624-.624.624z" fill="#E4EAF6" />
            <path
                d="M8.191 11.45a.241.241 0 01.341 0l.433.433 1.399-1.398a.241.241 0 01.34 0l.675.674V9.93H9.504a.725.725 0 11-1.078 0H7.034v2.655h.021L8.19 11.45z"
                fill="#E4EAF6"
            />
            <path d="M10.534 10.997l-1.227 1.227.362.362h1.71v-.744l-.845-.845z" fill="#E4EAF6" />
            <path
                d="M12.103 3.897a.724.724 0 01-.724-.725V1H4.862c-.4 0-.724.324-.724.724v1.69h6.276c.266 0 .482.216.482.483v3.379a.483.483 0 01-.482.483H4.138v6.517c0 .4.324.724.724.724h8.69c.4 0 .724-.324.724-.724V3.896h-2.173z"
                fill="#E4EAF6"
            />
            <path d="M12.103 3.897h2.172L11.38 1v2.172c0 .4.324.725.724.725z" fill="#D2DCF0" />
            <path d="M4.018 4.862h-.845v.724h.845a.362.362 0 000-.724z" fill="#295497" />
            <path
                d="M10.897 7.276v-3.38a.483.483 0 00-.483-.482H2.208a.483.483 0 00-.483.482v3.38c0 .266.216.483.482.483h8.207a.483.483 0 00.483-.483z"
                fill="#295497"
            />
            <path
                d="M7.661 9.222L7 9.286l.694 3.524.694.063.496-2.635.496 2.699.794.063L11 9l-.76.063-.463 2.858-.496-2.794-.661.032-.562 2.762-.397-2.699z"
                fill="#295597"
            />
            <path
                d="M3.032 6.8c-.091 0-.161-.025-.21-.074-.049-.049-.074-.119-.074-.21v-1.9c0-.091.025-.161.074-.21.049-.049.119-.074.21-.074h.683c.422 0 .747.108.976.322.231.213.346.516.346.91 0 .199-.03.375-.09.529-.059.152-.144.28-.256.385a1.13 1.13 0 01-.413.241c-.163.054-.351.081-.563.081h-.683zm.259-.445h.392c.133 0 .249-.016.346-.049a.615.615 0 00.245-.147.615.615 0 00.147-.245c.033-.1.05-.217.05-.35 0-.266-.067-.463-.2-.591-.13-.13-.327-.196-.588-.196h-.392v1.578zm3.312.483c-.242 0-.456-.052-.64-.157a1.128 1.128 0 01-.427-.448 1.42 1.42 0 01-.15-.669c0-.193.027-.367.083-.521a1.123 1.123 0 01.634-.658c.152-.06.318-.091.5-.091.248 0 .462.052.644.157.182.105.324.254.424.445.103.189.154.41.154.665 0 .194-.03.369-.088.525a1.1 1.1 0 01-.633.662c-.15.06-.316.09-.5.09zm0-.462a.622.622 0 00.35-.094.613.613 0 00.224-.28c.054-.124.081-.27.081-.438 0-.256-.057-.455-.172-.595a.584.584 0 00-.483-.213.633.633 0 00-.35.094.591.591 0 00-.224.277 1.12 1.12 0 00-.077.437c0 .255.058.454.172.599a.58.58 0 00.48.213zm2.839.462c-.266 0-.494-.052-.683-.157a1.09 1.09 0 01-.437-.441c-.1-.191-.15-.417-.15-.676 0-.193.027-.367.083-.521a1.099 1.099 0 01.651-.658 1.49 1.49 0 01.89-.046c.12.028.225.07.314.123a.248.248 0 01.137.27.227.227 0 01-.06.125.2.2 0 01-.115.067.24.24 0 01-.158-.032.787.787 0 00-.22-.09.992.992 0 00-.235-.029.77.77 0 00-.395.095.576.576 0 00-.238.266c-.054.117-.08.26-.08.43 0 .168.026.312.08.431.053.119.133.21.238.273.107.06.239.091.395.091a.964.964 0 00.455-.116c.06-.03.114-.04.161-.031a.187.187 0 01.12.063.207.207 0 01.059.119.242.242 0 01-.12.259 1.025 1.025 0 01-.318.136c-.126.033-.25.05-.374.05z"
                fill="#fff"
            />
        </svg>
    );
}

export default IconFileTypeWord;
