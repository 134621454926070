import { storageKey } from 'reduxStore/constant';
import ApiService from 'services/ApiService';

export const getGender = async () => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/api/v1/master-data/jenis-kelamin`, config);
};

export const getReligion = async () => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/api/v1/master-data/agama`, config);
};

export const getEducation = async () => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/api/v1/master-data/pendidikan`, config);
};

export const getGolP3K = async () => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/api/v1/master-data/golongan-p3k`, config);
};

export const getStsPernikahan = async () => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/api/v1/master-data/status-pernikahan`, config);
};

export const getStsDinas = async ({ filter, page, limit }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(
        `${process.env.REACT_APP_API_URL}/api/v1/master-data/status-dinas?filter=${filter}&page=${page}&limit=${limit}`,
        config
    );
};

export const getWorkingUnit = async ({ filter, page, limit }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(
        `${process.env.REACT_APP_API_URL}/api/v1/master-data/unit-kerja?filter=${filter || ''}&page=${page}&limit=${limit}`,
        config
    );

    // const queryParams = new URLSearchParams();
    // if (filter) queryParams.append('filter', filter);
    // if (page) queryParams.append('page', page);
    // if (limit) queryParams.append('limit', limit);
    // return ApiService.get(`${process.env.REACT_APP_API_URL}/api/v1/master-data/unit-kerja?${queryParams.toString()}`, config);
};

export const getPosition = async ({ filter, page, limit, unKer, module, type }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(
        `${process.env.REACT_APP_API_URL}/api/v1/master-data/jabatan?filter=${filter}&page=${page}&limit=${limit}&unit_kerja=${unKer}&module=${module}&type=${type}`,
        config
    );

    // const queryParams = new URLSearchParams();
    // if (filter) queryParams.append('filter', filter);
    // if (page) queryParams.append('page', page);
    // if (limit) queryParams.append('limit', limit);
    // if (unKer) queryParams.append('unit_kerja', unKer);
    // return ApiService.get(`${process.env.REACT_APP_API_URL}/api/v1/master-data/jabatan?${queryParams.toString()}`, config);
};
