/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useRef } from 'react';
import mammoth from 'mammoth';
import './DocxViewer.css'; // Custom CSS for styling

const DocxViewer = ({ fileUrl, options = {} }) => {
    const [docxContent, setDocxContent] = useState('');
    const [originalContent, setOriginalContent] = useState(''); // To store the original content
    const [searchTerm, setSearchTerm] = useState('');
    const [zoomLevel, setZoomLevel] = useState(1);
    const viewerRef = useRef(null);

    useEffect(() => {
        const fetchDocxFile = async () => {
            try {
                const response = await fetch(fileUrl);
                const arrayBuffer = await response.arrayBuffer();
                const result = await mammoth.convertToHtml({ arrayBuffer }, options);
                setDocxContent(result.value);
                setOriginalContent(result.value); // Save the original content
            } catch (error) {
                console.error('Error fetching or converting the docx file:', error);
            }
        };

        fetchDocxFile();
    }, [fileUrl, options]);

    const handleSearch = () => {
        if (!searchTerm) {
            resetSearch();
            return;
        }

        const viewerElement = viewerRef.current;
        const content = originalContent;

        const regex = new RegExp(searchTerm, 'gi');
        const newContent = content.replace(regex, (match) => `<mark>${match}</mark>`);

        viewerElement.innerHTML = newContent;
    };

    const resetSearch = () => {
        setDocxContent(originalContent);
        if (viewerRef.current) {
            viewerRef.current.innerHTML = originalContent;
        }
    };

    const handleZoomIn = () => setZoomLevel(zoomLevel + 0.1);
    const handleZoomOut = () => setZoomLevel(zoomLevel - 0.1);
    const resetZoom = () => setZoomLevel(1);

    return (
        <div className="docx-viewer">
            <div className="toolbar">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search..."
                    className="search-input"
                />
                <button onClick={handleSearch} className="toolbar-button">Search</button>
                <button onClick={handleZoomIn} className="toolbar-button">Zoom In</button>
                <button onClick={handleZoomOut} className="toolbar-button">Zoom Out</button>
                <button onClick={resetZoom} className="toolbar-button">Reset Zoom</button>
            </div>
            <div
                ref={viewerRef}
                className="docx-content"
                style={{
                    transform: `scale(${zoomLevel})`,
                    transformOrigin: '0 0',
                    ...options.containerStyle,
                }}
                dangerouslySetInnerHTML={{ __html: docxContent }}
            />
        </div>
    );
};

export default DocxViewer;
