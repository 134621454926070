// import dayjs from 'dayjs';

import moment from 'moment';
import { openModal } from './helperRedux';

export const convertDate = (dateString) => {
    if (!dateString) return null
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('id-ID', options);
};

export const formatDate = (date, format) => {
    if (!date) return null;
    return moment(new Date(date)).format(format ? format : 'YYYY-MM-DD');
};

export const userRole = localStorage.getItem('userRole');

export function appendToFormData(formData, data) {
    Object.keys(data).forEach(key => {
        if (data[key] !== undefined && data[key] !== null && data[key] !== '') {
            formData.append(key, data[key]);
        }
    });
}

export const timeout = (time = 100)=> {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

export { openModal };
