// import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Alert, Grid, Snackbar, Stack } from '@mui/material';
import { useState } from 'react';

import AuthWrapper from '../AuthWrapper';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthLogin from '../auth-forms/AuthLogin';

// import logo from 'assets/images/logo_eLayanan/logoKemluVertikal.png';

// ================================|| AUTH3 - LOGIN ||================================ //

// eslint-disable-next-line react/prop-types
const Login = ({ isUnderMaintenance }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };
    const handleClose3 = () => {
        setOpen3(false);
    };

    return (
        <AuthWrapper>
            <Stack justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
                {/* <Link to="#">
                    <img src={logo} alt="Kemlu Logo" width="230" />
                </Link> */}
                <AuthCardWrapper>
                    <AuthLogin
                        isUnderMaintenance={isUnderMaintenance}
                        open3={open3}
                        setOpen={setOpen}
                        setOpen2={setOpen2}
                        setOpen3={setOpen3}
                        handleClose3={handleClose3}
                    />
                </AuthCardWrapper>
                {isUnderMaintenance && (
                    <Grid
                        container
                        justifyContent="left"
                        alignContent="baseline"
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            marginBottom: '20px',
                            marginLeft: '20px',
                            zIndex: theme.zIndex.snackbar
                        }}
                    >
                        <Snackbar open={open} onClose={handleClose}>
                            <Alert
                                variant="filled"
                                severity="warning"
                                sx={{ color: theme.palette.grey[800], bgcolor: theme.palette.warning.dark }}
                                onClose={handleClose}
                            >
                                Sistem sedang dalam perbaikan
                            </Alert>
                        </Snackbar>
                    </Grid>
                )}
                <Grid
                    container
                    justifyContent="left"
                    alignContent="baseline"
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        marginBottom: '20px',
                        marginLeft: '20px',
                        zIndex: theme.zIndex.snackbar
                    }}
                >
                    <Snackbar open={open2} onClose={handleClose2}>
                        <Alert
                            variant="filled"
                            severity="error"
                            sx={{ color: theme.palette.grey[100], bgcolor: theme.palette.error.dark }}
                            onClose={handleClose2}
                        >
                            Tidak Ada Koneksi Internet
                        </Alert>
                    </Snackbar>
                </Grid>
            </Stack>
        </AuthWrapper>
    );
};

export default Login;
