import React, { useEffect, useState, useCallback } from 'react';
import Loader from 'ui-component/Loader';
import useDebounce from 'hooks/useDebounce';
import { Field } from 'redux-form';
import ReduxFormAutocomplete from '../field/renderSelect';

const KeteranganPengusulanSelector = (props) => {
    const { 
        submitting, 
        name, 
        label,
        disabled,
        getValue,
        input= { value:"", onChange:()=>{} }
    } = props;

    const [listData, setlistData] = useState([]);
    const [loading, setloading] = useState(false);
    const [search, setSearch] = useState(input.value || '');
    const searchData = useDebounce(search, 300);

    useEffect(() => {
        const dataArray = [
            { value: "Batas Usia Pensiun" },
            { value: "Meninggal" }
        ];

        setlistData(dataArray)
        setloading(false)
    }, [searchData]);

    const handleInputChange = useCallback(
        (search) => {
         
            setSearch(search);
            if (input.onChange) {
                const data = listData.find((item) => item.value === search);
                if (data) {
                    input.onChange(data.name);
                    getValue(data);
                }
            }
        },
        [listData, input, getValue, setSearch]
    );

    const handleClose = useCallback(() => {
        setloading(false);
        input.onChange(null);
        setSearch('');
        setlistData([]);
    }, [setloading, input, setSearch, setlistData]);

    return (
        <>
            {loading && <Loader />}
            <Field
                name={name}
                label={label}
                onClose={handleClose}
                component={ReduxFormAutocomplete}
                options={listData.map((item) => ({
                    label: item.value,
                    value: item.value
                }))}
                onChange={handleInputChange}
                loading={submitting || loading}
                disabled={disabled}
            />
        </>
    );
};

export default KeteranganPengusulanSelector;