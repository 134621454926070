/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Field, change } from 'redux-form';
import { useDispatch } from 'react-redux';
import ReduxFormAutocomplete from '../field/renderSelect'; // Adjust the path as needed
import useDebounce from 'hooks/useDebounce';
import Loader from 'ui-component/Loader';
import { getPosition } from 'services/api/master/master-data';

const JabatanSelector = (props) => {
    const {
        submitting,
        readOnly,
        label = '',
        getValue,
        module = 'jabatan-fungsional',
        type = 'pengangkatan-pertama',
        unker,
        required = false,
        input = { value: "", onChange: () => { }, name: 'kd_jabatan' },
        disabled = false
    } = props;

    const dispatch = useDispatch();

    const [searchKdJabatan, setSearch] = useState(props?.input?.value || props?.input?.value?.kd_jabatan || '');
    const searchData = useDebounce(searchKdJabatan, 300);

    const [listData, setlistData] = useState([]);
    const [loading, setloading] = useState(false);
    const [pageKode, setKode] = useState(1);
    const [limit, setLimit] = useState(20);
    
    const fetachData = useCallback(async () => {
        setloading(true);
        try {
            if (module === 'gelar-diplomatik' && (type !== "pengabdian" && type !== "pemberian-anumerta")) {
                setSearch("");
            }
            const res = await getPosition({
                filter: searchData || props?.input?.value || "",
                page: pageKode,
                limit: limit,
                unKer: unker || props?.unker || '',
                module: module,
                type: type
            });

            if (res && res.status) {
                const newData = res.data.list;
                setlistData((prevList) => {
                    const newItems = newData?.filter((newItem) => !prevList.some((prevItem) => prevItem?.kd_jabatan === newItem?.kd_jabatan));
                    return [...prevList, ...newItems];
                });

                if (newData?.length === 1) {
                    if (module === 'gelar-diplomatik') {
                        if (type === 'promosi-konsul') {
                            dispatch(change("FormPromosiKonsuler", 'kd_jabatan', newData[0].kd_jabatan || ""));
                        } else if (type === 'promosi-konjen') {
                            dispatch(change("FormPromosiKonjen", 'kd_jabatan', newData[0].kd_jabatan || ""));
                        } else if (type === 'promosi-dubes') {
                            dispatch(change("FormPromosiDubes", 'kd_jabatan', newData[0].kd_jabatan || ""));
                        } else if (type === 'promosi-dcm') {
                            dispatch(change("FormPromosiDCM", 'kd_jabatan', newData[0].kd_jabatan || ""));
                        }
                    }
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    }, [listData.length, module, pageKode, searchData, type, unker, searchKdJabatan, limit, props?.input?.value, input.onChange ]);

    useEffect(() => {
        setlistData([]);
        fetachData();
    }, [input.value, searchData, unker,]);

    const handleScroll = useCallback(
        (event) => {
            const listboxNode = event.currentTarget;
            const position = listboxNode.scrollTop + listboxNode.clientHeight;
            const isAtBottom = listboxNode.scrollHeight - position <= 1;
            
            setLimit((prevPage) => prevPage + 20);
            if (isAtBottom && !loading) {
                fetachData();
            }
        },
        [fetachData, loading]
    );

    const handleInputChange = useCallback(
        async (e, newInput) => {
            const search = newInput?.split('-')[0]?.trim();
            setSearch(search);
            await fetachData();
            if (input.onChange) {
                const foundKdJabatan = listData.find((item) => item?.kd_jabatan === search);
                // console.log(foundKdJabatan);
                if (foundKdJabatan) {
                    input.onChange(foundKdJabatan.kd_jabatan);
                    getValue(foundKdJabatan);
                }
            }


        },
        [listData, input.onChange, getValue, input.value]
    );

    const handleClose = useCallback(() => {
        setloading(true);
        input.onChange(null);
        getValue(null);
        setSearch('');
        setlistData([]);
        setKode(1);
        setTimeout(() => {
            fetachData();
        }, 300); // Fetch new data if needed
    }, [fetachData]);

    return (
        <>
            {loading && <Loader />}
            <Field
                name={input.name}
                component={ReduxFormAutocomplete}
                options={listData.map((item) => ({
                    label: item?.ket_jabatan,
                    value: item?.kd_jabatan
                }))}
                onClose={handleClose}
                readOnly={readOnly}
                label={`${label || 'Jabatan'} ${required ? '*' : ''}`}
                loading={submitting || loading}
                onChange={handleInputChange}
                ListboxProps={{
                    onScroll: handleScroll
                }}
                disabled={disabled}
            />
        </>
    );
};

export default JabatanSelector;
