/* eslint-disable react-hooks/rules-of-hooks */
import { useTheme } from '@mui/material/styles';
import { Grid, IconButton, Typography } from '@mui/material';
import { IconSquare } from '@tabler/icons';
import { IconSquareCheckFilled } from '@tabler/icons-react';
import PropTypes from 'prop-types';

export const renderCheckbox = ({ input, label, disabled = false }) => {
  const theme = useTheme();
  const isChecked = input.value;

  const handleClick = () => {
    input.onChange(!isChecked);
  };

  return (
    <Grid container alignItems="center" onClick={!disabled ? handleClick : null} sx={{ cursor: 'pointer' }}>
      <Grid item>
        <IconButton sx={{ m: 0, p: 0 }} disabled={disabled}>
          {isChecked ? (
            <IconSquareCheckFilled size="30px" style={{ color: theme.palette.success.dark }} />
          ) : (
            <IconSquare size="30px" />
          )}
        </IconButton>
      </Grid>
      <Grid item>
        <Typography sx={{ fontWeight: 400, fontSize: '14px', color: '#404041', ml: 1 }}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

renderCheckbox.defaultProps = {
  label: '',
};
