// Toast.js
import Swal from 'sweetalert2';

export const ToastNotification = (icon, message) => {
    return Swal.fire({
        toast: true,
        position: 'top-end',
        icon: icon || 'info',
        title: message,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
    });
};
export const NotificationModal = (icon, title, message) => {
    return Swal.fire({
        title: title || 'Success!',
        text: message,
        icon: icon || 'info',
        confirmButtonText: 'OK'
    });
};
