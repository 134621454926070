import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Field, reduxForm } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { RenderField, RenderDate, Col, Row, CustomButton, RenderUploadFile, PegawaiSelector, GelarUsulanSelector, JabatanSelector, GelarTerakhirSelector, PerwakilanSelector } from 'components';
import { hideModal } from 'reduxStore';

import validasiPemberianAnumerta from './validate';
import validasiPemberianAnumertaAjgp from './validate-ajgp';
import { formatDate } from 'utils';
import { servicePemberianAnumerta } from 'services/api/gelar-diplomatik/pemeberian-anumerta';
import imgArrow from 'assets/images/icons/arrowRight.svg';

const FormPemberianAnumerta = ({ handleSubmit, pristine, isEdit, submitting }) => {
    const dispatch = useDispatch();

    const service = servicePemberianAnumerta();
    const submit = (values) => {
        dispatch(service.prosesData(values));
    };
    const userRole = localStorage.getItem('userRole');

    const [kdUnker, setKdUnker] = useState('');

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Box width="710px" sx={{ flexGrow: 1 }}>
                <Field name="id" component={'input'} type="hidden" />
                {userRole === "Admin AJGP" ? (
                    <>
                        <Row spacing={2}>
                            <Col width="50%">
                                <Field label="No Sk" name="no_sk" component={RenderField} />
                            </Col>
                            <Col width="50%">
                                <Field label="Tanggal Sk" name="tanggal_sk" views={['day', 'month', 'year']} openTo="day" component={RenderDate} />
                            </Col>
                        </Row>
                        <Row spacing={2}></Row>
                    </>
                ) : null}
                {/* {userRole !== "Admin AJGP" ? (
                    <> */}
                <Row spacing={2}>
                    <Col width="100%">
                        <Field
                            label="NIP"
                            type="pemberian-anumerta"
                            module="gelar-diplomatik"
                            required={true}
                            getValue={(pegawai) => {
                                dispatch(service.getDetailPegawai(pegawai?.nip));
                                // if (!isEdit && pegawai?.nip) {
                                // }
                            }}
                            name="nip"
                            component={PegawaiSelector}
                        />
                    </Col>
                </Row>
                {/* <Row spacing={2} alignItems="center" justifyContent="center">
                    <Col width="50%">
                        <Field label="Gelar Sebelumnya" name="gelar_asal" component={RenderField} />
                    </Col>
                    <Col width="50%">
                        <Field label="Gelar Kenaikan" name="gelar_usulan" component={RenderField} />
                    </Col>
                </Row> */}
                <Row spacing={2} alignItems="center" justifyContent="center">
                    <Col width="45%">
                        {/* <Field label="Gelar Terakhir" name="gelar_terakhir" component={RenderField} /> */}
                        <Field
                            label="Gelar Terakhir"
                            type="promosi-anumerta"
                            module="gelar-diplomatik"
                            required={true}
                            getValue={(gelar_terakhir) => {
                                // Jika dalam mode add, set nilai default
                                dispatch({ type: "SET_VALUE", payload: gelar_terakhir?.kd_tkt_pdln });
                                // if (!isEdit) {
                                // }
                            }}
                            name="gelar_terakhir"
                            component={GelarTerakhirSelector}
                        />
                    </Col>
                    <Col width="5%" alignItems="center">
                        <img src={imgArrow} width={40} height={40} alt="img" />
                    </Col>
                    <Col width="45%">
                        {/* <GelarUsulanSelector label="Gelar Kenaikan" name="gelar_kenaikan"  /> */}
                        <Field
                            label="Gelar Kenaikan"
                            type="promosi-anumerta"
                            module="gelar-diplomatik"
                            required={true}
                            getValue={(gelar_kenaikan) => {
                                dispatch({ type: "SET_VALUE", payload: gelar_kenaikan?.kd_tkt_pdln });
                                // if (!isEdit) {
                                // }
                            }}
                            name="gelar_kenaikan"
                            component={GelarUsulanSelector}
                        />
                    </Col>
                </Row>

                <Row spacing={2}>
                    <Col width="50%">
                        <Field label="SK Gelar Terakhir" name="sk_gelar_terakhir" component={RenderField} />
                    </Col>
                    <Col width="50%">
                        <Field label="Tmt Gelar" name="tmt_gelar" views={['day', 'month', 'year']} openTo="day" component={RenderDate} />
                    </Col>
                </Row>
                {/* <Row spacing={2}>
                    <Col width="50%">
                        <Field label="Perwakilan" name="kd_perwakilan" component={RenderField} />
                        <Field
                            label="Perwakilan"
                            type="pemberian-anumerta"
                            module="gelar-diplomatik"
                            required={true}
                            getValue={(item) => {
                                dispatch({ type: "SET_VALUE", payload: item?.kd_unker });
                                setKdUnker(item?.kd_unker)
                                if (!isEdit && item?.kd_unker) {
                                }
                            }}
                            name="perwakilan"
                            component={PerwakilanSelector}
                        />
                    </Col>
                    <Col width="50%">
                        <Field label="Jabatan" name="kd_jabatan" component={RenderField} />
                        <Field
                            label="Jabatan"
                            type="promosi-anumerta"
                            module="gelar-diplomatik"
                            required={true}
                            getValue={(item) => {
                                dispatch({ type: "SET_VALUE", payload: item?.kd_jabatan });
                                if (!isEdit) {
                                }
                            }}
                            name="kd_jabatan"
                            component={JabatanSelector}
                            unker={kdUnker}
                        />
                    </Col>
                </Row> */}
                <Row spacing={2}>
                    <Col width="50%">
                        <Field label="No Dokumen Pendukung" name="no_keputusan" component={RenderField} />
                    </Col>
                    <Col width="50%">
                        <Field
                            label="Tanggal Dokumen Pendukung"
                            name="tanggal_keputusan"
                            views={['day', 'month', 'year']}
                            openTo="day"
                            component={RenderDate}
                        />
                    </Col>
                </Row>

                <Row spacing={2}>
                    <Col width="100%">
                        <Field label="Surat Keputusan" name="dokumen_keputusan" component={RenderUploadFile} />
                    </Col>
                </Row>
                {/* </> */}
                {/* ) : null} */}
                <Row justifyContent="flex-end" spacing={2} padding="30px 0 25px 0px">
                    <CustomButton onClick={() => dispatch(hideModal('FormPemberianAnumerta'))} variant="outlined">
                        Batal
                    </CustomButton>
                    <CustomButton disabled={pristine || submitting} loading type="submit" variant="contained">
                        Simpan Data
                    </CustomButton>
                </Row>

            </Box>
        </form>
    );
};

const mapState = (state) => {
    let modal = state.utility.modal;

    console.log(modal.data)

    return {
        isEdit: modal.isEdit,
        initialValues: {
            id: modal?.data?.id,
            no_sk: modal?.data?.no_sk,
            tanggal_sk: modal?.data?.tanggal_sk ? formatDate(modal?.data?.tanggal_sk) : null,
            nip: modal?.data?.nip,
            gelar_terakhir: modal?.data?.gelar_asal?.kd_tkt_pdln,
            gelar_kenaikan: modal?.data?.gelar_usulan?.kd_tkt_pdln,
            // perwakilan: modal?.data?.kd_perwakilan?.kd_unker,
            no_keputusan: modal?.data?.no_keputusan,
            // kd_jabatan: modal?.data?.kd_jabatan?.kd_jabatan,
            tanggal_keputusan: formatDate(modal?.data?.tanggal_keputusan),
            tmt_gelar: formatDate(modal?.data?.tmt_gelar),
            sk_gelar_terakhir: modal?.data?.no_sk_gelar_terakhir
        }
    };

};
const connector = connect(mapState);
const userRole = localStorage.getItem('userRole');
const config = {
    form: 'FormPemberianAnumerta',
    enableReinitialize: true,
    destroyOnUnmount: true,
    validate: userRole === "Admin AJGP" ? validasiPemberianAnumertaAjgp : validasiPemberianAnumerta
};

export default connector(reduxForm(config)(FormPemberianAnumerta));
