const validasiPemberianDubesDewatap = (value, props) => {
    const errors = {};

    // if (!value.no_sk) {
    //     errors.no_sk = 'No SK wajib diisi';
    // }

    // if (!value.tgl_sk || value.tgl_sk === 'Invalid date') {
    //     errors.tgl_sk = 'Tanggal SK wajib diisi';
    // }

    if (!value.nip) {
        errors.nip = 'NIP wajib dipilih';
    }

    if (!value.gelar_kenaikan) {
        errors.gelar_kenaikan = 'Gelar kenaikan wajib diisi';
    }

    if (!value.kd_jabatan) {
        errors.kd_jabatan = 'Jabatan wajib diisi';
    }

    if (!value.tmt_gelar) {
        errors.tmt_gelar = 'Tmt Gelar wajib diisi';
    }

    if (!value.perwakilan) {
        errors.perwakilan = 'Perwakilan wajib diisi';
    }

    if (!value.no_keputusan) {
        errors.no_keputusan = 'No Surat Keputusan wajib diisi';
    }

    if (!value.tgl_keputusan || value.tgl_keputusan === 'Invalid date') {
        errors.tgl_keputusan = 'Tanggal Surat Keputusan wajib diisi';
    }

    if (!value.tgl_keputusan) {
        errors.tgl_keputusan = 'Tanggal Suart Keputusan wajib diisi';
    }

    if (!props.isEdit && !value.dokumen_keputusan) {
        errors.dokumen_keputusan = 'Surat Keputusan wajib diisi';
    }

    return errors;
};

export default validasiPemberianDubesDewatap;
