/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { Grid, Stack, Typography } from '@mui/material';
import { IconDownload } from '@tabler/icons';
import ButtonUpload from 'ui-component/button/ButtonUpload';
import Buttons from 'ui-component/button/Buttons';
import ButtonUploaded from 'ui-component/button/ButtonUploaded';

const StackSignedBasah = ({ onClickForm, payload, setPayload, handleClick, handleDelete }) => (
    <Stack alignItems="center" spacing={3} sx={{ width: '100%' }}>
        <Grid textAlign="right">
            <Buttons title="Unduh Form" onClick={onClickForm} startIcon={<IconDownload size="16px" />} outlined />
        </Grid>
        <Stack direction="column" spacing={0.5} sx={{ width: '100%' }}>
            <Typography textAlign="center">Masukkan Dokumen dengan Tanda Tangan Basah</Typography>
            {!payload.dokumen_ttd_basah ? (
                <ButtonUpload payload={payload} setPayload={setPayload} file="dokumen_ttd_basah" />
            ) : (
                <ButtonUploaded handleClick={handleClick} handleRemove={handleDelete} name={payload.dokumen_ttd_basah.name} />
            )}
        </Stack>
    </Stack>
);

export default StackSignedBasah;
