import { Tooltip, Typography } from '@mui/material';
import {
    IconCertificate,
    IconCertificate2,
    IconColorSwatch,
    IconFileInvoice,
    IconLayoutDashboard,
    IconLicense,
    IconSettings,
    IconUserCheck,
    IconUsers
} from '@tabler/icons';
import { IconUserCog } from '@tabler/icons-react';

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const sidebar = {
    id: 'sidebar',
    // title: "sidebar",
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: IconLayoutDashboard,
            url: '/dashboard',
            roles: [
                'Super Admin',
                'Admin Satker',
                'Admin Formasi',
                'Admin AJGP',
                'Admin BSDM',
                'Kasubag BSDM',
                'Kabag BSDM',
                'Karo BSDM',
                'Admin Itwil',
                'Admin Pusbinjabfung',
                'Admin Rokeu',
                'Admin Tu',
                'SET DITJEN'
            ]
        },
        {
            id: 'jabatan-fungsional',
            type: 'collapse',
            title: 'Jabatan Fungsional',
            icon: IconCertificate2,
            roles: ['Super Admin', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP', 'Admin Satker', 'SET DITJEN'],
            children: [
                {
                    id: 'pengangkatan-pertama',
                    title: 'Pengangkatan Pertama',
                    type: 'item',
                    url: '/jabatan-fungsional/pengangkatan-pertama',
                    roles: ['Super Admin', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP']
                },
                {
                    id: 'kenaikan-jenjang',
                    title: 'Kenaikan Jenjang',
                    type: 'item',
                    url: '/jabatan-fungsional/kenaikan-jenjang',
                    roles: ['Super Admin', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP', 'Admin Satker', 'SET DITJEN']
                },
                {
                    id: 'pemberhentian-fungsional',
                    title: 'Pemberhentian',
                    type: 'collapse',
                    roles: ['Super Admin', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP'],
                    children: [
                        {
                            id: 'pemberhentian-karena-struktural',
                            title: 'Karena Struktural',
                            type: 'item',
                            url: '/jabatan-fungsional/pemberhentian/karena-struktural',
                            roles: ['Super Admin', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP']
                        },
                        {
                            id: 'pemberhentian-karena-cltn',
                            title: 'Karena CLTN',
                            type: 'item',
                            url: '/jabatan-fungsional/pemberhentian/karena-cltn',
                            roles: ['Super Admin', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP']
                        },
                        {
                            id: 'pemberhentian-karena-tubel',
                            title: 'Karena Tubel',
                            type: 'item',
                            url: '/jabatan-fungsional/pemberhentian/karena-tubel',
                            roles: ['Super Admin', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP']
                        },
                        {
                            id: 'pemberhentian-karena-hukuman',
                            title: 'Karena Hukuman',
                            type: 'item',
                            url: '/jabatan-fungsional/pemberhentian/karena-hukuman',
                            roles: ['Super Admin', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP']
                        }
                    ]
                },
                {
                    id: 'pengangkatan-kembali',
                    title: 'Pengangkatan Kembali',
                    type: 'collapse',
                    roles: ['Super Admin', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP'],
                    children: [
                        {
                            id: 'pengangkatan-kembali-berhenti-struktural',
                            title: 'Berhenti Struktural',
                            type: 'item',
                            url: '/jabatan-fungsional/pengangkatan-kembali/berhenti-struktural',
                            roles: ['Super Admin', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP']
                        },
                        {
                            id: 'pengangkatan-kembali-selesai-cltn',
                            title: 'Selesai CLTN',
                            type: 'item',
                            url: '/jabatan-fungsional/pengangkatan-kembali/selesai-cltn',
                            roles: ['Super Admin', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP']
                        },
                        {
                            id: 'pengangkatan-kembali-selesai-tubel',
                            title: 'Selesai Tubel',
                            type: 'item',
                            url: '/jabatan-fungsional/pengangkatan-kembali/selesai-tubel',
                            roles: ['Super Admin', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP']
                        }
                    ]
                }
            ]
        },
        {
            id: 'jabatan-struktural',
            type: 'collapse',
            title: 'Jabatan Struktural',
            icon: IconCertificate,
            roles: ['Super Admin', 'Admin Satker', 'Admin AJGP', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM'],
            children: [
                {
                    id: 'pengangkatan-adp',
                    title: (
                        <Tooltip title="Pengangkatan Administrasi & Pengawas">
                            <Typography noWrap>Pengangkatan Administrasi & Pengawas</Typography>
                        </Tooltip>
                    ),
                    type: 'item',
                    url: '/jabatan-struktural/pengangkatan-administrator-pengawas',
                    roles: ['Super Admin', 'Admin Satker', 'Admin AJGP', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                },
                {
                    id: 'pengangkatan-jpt-pratama',
                    title: (
                        <Tooltip title="Pengangkatan JPT Pratama">
                            <Typography noWrap>Pengangkatan JPT Pratama</Typography>
                        </Tooltip>
                    ),
                    type: 'item',
                    url: '/jabatan-struktural/pengangkatan-jpt-pratama',
                    roles: ['Super Admin', 'Admin AJGP', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM']
                },
                {
                    id: 'pengangkatan-jpt-madya',
                    title: (
                        <Tooltip title="Pengangkatan JPT Madya">
                            <Typography noWrap>Pengangkatan JPT Madya</Typography>
                        </Tooltip>
                    ),
                    type: 'item',
                    url: '/jabatan-struktural/pengangkatan-jpt-madya',
                    roles: ['Super Admin', 'Admin AJGP', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                },
                {
                    id: 'pemberhentian-adp',
                    title: (
                        <Tooltip title="Pemberhentian Administrator & Pengawas">
                            <Typography noWrap>Pemberhentian Administrator & Pengawas</Typography>
                        </Tooltip>
                    ),
                    type: 'item',
                    url: '/jabatan-struktural/pemberhentian-administrator-pengawas',
                    roles: ['Super Admin', 'Admin Satker', 'Admin AJGP', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                },
                {
                    id: 'spp-spmt',
                    title: 'SPP & SPMT',
                    type: 'item',
                    url: '/jabatan-struktural/spp-spmt',
                    roles: ['Super Admin', 'Admin Satker', 'Admin AJGP', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                }
                // {
                //     id: 'pengangkatan-struktural',
                //     title: "Pengangkatan",
                //     type: 'item',
                //     url: '/jabatan-struktural/pengangkatan',
                //     roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                // },
                // {
                //     id: 'pemberhentian-struktural',
                //     title: "Pemberhentian",
                //     type: 'item',
                //     url: '/jabatan-struktural/pemberhentian',
                //     roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                // }
            ]
        },
        {
            id: 'pppk',
            type: 'collapse',
            title: 'PPPK',
            icon: IconLicense,
            roles: ['Super Admin', 'Admin Formasi', 'Admin AJGP', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM'],
            children: [
                {
                    id: 'pengangkatan-cpppk',
                    title: 'Pengangkatan CPPPK',
                    type: 'item',
                    url: '/pppk/pengangkatan-cpppk',
                    roles: ['Super Admin', 'Admin Formasi', 'Admin AJGP', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                },
                {
                    id: 'pengangkatan-pppk',
                    title: 'Pengangkatan PPPK',
                    type: 'item',
                    url: '/pppk/pengangkatan-pppk',
                    roles: ['Super Admin', 'Admin Formasi', 'Admin AJGP', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                }
            ]
        },
        {
            id: 'pelantikan',
            type: 'collapse',
            title: 'Pelantikan',
            icon: IconUserCheck,
            roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM'],
            children: [
                {
                    id: 'bap',
                    title: 'BAP',
                    type: 'item',
                    url: '/pelantikan/bap',
                    roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                },
                {
                    id: 'spmt-spp',
                    title: 'SPMT & SPP',
                    type: 'item',
                    url: '/pelantikan/spmt-spp',
                    roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                }
            ]
        },
        {
            id: 'gelar-diplomatik',
            type: 'collapse',
            title: 'Gelar Diplomatik',
            icon: IconFileInvoice,
            roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Admin AJGP', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM'],
            children: [
                {
                    id: 'kenaikan-reguler',
                    title: 'Kenaikan Reguler',
                    type: 'collapse',
                    roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP'],
                    children: [
                        {
                            id: 'atase',
                            title: 'Atase',
                            type: 'item',
                            url: '/gelar-diplomatik/kenaikan-reguler/atase',
                            roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP']
                        },
                        {
                            id: 'selain_atase',
                            title: 'Selain Atase',
                            type: 'item',
                            url: '/gelar-diplomatik/kenaikan-reguler/non-atase',
                            roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP']
                        },
                    ]
                },
                {
                    id: 'promosi',
                    title: 'Promosi',
                    type: 'collapse',
                    roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Admin AJGP', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM'],
                    children: [
                        {
                            id: 'promosi_konsulera',
                            title: 'Promosi Konsul',
                            type: 'item',
                            url: '/gelar-diplomatik/promosi/promosi-konsuler',
                            roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP']
                        },
                        {
                            id: 'promosi_dcm',
                            title: 'Promosi DCM',
                            type: 'item',
                            url: '/gelar-diplomatik/promosi/promosi-dcm',
                            roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Admin AJGP', 'Kasubag BSDM', 'Kabag BSDM']
                        },
                        {
                            id: 'promosi_konjen',
                            title: 'Promosi Konjen',
                            type: 'item',
                            url: '/gelar-diplomatik/promosi/promosi-konjen',
                            roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Admin AJGP', 'Kasubag BSDM', 'Kabag BSDM']
                        },
                        {
                            id: 'promosi_dubes',
                            title: 'Promosi Dubes',
                            type: 'item',
                            url: '/gelar-diplomatik/promosi/promosi-dubes',
                            roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Admin AJGP', 'Kasubag BSDM', 'Kabag BSDM']
                        },
                        {
                            id: 'promosi_tituler',
                            title: 'Promosi Tituler',
                            type: 'item',
                            url: '/gelar-diplomatik/promosi/promosi-tituler',
                            roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Admin AJGP', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                        },
                        {
                            id: 'pemberian_dubes_dewatap',
                            title: 'Promosi Watap Dewatap',
                            type: 'item',
                            url: '/gelar-diplomatik/promosi/gelar-diplomatik-dubes-dewatap',
                            roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Admin AJGP', 'Kasubag BSDM', 'Kabag BSDM']
                        }
                    ]
                },
                {
                    id: 'Pengabdian',
                    title: 'Pengabdian',
                    type: 'item',
                    url: '/gelar-diplomatik/promosi/pengabdian',
                    roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Admin AJGP', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                },
                {
                    id: 'pemberian_anumerta',
                    title: 'Pemberian Anumerta',
                    type: 'item',
                    url: '/gelar-diplomatik/promosi/pemberian-anumerta',
                    roles: ['Super Admin', 'Admin Satker', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM', 'Admin AJGP']
                }
                // {
                //     id: 'efektif-konsul',
                //     title: 'Efektif Promosi Konsul',
                //     type: 'item',
                //     url: '/gelar-diplomatik/efektif-promosi-konsul',
                //     roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                // },
                // {
                //     id: 'efektif-istimewa',
                //     title: 'Efektif Istimewa',
                //     type: 'item',
                //     url: '/gelar-diplomatik/efektif-istimewa',
                //     roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                // },
                // {
                //     id: 'efektif-pengabdian',
                //     title: 'Efektif Pengabdian',
                //     type: 'item',
                //     url: '/gelar-diplomatik/efektif-pengabdian',
                //     roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                // },
                // {
                //     id: 'efektif-promosi-dcm',
                //     title: 'Efektif Promosi DCM',
                //     type: 'item',
                //     url: '/gelar-diplomatik/efektif-promosi-dcm',
                //     roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                // },
                // {
                //     id: 'efektif-promosi-konjen',
                //     title: 'Efektif Promosi Konjen',
                //     type: 'item',
                //     url: '/gelar-diplomatik/efektif-promosi-konjen',
                //     roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                // },
                // {
                //     id: 'efektif-promosi-dubes',
                //     title: 'Efektif Promosi Dubes',
                //     type: 'item',
                //     url: '/gelar-diplomatik/efektif-promosi-dubes',
                //     roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                // },
                // {
                //     id: 'efektif-anumerta',
                //     title: 'Efektif Anumerta',
                //     type: 'item',
                //     url: '/gelar-diplomatik/efektif-anumerta',
                //     roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                // },
                // {
                //     id: 'atase-sekdilu',
                //     title: 'Atase Sekdilu',
                //     type: 'item',
                //     url: '/gelar-diplomatik/atase-sekdilu',
                //     roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                // },
                // {
                //     id: 'dubes-dewatap',
                //     title: 'Dubes Dewatap',
                //     type: 'item',
                //     url: '/gelar-diplomatik/dubes-dewatap',
                //     roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                // }
            ]
        },

        {
            id: 'penugasan-pns',
            type: 'collapse',
            title: 'Penugasan PNS Kemlu',
            icon: IconUsers,
            roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM'],
            children: [
                {
                    id: 'penugasan-pemerintah',
                    title: 'Penugasan Instansi Pemerintah',
                    type: 'item',
                    url: '/penugasan-pns/penugasan-pemerintah',
                    roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                },
                {
                    id: 'penugasan-luar-instansi',
                    title: 'Penugasan Luar Instansi Pemerintah',
                    type: 'item',
                    url: '/penugasan-pns/penugasan-luar-instansi',
                    roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                },
                {
                    id: 'penugasan-nomenklatur',
                    title: 'Penugasan Nomenklatur',
                    type: 'item',
                    url: '/penugasan-pns/penugasan-nomenklatur',
                    roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                },
                {
                    id: 'penugasan-rotasi',
                    title: 'Penugasan Rotasi Promosi Jabatan',
                    type: 'item',
                    url: '/penugasan-pns/penugasan-rotasi',
                    roles: ['Super Admin', 'Admin BSDM', 'Kasubag BSDM', 'Kabag BSDM', 'Karo BSDM']
                }
            ]
        },
        {
            id: 'tunjangan-pengamanan',
            type: 'collapse',
            title: 'Tunjangan Pengamanan',
            icon: IconColorSwatch,
            roles: ['Super Admin'],
            children: [
                {
                    id: 'pemberian-pengamanan',
                    title: 'Pemberian',
                    type: 'item',
                    url: '/tunjangan-pengamanan/pemberian',
                    roles: ['Super Admin']
                },
                {
                    id: 'penyesuaian-pengamanan',
                    title: 'Penyesuaian',
                    type: 'item',
                    url: '/tunjangan-pengamanan/penyesuaian',
                    roles: ['Super Admin']
                },
                {
                    id: 'pemberhentian-pengamanan',
                    title: 'Pemberhentian',
                    type: 'item',
                    url: '/tunjangan-pengamanan/pemberhentian',
                    roles: ['Super Admin']
                }
            ]
        },
        {
            id: 'user-management',
            type: 'collapse',
            title: 'User Management',
            icon: IconUserCog,
            roles: ['Super Admin'],
            children: [
                {
                    id: 'role-list',
                    title: 'Role List',
                    type: 'item',
                    url: '/user/role-list',
                    roles: ['Super Admin']
                },
                {
                    id: 'user-list',
                    title: 'User List',
                    type: 'item',
                    url: '/user/user-list',
                    roles: ['Super Admin']
                }
            ]
        },
        {
            id: 'master',
            type: 'collapse',
            title: 'Master',
            icon: IconSettings,
            roles: ['Super Admin', 'Admin BSDM'],
            children: [
                {
                    id: 'master-jabatan',
                    title: 'Master Jabatan',
                    type: 'item',
                    url: '/master/jabatan',
                    roles: ['Super Admin', 'Admin BSDM']
                },
                {
                    id: 'master-tunjangan',
                    title: 'Master Tunjangan',
                    type: 'item',
                    url: '/master/tunjangan',
                    roles: ['Super Admin', 'Admin BSDM']
                },
                {
                    id: 'master-golongan-pppk',
                    title: 'Master Golongan PPPK',
                    type: 'item',
                    url: '/master/golongan-pppk',
                    roles: ['Super Admin', 'Admin BSDM']
                },
                {
                    id: 'master-template',
                    title: 'Master Template',
                    type: 'item',
                    url: '/master/template',
                    roles: ['Super Admin', 'Admin BSDM']
                }
            ]
        }
    ]
};

export default sidebar;
