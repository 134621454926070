import React from 'react';
import { Grid, Typography } from '@mui/material';
import { IconCircleXFilled, IconCircleCheckFilled } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import { GridContCentered } from 'ui-component/style/grid';

const StatusIndicator = ({ aktif, text }) => {
  const theme = useTheme();

  return (
    
    <Grid item alignItems="center" sx={GridContCentered()} xs={3}>
      <Grid item textAlign="center" mr={1}>
        {aktif ? (
          <IconCircleCheckFilled size="30px" style={{ color: theme.palette.success.dark }} />
        ) : (
          <IconCircleXFilled size="30px" style={{ color: theme.palette.error.main }} />
        )}
      </Grid>
      <Grid item>
        <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#404041' }}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StatusIndicator;
