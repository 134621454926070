import { createContext, useState, useContext } from 'react';

const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
    const [search, setSearch] = useState('');
    const [searchUnit, setSearchUnit] = useState('');

    return <SearchContext.Provider value={{ search, setSearch, searchUnit, setSearchUnit }}>{children}</SearchContext.Provider>;
};
