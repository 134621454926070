// action - state management
import * as actionTypes from '../actions';

const initialState = {
    getAdminPengawas: {
        total: 0,
        list: []
    },
    getPemberhentianAdminPengawas: {
        total: 0,
        list: []
    },
    getJptPratama: {
        total: 0,
        list: []
    },
    getJptMadya: {
        total: 0,
        list: []
    },
};

const getJabatanStrukturalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DATA_ADMIN_PENGAWAS:
            return {
                ...state,
                getAdminPengawas: {
                    list: action.payload.list,
                    total: action.payload.total
                }
            };
        case actionTypes.GET_DATA_PEMBERHENTIAN_ADMIN_PENGAWAS:
            return {
                ...state,
                getPemberhentianAdminPengawas: {
                    list: action.payload.list,
                    total: action.payload.total
                }
            };    
        case actionTypes.GET_DATA_JPT_PRATAMA:
            return {
                ...state,
                getJptPratama: {
                    list: action.payload.list,
                    total: action.payload.total
                }
            };
            case actionTypes.GET_DATA_JPT_MADYA:
                return {
                    ...state,
                    getJptMadya: {
                        list: action.payload.list,
                        total: action.payload.total
                    }
                };
        default:
            return state;
    }
};

export default getJabatanStrukturalReducer;
