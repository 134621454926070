// diplomatik-pemberian-anumerta

import { ToastNotification } from 'components';
import { reset, change } from 'redux-form';
import { GET_DATA_PEMBERIAN_ANUMERTA, hideModal, hideModalKonfirmasi, showLoading, stopLoading } from 'reduxStore';
import { storageKey } from 'reduxStore/constant';
import ApiService from 'services/ApiService';

export const servicePemberianAnumerta = () => {
    const getPemberianAnumerta = (row) => {
        return async (dispatch) => {
            try {
                dispatch(
                    showLoading({
                        table: true
                    })
                );
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` },
                    params: {
                        filter: row.search,
                        page: row.search ? undefined : row.page,
                        limit: row.search ? undefined : row.rowsPerPage,
                        sortBy: 'nip,desc',
                        type: '',
                        module: ''
                    }
                };

                let url = `${process.env.REACT_APP_API_URL}/api/v1/diplomatik-pemberian-anumerta`;
                let result = await ApiService.get(url, config); // Menunggu hasil dari ApiService.get
                dispatch({
                    type: GET_DATA_PEMBERIAN_ANUMERTA,
                    payload: {
                        list: result.data.list,
                        total: result.data.total
                    }
                });

                dispatch(stopLoading());
            } catch (error) {
                dispatch({
                    type: GET_DATA_PEMBERIAN_ANUMERTA,
                    payload: {
                        list: [],
                        total: 0
                    }
                });
                dispatch(stopLoading());
            }
        };
    };

    const removePemberianAnumerta = (id) => {
        return async (dispatch, getState) => {
            try {
                dispatch(
                    showLoading({
                        table: true
                    })
                );
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
                };

                let url = `${process.env.REACT_APP_API_URL}/api/v1/diplomatik-pemberian-anumerta/${id}`;
                await ApiService.delete(url, config);
                dispatch(getPemberianAnumerta(1, 10));
            } catch (error) {
                dispatch(stopLoading());
                dispatch(getPemberianAnumerta(1.1));
            }
        };
    };

    const prosesData = (data, files) => {
        return async (dispatch, getState) => {
            let state = getState();
            let isEdit = state.utility.modal.isEdit;

            if (isEdit) {
                dispatch(edit(data, files));
            } else {
                dispatch(save(data, files));
            }
        };
    };
    const edit = (data, files) => {
        return async (dispatch, getState) => {
            try {
                dispatch(
                    showLoading({
                        button: true
                        
                    })
                );

                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
                        'Content-Type': 'application/json'
                    }
                };

                const bodyRequest = {
                    no_sk: data.no_sk,
                    tanggal_sk: data.tanggal_sk !== null && data.tanggal_sk !== "" ? data.tanggal_sk : null,
                };

                const url = `${process.env.REACT_APP_API_URL}/api/v1/diplomatik-pemberian-anumerta/${data.id}`;
                await ApiService.put(url, bodyRequest, config);

                dispatch(hideModal());
                dispatch(reset('FormPemberianAnumerta'));
                ToastNotification('success', 'Data Berhasil Di Update');
                dispatch(stopLoading());
                dispatch(getPemberianAnumerta(1, 10));
            } catch (error) {
                dispatch(stopLoading());

                ToastNotification('error', error?.response?.data?.message || 'Data Gagal Di Update');
                console.error('Error updating Promosi Dubes:', error);
            }
        };
    };

    const save = (data, files) => {
        return async (dispatch, getState) => {
            try {
                dispatch(
                    showLoading({
                        button: true
                    })
                );

                const formDataFile = new FormData();
                formDataFile.append('nama_table', 'dokumen_keputusan');
                formDataFile.append('files', data.dokumen_keputusan);

                const configFile = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
                        'Content-Type': 'multipart/form-data'
                    }
                };

                const urlFile = `${process.env.REACT_APP_API_URL}/api/v1/filestore`;
                let result = await ApiService.post(urlFile, formDataFile, configFile);
                    
                if (result.status===200) {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
                            'Content-Type': 'application/json'
                        }
                    };

                    const bodyRequest = {
                        type_pengusulan: "Pemberian Anumerta",
                        no_sk: data.no_sk,
                        tanggal_sk: data.tanggal_sk !== null && data.tanggal_sk !== "" ? data.tanggal_sk : null,
                        nip: data.nip,
                        gelar_asal: data.gelar_terakhir,
                        gelar_usulan: data.gelar_kenaikan,
                        kd_perwakilan: data.perwakilan,
                        tmt_gelar: data.tmt_gelar,
                        kd_jabatan: data.kd_jabatan,
                        no_keputusan: data.no_keputusan,
                        tanggal_keputusan: data.tanggal_keputusan,
                        dokumen_keputusan: result?.data?.id,
                        sk_gelar_terakhir: data.sk_gelar_terakhir
                    };

                    const url = `${process.env.REACT_APP_API_URL}/api/v1/diplomatik-pemberian-anumerta`;
                    await ApiService.post(url, bodyRequest, config);

                    dispatch(hideModal());
                    dispatch(reset('FormPemberianAnumerta'));
                    ToastNotification('success', 'Data Berhasil Di Simpan');
                    dispatch(stopLoading());
                    dispatch(getPemberianAnumerta(1, 10)); // Adjust the pagination as needed
                } else {
                    dispatch(stopLoading());

                    ToastNotification('error', result?.message || 'Data Gagal Di Simpan');
                    console.error('Error saving Pemberian Anumerta:', result?.message);
                }
            } catch (error) {
                dispatch(stopLoading());

                ToastNotification('error', error?.response?.data?.message || 'Data Gagal Di Simpan');
                console.error('Error saving Promosi Anumerta:', error);
            }
        };
    };

    const approval = (action, payload) => {
        return async (dispatch, getState) => {
            dispatch(
                showLoading({
                    screen: true
                })
            );
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
                        'Content-Type': 'application/json'
                    }
                };

                await ApiService.put(`${process.env.REACT_APP_API_URL}/api/v1/diplomatik-pemberian-anumerta/approval/${payload.id}`,{
                    "status" : action,
                    "alasan_revisi" : payload.alasan_revisi || "-",
                    "alasan_ditolak" : payload.alasan_ditolak || "-"
                },config)
                dispatch(stopLoading());
                dispatch(getPemberianAnumerta(1, 10));
                dispatch(hideModal())
                dispatch(hideModalKonfirmasi())
            } catch (error) {
                dispatch(stopLoading());

                ToastNotification('error', error?.response?.data?.message || 'Data Gagal Di Update');
                console.error('Error updating Promosi Dubes:', error);
            }
        }
    }

    const getDetailPegawai = (nip) => {
        return async (dispatch) => {
            try {
                dispatch(
                    showLoading({
                        screen: true
                    })
                );
                
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` },
                };

                let url = `${process.env.REACT_APP_API_URL}/api/v1/pegawai/detail-pegawai-diplomatik-promosi/${nip}`;
                let result = await ApiService.get(url, config); // Menunggu hasil dari ApiService.get

                if (result.status===true) {

                    var gelar = result?.data?.gelar?.data_gelar?.kd_tkt_pdln
                    const lastTwoDigits = gelar.slice(-2);

                    var newCode = null;

                    // Cek apakah angka terakhir lebih besar dari 01
                    if (parseInt(lastTwoDigits) > 1) {
                        // Kurangi angka terakhir dengan 1 dan pastikan tetap dua digit
                        const newLastTwoDigits = String(parseInt(lastTwoDigits) - 1).padStart(2, '0');
                        newCode = gelar.slice(0, -2) + newLastTwoDigits;
                    } else {
                        newCode = gelar;
                    }
                    dispatch(change("FormPemberianAnumerta", 'sk_gelar_terakhir', result?.data?.no_sk_gelar_terakhir || ""))
                    dispatch(change("FormPemberianAnumerta", 'gelar_terakhir', result?.data?.gelar?.data_gelar?.kd_tkt_pdln || ""))
                    dispatch(change("FormPemberianAnumerta", 'gelar_kenaikan', newCode))
                    // dispatch(change("FormPemberianAnumerta", 'perwakilan', result?.data?.unit_kerja?.kd_unker || ""))
                    // dispatch(change("FormPemberianAnumerta", 'kd_jabatan', result?.data?.unit_kerja?.kd_unker+"D"+result?.data?.jabatan?.kd_fungsional || ""))
                    console.log(result?.data?.unit_kerja?.kd_unker+"D"+result?.data?.jabatan?.kd_fungsional)
                    // dispatch(change("FormPromosiDCM", 'tmt_gelar', result?.data?.gelar?.tmt_sk ? formatDate(result?.data?.gelar?.tmt_sk) :  ""))
                }


                dispatch(stopLoading());
            } catch (error) {
                console.log("error"+ error);
                dispatch(stopLoading());
            }
        };
    };

    const uploadSK = (data) => {
        return async (dispatch, getState) => {
            try {
                dispatch(
                    showLoading({
                        button: true
                    })
                );

                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
                        'Content-Type': 'multipart/form-data'
                    }
                };

                const formData = new FormData();

                if(data.dokumen_sk_ttd){
                    formData.append('files', data.dokumen_sk_ttd);


                    const url = `${process.env.REACT_APP_API_URL}/api/v1/diplomatik-pemberian-anumerta/upload/sk/${data.id}`;
                    let result = await ApiService.put(url, formData, config);

                    dispatch(hideModal());
                    dispatch(reset('FormPemberianAnumerta'));
                    dispatch(stopLoading());
                    dispatch(getPemberianAnumerta(1, 10));
                    if (result.status===200) {
                        ToastNotification('success', result?.message || 'Data Berhasil Diupdate');
                    } else {
                        ToastNotification('error', result?.message || 'Data Gagal Diupdate');
                    }
                }else{
                    ToastNotification("info","Dokumen Belum Dipilih")
                }
            } catch (error) {
                dispatch(stopLoading());

                ToastNotification('error', error?.response?.data?.message || 'Data Gagal Diupdate');
                console.error('Error updating Pengabdian:', error);
            }
        }
    }

    return {
        removePemberianAnumerta,
        prosesData,
        getPemberianAnumerta,
        approval,
        getDetailPegawai,
        uploadSK
    };
};
