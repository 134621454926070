import { useTheme } from '@emotion/react';
import { Button, Chip } from '@mui/material';
import { borderRadius } from '@mui/system';
import React from 'react';

const ButtonStatus = ({ status = '', type = '' }) => {
    const theme = useTheme();

    return type === 'Button' ? (
        <Button
            variant="contained"
            size="small"
            style={{ color: '#FFFFFF' }}
            sx={{
                fontSize: '12px',
                backgroundColor:
                    status === 0
                        ? '#4F57FE'
                        : status === '1'
                        ? '#408BFC'
                        : status === '2'
                        ? '#EEBA56'
                        : status === '3'
                        ? '#3BB44A'
                        : '#67D064'
            }}
            color={
                status === 0 ? 'primary' : status === '1' ? 'primary' : status === '2' ? 'warning' : status === '3' ? 'success' : 'success'
            }
        >
            {status === 0 ? 'Proses' : status === '1' ? 'Revisi' : status === '2' ? 'Ditolak' : status === '3' ? 'Diterima' : 'Selesai'}
        </Button>
    ) : (
        <Chip
            label={status || ''}
            sx={{
                width: 'fit-content',
                borderRadius: borderRadius,
                color: 'white',
                fontWeight: 550,
                backgroundColor:
                    status === 'Pengajuan' || status === 'Pengusulan' || status.includes("Permintaan Revisi")
                        ? theme.palette.status.warning
                        : status.includes("Ditolak")
                        ? '#FF0000'
                        : status.includes("Draft")
                        ? '#D3D3D3'
                        : status === 'Selesai'
                        ? theme.palette.status.success
                        : theme.palette.status.info
            }}
        />
    );
};

export default ButtonStatus;
