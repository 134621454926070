/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CardContent,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import PaginationFunc from 'components/function/PaginationFunc';
import SortColumnFunc from 'components/function/SortColumn';
import SearchPackages from 'ui-component/search/SearchPackages';
import { ButtonStyle1 } from 'ui-component/style/button';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { TableCells } from 'ui-component/style/table/TableStyle';
import TableHeightFunc from 'components/function/TableHeightFunc';
import useDebounce from 'hooks/useDebounce';
import { useSelector } from 'react-redux';

const DataTable = ({ dataTable, columns, totalDataCount, onChangePage, minWidth = 320, handleSearch, handleClickButton, titleButton }) => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderBy, setOrderBy] = useState('name');
    const { handleChangePage, handleChangeRowsPerPage } = PaginationFunc({ setPage, setRowsPerPage });
    const { stableSort, getComparator, order } = SortColumnFunc({ orderBy, setOrderBy });
    const utility = useSelector((state) => state.utility);

    const changePage = (eventOrPage, status) => {
        if (status === 'ROWPAGE') {
            const newRowsPerPage = parseInt(eventOrPage.target.value, 10);
            setRowsPerPage(newRowsPerPage);
            setPage(0);
            handleChangeRowsPerPage(eventOrPage);
            onChangePage({ page: page || 0, rowsPerPage: newRowsPerPage });
        } else {
            const newPage = eventOrPage;
            setPage(newPage);
            handleChangePage(null, newPage);
            onChangePage({ page: newPage || 1, rowsPerPage });
        }
    };

    const { containerHeight } = TableHeightFunc({ dataTable });

    const [search, setSearch] = useState('');
    const searchDataBounche = useDebounce(search, 300); // Menggunakan debounce untuk search

    useEffect(() => {
        if (onChangePage) {
            if (searchDataBounche !== '') {
                handleSearch({ page: page || 0, rowsPerPage: rowsPerPage, search: search });
            } else {
                handleSearch({ page: page || 0, rowsPerPage: rowsPerPage, search: '' });
            }
        }
    }, [searchDataBounche]);

    return (
        <>
            <CardContent>
                <SearchPackages
                    filter={['search']}
                    search={search}
                    setSearch={(e) => setSearch(e)}
                    children2={
                        handleClickButton && (
                            <Grid item>
                                <Button sx={ButtonStyle1} onClick={() => handleClickButton()}>
                                    <AddBoxRoundedIcon fontSize="small" sx={{ marginRight: '5px' }} /> {titleButton || 'Tambah Data'}
                                </Button>
                            </Grid>
                        )
                    }
                />
            </CardContent>

            <TableContainer id="tableContainer" sx={{ height: containerHeight, overflowY: 'auto', paddingBottom: 2 }}>
                <Table sx={{ minWidth: minWidth, justifyContent: 'space-between' }}>
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 100 }}>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCells key={index} align="center" sx={{ pl: 3 }}>
                                    {column.label}
                                </TableCells>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {utility?.loading?.table ? (
                            <>
                                <TableRow
                                    style={{
                                        height: '25vh',
                                        position: 'relative' // Pastikan parent element memiliki position: relative
                                    }}
                                >
                                    <TableCell colSpan={columns.length}>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%'
                                            }}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </>
                        ) : dataTable && dataTable.length > 0 ? (
                            stableSort(dataTable, getComparator(order, orderBy)).map((row, index) => (
                                <TableRow hover key={index}>
                                    {columns.map((column) => (
                                        <TableCell key={column.field} align="center">
                                            {column.formatter
                                                ? column.formatter(row?.[column.field], row, index)
                                                : row?.[column.field] || ''}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow style={{ height: '50vh' }}>
                                <TableCell colSpan={columns.length} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography fontWeight={700}>Data tidak tersedia</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Table Pagination */}
            <TablePagination
                component="div"
                page={page}
                rowsPerPage={rowsPerPage}
                count={totalDataCount || 0 || dataTable?.length}
                sx={{ mr: 3 }}
                rowsPerPageOptions={[5, 10, 25]}
                onPageChange={(_e, newPage) => changePage(newPage, 'PAGE')}
                onRowsPerPageChange={(e) => changePage(e, 'ROWPAGE')}
                labelRowsPerPage="Baris per halaman:"
                showFirstButton
                showLastButton
            />
        </>
    );
};

export default DataTable;
