import { Backdrop, Card, CardContent, Fade, Modal, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { IconLock } from '@tabler/icons';
import { ModalStyle1 } from 'ui-component/style/modal';

import RoundIconCard from 'ui-component/card/RoundIconCard';
import ButtonBatalOnModal from 'ui-component/button/ButtonBatalOnModal';

const ForgotPassword = ({ open, handleClose }) => {
    const theme = useTheme();

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={open}>
                <Card sx={ModalStyle1()}>
                    <CardContent>
                        <Stack alignItems="center" spacing={3}>
                            <Stack alignItems="center">
                                <RoundIconCard iconPrimary={IconLock} color={theme.palette.secondary.light} />
                                <Typography variant="h5" sx={{ color: 'rgba(57, 57, 57, 0.5)', paddingTop: 1, fontWeight: 400 }}>
                                    Lupa Kata Sandi?
                                </Typography>
                            </Stack>

                            <Typography variant="h5" fontWeight="700">
                                Silahkan menghubungi Helpdesk Pustik KP ext. 5555
                            </Typography>

                            <ButtonBatalOnModal tutup onClick={handleClose} />
                        </Stack>
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    );
};

export default ForgotPassword;
