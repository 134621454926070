import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import accountReducer from './accountReducer';
import utilityReducer from './utilityReducer';
import gelarDiplomatikReducer from './gelarDiplomatik';
import { reducer as formReducer } from 'redux-form';
import getJabatanStrukturalReducer from './jabatan-struktural';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    form: formReducer,
    customization: customizationReducer,
    snackbar: snackbarReducer,
    utility: utilityReducer,
    gelarDiplomatik: gelarDiplomatikReducer,
    getJabatanStruktural: getJabatanStrukturalReducer,
    account: persistReducer(
        {
            key: 'data',
            storage,
            keyPrefix: 'esk@'
        },
        accountReducer
    )
});

export default reducer;
