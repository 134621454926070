import React from 'react';
import Stack from '@mui/material/Stack';

const Row = ({ spacing = 2, children, ...props }) => {
    return (
        <Stack direction="row" padding="15px 0 0px 0px" spacing={spacing} {...props}>
            {children}
        </Stack>
    );
};

const Col = ({ width = '100%', children, ...props }) => {
    return (
        <Stack width={width} {...props}>
            {children}
        </Stack>
    );
};

export { Row, Col };
