import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import reducer from './reducer';
import thunk from 'redux-thunk';

// ==============================|| REDUX - MAIN STORE ||============================== //

// const store = createStore(reducer)
const store = createStore(
    reducer,
    compose(
        applyMiddleware(thunk),
        typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'undefined'
            ? (a) => a
            : window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);
const persister = persistStore(store);

export * from './actions/utilityAction/index';
export * from './actions';

export { store, persister };
