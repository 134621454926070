import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persister } from './reduxStore';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import 'assets/scss/style.scss';
import config from 'config';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
// ==============================|| REACT DOM RENDER  ||============================== //

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
