import React from 'react';
import MainCard from 'ui-component/card/MainCard';
import { useDispatch, useSelector } from 'react-redux';
import FormPemberianDubesDewatap from './form';
import { servicePemberianDubesDewatap } from 'services/api/gelar-diplomatik/pemberian-dubes-dewatap';
import { convertDate, openModal } from 'utils';
import { ActionsTable, DataTable, ButtonStatus, ModalGlobal } from 'components';
import ViewPemberianDubesDewatap from './ViewPemberianDubesDewatap';
const service = servicePemberianDubesDewatap();

const PemberianDubesDewatap = () => {
    const role = localStorage.getItem('userRole');
    const dispatch = useDispatch();
    const columns = [
        {
            label: 'No',
            field: 'No',
            formatter: (_cell, _row, index) => <span>{index + 1}</span>
        },
        {
            label: 'NIP',
            field: 'nip'
        },
        {
            label: 'Nama Pegawai',
            field: 'nama',
            formatter: (_cell, row) => <>{row?.pegawai?.nama || '-'}</>
        },

        {
            label: 'Gelar Kenaikan',
            field: 'gelar_kenaikan',
            formatter: (_cell, row) => <>{row?.gelar_kenaikan?.gelar_pdln || '-'}</>
        },
        {
            label: 'Perwakilan',
            field: 'perwakilan',
            formatter: (_cell, row) => <>{row?.perwakilan?.nama_unker || '-'}</>
        },
        {
            label: 'TMT Gelar',
            field: 'tmt_gelar',
            formatter: (cell) => <>{cell ? convertDate(cell) : '-'}</>
        },
        {
            label: 'Status',
            field: 'status',
            formatter: (_, row) => (
                <div>
                    <ButtonStatus status={role === 'Admin AJGP' && row?.status?.status === 'Disetujui Kabag' ? 'Upload SK' : row?.status?.status || '-'} />
                </div>
            )
        },

        {
            label: 'Aksi',
            field: 'action',
            formatter: (_, row) => {
                return (
                    <ActionsTable
                        handleClickView={() =>
                            dispatch(service.getPemberianDetailDubesDewatap(row.id))
                        }
                        handleClickEdit={() =>
                            dispatch(
                                openModal({
                                    isEdit: true,
                                    data: row,
                                    form: 'FormPemberianDubesDewatap',
                                    title: 'Edit Data'
                                })
                            )
                        }
                        handleClickDelete={() => dispatch(service.removePemberianDubesDewatap(row.id))}
                    />
                );
            }
        }
    ];

    const data = useSelector((state) => state.gelarDiplomatik.getPemberianDubesDewatap);

    const modal = useSelector((state) => state.utility.modal);

    return (
        <MainCard title="Gelar Diplomatik Promosi Watap Dewatap" content={false}>
            <DataTable
                dataTable={data?.list}
                totalDataCount={data?.total}
                columns={columns}
                titleButton="Tambah Data"
                onChangePage={(value) => {
                    dispatch(service.getPemberianDubesDewatap(value));
                }}
                handleSearch={(value) => {
                    dispatch(service.getPemberianDubesDewatap(value));
                }}
                handleClickButton={
                    role === 'Super Admin' || role === 'Admin BSDM' || role === 'Admin AJGP' ? (
                    () => 
                    dispatch(
                        openModal({
                            title: 'Tambah Data',
                            form: 'FormPemberianAnumerta'
                        })
                    )
                    ) : null
                }
                minWidth={1300}
            />
            <ModalGlobal title={modal.title}>
                {modal.form === 'ViewPemberianDubesDewatap' ? <ViewPemberianDubesDewatap /> : <FormPemberianDubesDewatap />}
            </ModalGlobal>
        </MainCard>
    );
};

export default PemberianDubesDewatap;
