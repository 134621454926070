/* eslint-disable no-unused-vars */
import { Grid, InputAdornment, OutlinedInput } from '@mui/material';
import { IconSearch } from '@tabler/icons';
import { memo, useCallback, useEffect, useState } from 'react';
import { TextFieldStyle1, TextFieldStyle2 } from 'ui-component/style/textfield';

const FilterSearch = ({ search, setSearch }) => {
    // const [tempSearch, setTempSearch] = useState(search);

    // useEffect(() => {
    //     const handler = setTimeout(() => {
    //         setSearch(tempSearch);
    //     }, 500); // Delay 500ms

    //     return () => {
    //         clearTimeout(handler);
    //     };
    // }, [tempSearch, setSearch]);

    // const handleSearch = (event) => {
    //     const newString = event?.target.value;
    //     setTempSearch(newString || '');
    // };

    // const handleChange = (e) => {
    //     setTempSearch(e.target.value);
    // };

    const handleSearchChange = useCallback(
        (e) => {
            setSearch(e.target.value);
        },
        [setSearch]
    );

    return (
        <Grid item sx={{ width: '200px' }}>
            <OutlinedInput
                size="small"
                type="search"
                placeholder="Cari"
                // value={tempSearch}
                // onChange={(e) => setSearch(e.target.value)}
                value={search}
                onChange={handleSearchChange}
                startAdornment={
                    <InputAdornment position="start">
                        <IconSearch stroke={1.5} size="1rem" />
                    </InputAdornment>
                }
                sx={TextFieldStyle2}
            />
        </Grid>
    );
};

export default memo(FilterSearch);
