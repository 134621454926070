import styled from '@emotion/styled';
import { TableCell, TableRow, TableSortLabel, tableCellClasses } from '@mui/material';

export const TableCells = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#E5E7EC',
        color: 'black'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12
    }
}));
export const StyledTableCellPrimary = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#31708F',
        color: 'white'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12
    }
}));

export const TableRows = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
        backgroundColor: 'rgba(229, 231, 236, 1)',
        color: 'rgba(57, 57, 57, 1)'
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

export const StyledTableSortLabel = styled(TableSortLabel)(() => ({
    '&.MuiTableSortLabel-root': {
        color: 'white',
        '&:hover': {
            color: 'white'
        },
        '&.MuiTableSortLabel-active': {
            color: 'white',
            '&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active': {
                color: 'white'
            }
        },
        '& .MuiTableSortLabel-icon': {
            color: 'white'
        }
    }
}));
