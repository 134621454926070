/* eslint-disable no-unused-vars */

import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Modal, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { TextFieldStyle1 } from 'ui-component/style/textfield';
import { IconWriting } from '@tabler/icons';
import { StyleTitleOnModal } from 'ui-component/style/typography';
import { ModalStyle1 } from 'ui-component/style/modal';

import ButtonBatalOnModal from 'ui-component/button/ButtonBatalOnModal';
import Buttons from 'ui-component/button/Buttons';

const ModalRevision2 = ({ open, payload, setPayload, handleSubmit, handleClose, loading = false }) => {
    const theme = useTheme();
    const [disable, setDisable] = useState(true);

    const handleDisable = () => {
        if (payload.alasan_revisi === '' || !payload.alasan_revisi) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    };

    useEffect(() => {
        handleDisable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.alasan_revisi]);

    return (
        <Modal open={open}>
            <Card sx={{ ...ModalStyle1(), width: '500px' }}>
                <CardHeader
                    title={<Typography sx={{ ...StyleTitleOnModal(), mb: 0 }}>Revisi Pengajuan</Typography>}
                    sx={{ paddingBottom: 0 }}
                />
                <CardContent>
                    <Stack spacing={2} direction="column">
                        <TextField
                            autoFocus
                            required
                            multiline
                            minRows={4}
                            maxRows={4}
                            type="text"
                            label="Alasan Revisi"
                            value={payload.alasan_revisi}
                            onChange={(e) => setPayload({ ...payload, alasan_revisi: e.target.value })}
                            sx={TextFieldStyle1()}
                        />
                        <Stack direction="row" spacing={1} justifyContent="end" paddingTop={4}>
                            <ButtonBatalOnModal onClick={handleClose} />
                            <Buttons
                                title="Revisi"
                                disable={disable}
                                onClick={() => {
                                    handleSubmit();
                                    handleClose();
                                }}
                                startIcon={
                                    loading ? <CircularProgress size="16px" style={{ color: 'white' }} /> : <IconWriting size="16px" />
                                }
                                sx={{
                                    backgroundColor: theme.palette.warning.dark,
                                    color: 'white',
                                    '&:hover': { backgroundColor: theme.palette.warning.dark, color: 'white' }
                                }}
                            />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default ModalRevision2;
