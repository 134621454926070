import { reset } from 'redux-form';
import { GET_DATA_TMP, LOADING, MODAL, MODAL_KONFIRMASI, CLEAR_DATA_TMP } from 'reduxStore/actions';

export const hideModal = (namaForm) => {
    return (dispatch) => {
        if (namaForm) {
            dispatch(reset(namaForm));
        }

        dispatch({
            type: MODAL,
            payload: {
                data: [],
                isOpen: false,
                isEdit: false,
                title: '',
                form: '',
                disable: false,
                fullscreen: false
            }
        });
    };
};
export const showModalKonfirmasi = (row) => {
    return (dispatch) => {
        dispatch({
            type: MODAL_KONFIRMASI,
            payload: {
                title: row.title || "",
                loading: row.loading || false
            }
        });
    };
};
export const simpanDataTmp = (row) => {
    return (dispatch) => {
        dispatch({
            type: GET_DATA_TMP,
            payload: row
        });
    };
};
export const clearDataTmp = () => {
    return (dispatch) => {
      dispatch({
        type: CLEAR_DATA_TMP,
        payload: {}
      });
    };
  };
export const hideModalKonfirmasi = () => {
    return (dispatch) => {
        dispatch({
            type: MODAL_KONFIRMASI,
            payload: {
                title: "",
                loading: false
            }
        });
    };
};
export const showModal = (row) => {
    return (dispatch) => {
        dispatch({
            type: MODAL,
            payload: {
                data: row.data || [],
                isOpen: row.isOpen || false,
                isEdit: row.isEdit || false,
                title: row.title || '',
                form: row.form || '',
                disable: row.disable || false,
                fullscreen: row.fullscreen || false
            }
        });
    };
};
export const showLoading = (loading) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: {
                table: loading.table || false,
                screen: loading.screen || false,
                button: loading.button || false,
                form: loading.form || false
            }
        });
    };
};
export const stopLoading = () => {
    return async (dispatch) => {
        dispatch({
            type: LOADING,
            payload: {
                table: false,
                screen: false,
                button: false,
                form: false
            }
        });
    };
};
