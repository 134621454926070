import { borderRadius } from 'reduxStore/constant';
import { useTheme } from '@mui/material/styles';

export const IconDeleteStyle = () => {
    const theme = useTheme();

    return {
        color: theme.palette.paper,
        backgroundColor: theme.palette.error.light,
        '&:hover': {
            color: theme.palette.paper,
            backgroundColor: theme.palette.error.main,
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)'
        },
        borderRadius: borderRadius,
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
        padding: '10px'
    };
};

export const IconButtonStyle = () => {
    const theme = useTheme();

    return {
        color: theme.palette.paper,
        backgroundColor: theme.palette.secondary.light,
        '&:hover': {
            color: theme.palette.paper,
            backgroundColor: theme.palette.secondary.light,
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)'
        },
        borderRadius: borderRadius,
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
        padding: '10px'
    };
};

export const ButtonStyle2 = () => {
    const theme = useTheme();

    return {
        color: theme.palette.paper,
        backgroundColor: theme.palette.secondary.light,
        '&:hover': {
            color: theme.palette.paper,
            backgroundColor: theme.palette.secondary.light
        }
    };
};

export const ButtonStyle1 = () => {
    const theme = useTheme();

    return {
        color: '#FFFFFF',
        backgroundColor: theme.palette.secondary.light,
        '&:hover': {
            color: '#FFFFFF',
            backgroundColor: theme.palette.secondary.light,
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)'
        },
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: borderRadius,
        padding: '7px 15px',
        justifyContents: 'flex-end'
    };
};

export const ButtonFileStyle = () => {
    const theme = useTheme();

    return {
        padding: '7px 20px',
        borderRadius: '10px',
        color: theme.palette.paper,
        backgroundColor: '#97B7C7',
        '&:hover': {
            color: theme.palette.paper,
            backgroundColor: '#97B7C7'
        }
    };
};

export const ButtonSuccess = (noShadow) => {
    const theme = useTheme();

    return {
        width: '100%',
        color: 'white',
        backgroundColor: theme.palette.success.dark,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
            boxShadow: noShadow ? 'none' : '0px 0px 20px rgba(0, 0, 0, 0.15)'
        },
        boxShadow: noShadow ? 'none' : '0px 0px 8px rgba(0, 0, 0, 0.1)'
    };
};

export const ButtonWarning = (noShadow) => {
    const theme = useTheme();

    return {
        width: '100%',
        color: 'white',
        backgroundColor: theme.palette.warning.dark,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
            boxShadow: noShadow ? 'none' : '0px 0px 20px rgba(0, 0, 0, 0.15)'
        },
        boxShadow: noShadow ? 'none' : '0px 0px 8px rgba(0, 0, 0, 0.1)'
    };
};

export const ButtonError = (noShadow) => {
    const theme = useTheme();

    return {
        width: '100%',
        color: 'white',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.main,
            boxShadow: noShadow ? 'none' : '0px 0px 20px rgba(0, 0, 0, 0.15)'
        },
        boxShadow: noShadow ? 'none' : '0px 0px 8px rgba(0, 0, 0, 0.1)'
    };
};

export const ButtonInfo = (noShadow) => ({
    width: '100%',
    px: 2,
    color: 'white',
    backgroundColor: 'rgba(64, 139, 252, 1)',
    '&:hover': {
        backgroundColor: 'rgba(64, 139, 252, 1)',
        boxShadow: noShadow ? 'none' : '0px 0px 20px rgba(0, 0, 0, 0.15)'
    },
    boxShadow: noShadow ? 'none' : '0px 0px 8px rgba(0, 0, 0, 0.1)'
});
