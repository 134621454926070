import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FirebaseProvider } from 'contexts/FirebaseContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { theme } from 'themes';
import Routes from 'routes';
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <FirebaseProvider>
                            <Routes />
                        </FirebaseProvider>
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </LocalizationProvider>
    );
};

export default App;
