// const fetchUnit = async () => {
//     setLoading(true);
//     await getWorkingUnit({ page: page, limit: limit, filter: filterUnit })
//         .then((res) => {
//             setUnitList(res.data.list);
//             setLoading(false);
//         })
//         .catch((e) => {
//             console.error(e);
//             setLoading(false);
//         })
//         .finally(() => setLoading(false));
// };

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Field } from 'redux-form';
import ReduxFormAutocomplete from '../field/renderSelect'; // Adjust the path as needed
import useDebounce from 'hooks/useDebounce';
import Loader from 'ui-component/Loader';
import { getWorkingUnit } from 'services/api/master/master-data';

const UnitOrganisasiSelector = (props) => {
    const { submitting,readOnly, label = '', onChange, module = 'jabatan-fungsional', type = 'pengangkatan-pertama',required = false, disabled = false } = props;
    const [listNIP, setListNIP] = useState([]);
    const [loading, setloading] = useState(false);
    const [searchBy, setSearch] = useState('');
    const search = useDebounce(searchBy, 300);
    const [page, setpage] = useState(1);

    const fetachData = useCallback(async () => {
        setloading(true);
        try {
            if (listNIP.length > 0 && listNIP.length % 20 !== 0) {
                setloading(false);
                return;
            }

            const res = await getWorkingUnit({
                page: page,
                limit: 20,
                search: search,
                type: type,
                module: module
            });

            if (res && res.status) {
                const newData = res.data.list;
                if (newData.length >= 20) {
                    setpage((prevPage) => prevPage + 1);
                }
                setListNIP((prevList) => {
                    const newItems = newData.filter((newItem) => !prevList.some((prevItem) => prevItem.nip === newItem.nip));
                    return [...prevList, ...newItems];
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    }, [listNIP.length, page, search]);

    useEffect(() => {
        fetachData();
    }, [search]);

    const handleScroll = useCallback(
        (event) => {
            const listboxNode = event.currentTarget;
            const position = listboxNode.scrollTop + listboxNode.clientHeight;
            const isAtBottom = listboxNode.scrollHeight - position <= 1;

            if (isAtBottom && !loading) {
                fetachData();
            }
        },
        [fetachData, loading]
    );

    const handleInputChange = useCallback(
        (e, newInput) => {
            const searchBy = newInput.split('-')[0]?.trim();
            setSearch(searchBy);

            if (onChange) {
                const foundPegawai = listNIP.find((item) => item.nip === searchBy);
                if (foundPegawai) {
                    onChange(foundPegawai);
                }
            }
        },
        [listNIP, onChange]
    );

    const handleClose = useCallback(() => {
        // console.log("Dropdown closed");
        setSearch("");
        setListNIP([])
       setTimeout(() => {
        fetachData();
       }, 300);
    }, [fetachData]);

    return (
        <>
            {loading && <Loader />} {/* Show loader only when loading */}
            <Field
                name="unit_organisasi"
                component={ReduxFormAutocomplete}
                options={listNIP.map((item) => ({
                    label: item?.ket_unker,
                    value: item?.kd_unker
                }))}
                readOnly={readOnly}
                label={`${label || "Unit Organisasi"} ${required ? '*' : ''}`}
                loading={submitting || loading}
                onInputChange={handleInputChange}
                ListboxProps={{
                    onScroll: handleScroll
                }}
                onClose={handleClose}
                disabled={disabled}
            />
        </>
    );
};

export default UnitOrganisasiSelector;
