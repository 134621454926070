import { Grid } from '@mui/material';
import { SearchProvider } from 'components/function/search/SearchContext';

import FilterSearch from './FilterSearch';
import FilterUnitOrg from './FilterUnit';
import ExportExcel from 'ui-component/export/ExportExcel';
import FilterJabatanFungsional from './FilterJabatanFungsional';

const SearchPackages = ({
    filter = ['search', 'searchUnit'],
    search,
    setSearch,
    setSearchUnit,
    children,
    children2,
    setJabatanSelection
}) => {
    return (
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
                <Grid container alignItems="center" spacing={1}>
                    <SearchProvider>
                        {filter.includes('search') && <FilterSearch search={search} setSearch={setSearch} />}
                        {filter.includes('searchUnit') && <FilterUnitOrg setSearchUnit={setSearchUnit} />}
                        {filter.includes('searchJF') && <FilterJabatanFungsional setJabatanSelection={setJabatanSelection} />}
                    </SearchProvider>
                    {children}
                </Grid>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" spacing={1}>
                    {filter.includes('exportExcel') && <ExportExcel />}
                    {children2}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SearchPackages;
