export const CardModalStyle1 = () => ({
    paddingBottom: 3,
    marginBottom: 0,
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    backgroundColor: 'rgba(242, 242, 242, 1)',
    backgroundImage: 'linear-gradient(to right, rgba(64, 64, 65, 0.2) 100%, transparent 0)',
    backgroundPosition: 'left 24px bottom',
    backgroundSize: 'calc(100% - 48px) 1px',
    backgroundRepeat: 'no-repeat'
});

export const CardHeaderModalStyle2 = () => ({
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center'
});

export const ButtonOnModalStyle = () => ({
    paddingBottom: 3,
    paddingRight: 3,
    marginTop: 0,
    position: 'sticky',
    bottom: 0,
    zIndex: 1000,
    backgroundColor: 'rgba(242, 242, 242, 1)'
});
