// action - state management
import * as actionTypes from '../actions';

const initialState = {
    getKenaikanAtase: {
        total: 0,
        list: []
    },
    getKenaikanNonAtase: {
        total: 0,
        list: []
    },
    getPromosiDubes: {
        total: 0,
        list: []
    },
    getPemberianDubesDewatap: {
        total: 0,
        list: []
    },
    getPemberianAnumerta: {
        total: 0,
        list: []
    },
    getPromosiDCM: {
        total: 0,
        list: []
    },
    getPromosiKonjen: {
        total: 0,
        list: []
    },
    getPromosiTituler: {
        total: 0,
        list: []
    },
    getPromosiPengabdian: {
        total: 0,
        list: []
    }
};

const gelarDiplomatikReducer = (state = initialState, action) => {
    // console.log(action)
    switch (action.type) {
        case actionTypes.GET_DATA_PEMBERIAN_ANUMERTA:
            return {
                ...state,
                getPemberianAnumerta: {
                    list: action.payload.list,
                    total: action.payload.total
                }
            };
        case actionTypes.GET_DATA_PROMOSI_DUBES:
            return {
                ...state,
                getPromosiDubes: {
                    list: action.payload.list,
                    total: action.payload.total
                }
            };
        case actionTypes.GET_PEMBERIAN_DUBES_DEWATAP:
            return {
                ...state,
                getPemberianDubesDewatap: {
                    list: action.payload.list,
                    total: action.payload.total
                }
            };
        case actionTypes.GET_DATA_PROMOSI_DCM:
            return {
                ...state,
                getPromosiDCM: {
                    list: action.payload.list,
                    total: action.payload.total
                }
            };    
        case actionTypes.GET_DATA_PROMOSI_KONJEN:
            return {
                ...state,
                getPromosiKonjen: {
                    list: action.payload.list,
                    total: action.payload.total
                }
            };    
        case actionTypes.GET_DATA_PROMOSI_TITULER:
            return {
                ...state,
                getPromosiTituler: {
                    list: action.payload.list,
                    total: action.payload.total
                }
            };    
        case actionTypes.GET_DATA_KENAIKAN_ATASE:
                return {
                    ...state,
                    getKenaikanAtase: {
                        list: action.payload.list,
                        total: action.payload.total
                    }
                };
        case actionTypes.GET_DATA_KENAIKAN_NON_ATASE:
                return {
                    ...state,
                    getKenaikanNonAtase: {
                        list: action.payload.list,
                        total: action.payload.total
                    }
                };
        case actionTypes.GET_DATA_PROMOSI_PENGABDIAN:
                return {
                    ...state,
                    getPromosiPengabdian: {
                        list: action.payload.list,
                        total: action.payload.total
                    }
                };
        case actionTypes.GET_DATA_PROMOSI_KONSULER:
                return {
                    ...state,
                    getPromosiKonsuler: {
                        list: action.payload.list,
                        total: action.payload.total
                    }
                };
        default:
            return state;
    }
};

export default gelarDiplomatikReducer;
