import { borderRadius } from 'reduxStore/constant';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { IconCircleFilled } from '@tabler/icons-react';
import { StackStyleBorder } from 'ui-component/style/stack';

export const StackHeaderOnModalDetail = ({ children, Icon1, Icon2, title, subtitle, sx, onClickSub }) => {
    return (
        <Stack direction="column" sx={{ mb: 3, ...sx }}>
            <Stack direction="column" sx={{ mb: 1.5 }}>
                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            color: 'rgba(64, 64, 65, 0.5)',
                            p: 0,
                            m: 0,
                            mb: 0.5,
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Icon1 size={18} style={{ marginRight: '5px' }} />
                        {title}
                    </Typography>
                    {Icon2 && subtitle && (
                        <Typography
                            sx={{
                                fontWeight: 400,
                                color: 'rgba(49, 113, 144, 1)',
                                p: 0,
                                m: 0,
                                mb: 0.5,
                                fontSize: '13px',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}
                            onClick={onClickSub}
                        >
                            <Icon2 size={18} style={{ marginRight: '5px' }} />
                            {subtitle}
                        </Typography>
                    )}
                </Stack>
                <Divider sx={{ p: 0, m: 0 }} />
            </Stack>
            {children}
        </Stack>
    );
};

export const StackOnModalDetail = ({ title, value }) => {
    return (
        <Stack direction="column" sx={{ width: '100%' }}>
            <Typography sx={{ fontWeight: 500, fontSize: '12px', color: 'rgba(64, 64, 65, 0.5)' }}>{title}</Typography>
            <Typography
                sx={{
                    bgcolor: 'rgba(242, 242, 242, 1)',
                    fontSize: '12px',
                    color: 'rgba(64, 64, 65, 0.5)',
                    fontWeight: 500,
                    py: 1,
                    px: 0.5,
                    borderRadius: borderRadius
                }}
            >
                {value}
            </Typography>
        </Stack>
    );
};

export const StackOnModalDetail2 = ({ title, value, onClickIcon, Icon }) => {
    return (
        <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', width: '50%' }}>
            <Typography
                sx={{
                    m: 0,
                    p: 0,
                    fontWeight: 600,
                    fontSize: '16px',
                    color: 'rgba(64, 64, 65, 0.5)',
                    mr: 0.5
                }}
            >
                {title}
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: 'rgba(64, 64, 65, 1)' }}>{value}</Typography>
            <IconButton size="small" sx={{ m: 0, p: 0, ml: 'auto' }} onClick={onClickIcon}>
                <Icon size={16} style={{ color: 'rgba(197, 197, 197, 1)' }} />
            </IconButton>
        </Stack>
    );
};

export const StackOnModalDetail3 = ({ title, value }) => {
    return (
        <Stack direction="column">
            <Typography sx={{ fontWeight: 500, fontSize: '12px', color: 'rgba(64, 64, 65, 0.5)' }}>{title}</Typography>
            <Typography sx={{ fontWeight: 400, fontSize: '14px', color: 'rgba(64, 64, 65, 1)' }}>{value}</Typography>
        </Stack>
    );
};

export const StackOnModalDetail4 = ({ Icon, title, sub1, sub2, onClick, onClickSub2 }) => {
    return (
        <Stack direction="row" spacing={1} sx={{ ...StackStyleBorder(), cursor: 'pointer' }} onClick={onClick}>
            <Icon size={32} />
            <Stack direction="column">
                <Typography sx={{ fontWeight: 500, fontSize: '14px', color: 'rgba(64, 64, 65, 1)' }}>{title}</Typography>
                <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 400, fontSize: '12px', color: 'rgba(64, 64, 65, 0.5)' }}>{sub1}</Typography>
                    <IconCircleFilled size={2.5} style={{ marginLeft: 10, marginRight: 10 }} />
                    <Typography
                        sx={{ fontWeight: 400, fontSize: '12px', color: 'rgba(64, 64, 65, 0.5)', cursor: 'pointer' }}
                        onClick={onClickSub2}
                    >
                        {sub2}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};
