import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
import { showModal } from 'reduxStore';
import Loader from 'ui-component/Loader';
import ModalForm from 'ui-component/modal/ModalForm';
import { ModalStyle1 } from 'ui-component/style/modal';

const ModalGlobal = ({ children, onClick, fullScreen, title, namaForm }) => {
    const dispatch = useDispatch();
    const utility = useSelector((state) => state.utility);

    const close = () => {
        if (namaForm) {
            dispatch(reset(namaForm));
        }
        dispatch(
            showModal({
                isOpen: false,
                data: []
            })
        );
    };

    return (
        <>
         {utility.loading.screen && <Loader />}
        <ModalForm
            open={utility.modal.isOpen}
            title={title}
            disable={utility.modal.disable}
            loading={utility.loading.form}
            onClick={onClick}
            disableButton={true}
            onClose={() => close()}
            fullScreen={utility.modal.fullscreen}
            sx={
                utility.modal.fullscreen && {
                    ...ModalStyle1(),
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    borderRadius: '0'
                }
            }
        >
            {children}
        </ModalForm>
        </>
    );
};

export default ModalGlobal;
