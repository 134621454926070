import ApiService from 'services/ApiService';
import { GET_PEMBERIAN_DUBES_DEWATAP } from 'reduxStore/actions';
import { storageKey } from 'reduxStore/constant';
import { stopLoading, showLoading, hideModal, hideModalKonfirmasi } from 'reduxStore';
import { ToastNotification } from 'components';
import { reset, change } from 'redux-form';
import { openModal, formatDate } from 'utils';

export const servicePemberianDubesDewatap = () => {
    const getPemberianDubesDewatap = (row) => {
        return async (dispatch) => {
            try {
                dispatch(
                    showLoading({
                        table: true
                    })
                );

                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` },
                    params: {
                        filter: row.search,
                        page: row.search ? undefined : row.page,
                        limit: row.search ? undefined : row.rowsPerPage,
                        sortBy: 'nip,desc',
                        type: '',
                        module: ''
                    }
                };

                let url = `${process.env.REACT_APP_API_URL}/api/v1/promosi-diplomatik/dubes_dewatap`;
                let result = await ApiService.get(url, config); // Menunggu hasil dari ApiService.get
                dispatch({
                    type: GET_PEMBERIAN_DUBES_DEWATAP,
                    payload: {
                        list: result.data.list,
                        total: result.data.total
                    }
                });

                dispatch(stopLoading());
            } catch (error) {
                // console.error('Error fetching data:', error);
                dispatch({
                    type: GET_PEMBERIAN_DUBES_DEWATAP,
                    payload: {
                        list: [],
                        total: 0
                    }
                });
                dispatch(stopLoading());
            }
        };
    };

    const getPemberianDetailDubesDewatap = (id) => {
        return async (dispatch) => {
            try {
                dispatch(
                    showLoading({
                        table: true
                    })
                );

                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
                };

                let url = `${process.env.REACT_APP_API_URL}/api/v1/promosi-diplomatik/dubes_dewatap/${id}`;
                let result = await ApiService.get(url, config);

                if (result.status===true) {
                    dispatch(stopLoading());
                    dispatch(
                        openModal({
                            data: result.data,
                            fullscreen: true,
                            form: 'ViewPemberianDubesDewatap',
                            title: 'Data Pemberian Dubes Dewatap'
                        })
                    )
                } else {
                    ToastNotification('error', result.message);
                    dispatch(stopLoading());
                }
            } catch (error) {
                ToastNotification('error', error);
                dispatch(stopLoading());
            }
        };
    };

    const removePemberianDubesDewatap = (id) => {
        return async (dispatch, getState) => {
            try {
                dispatch(
                    showLoading({
                        table: true
                    })
                );

                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
                };

                let url = `${process.env.REACT_APP_API_URL}/api/v1/promosi-diplomatik/dubes_dewatap/${id}`;
                let result = await ApiService.delete(url, config);
                
                if (result.status===true) {
                    dispatch(getPemberianDubesDewatap(1, 10));
                    ToastNotification('success', result?.message || 'Data Berhasil Di Hapus');
                } else {
                    ToastNotification('error', result?.message || 'Data Gagal Di Hapus');
                    dispatch(stopLoading());
                    dispatch(getPemberianDubesDewatap(1.1));
                }
            } catch (error) {
                ToastNotification('error', 'Data Gagal Di Hapus');
                dispatch(stopLoading());
                dispatch(getPemberianDubesDewatap(1.1));
            }
        };
    };

    const prosesData = (data, files) => {
        return async (dispatch, getState) => {
            let state = getState();
            let isEdit = state.utility.modal.isEdit;

            if (isEdit) {
                dispatch(edit(data, files));
            } else {
                dispatch(save(data, files));
            }
        };
    };

    const edit = (data, files) => {
        return async (dispatch, getState) => {
            try {
                dispatch(
                    showLoading({
                        button: true
                    })
                );
                
                let is_file_object = false;
                let dokumen_keputusan_id;

                if (data.dokumen_keputusan instanceof File) {
                    is_file_object = true;
                } else {
                    is_file_object = false;
                    dokumen_keputusan_id = data.dokumen_keputusan;
                }

                if (is_file_object) {
                    const formDataFile = new FormData();
                    formDataFile.append('nama_table', 'dokumen_keputusan');
                    formDataFile.append('files', data.dokumen_keputusan);
    
                    const configFile = {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    };
    
                    const urlFile = `${process.env.REACT_APP_API_URL}/api/v1/filestore`;
                    let result = await ApiService.post(urlFile, formDataFile, configFile);
                    
                    if (result && result?.status===200) {
                        dokumen_keputusan_id = result?.data?.id;
                    } else {
                        dispatch(stopLoading());

                        ToastNotification('error', result?.message || 'Data Gagal Di Update');
                        console.error('Error updating Promosi Dubes Dewatap:', result?.message);
                    }
                }

                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
                        'Content-Type': 'application/json'
                    }
                };

                const bodyRequest = {
                    no_sk: data.no_sk,
                    tanggal_sk: data.tgl_sk,
                    nip: data.nip,
                    gelar_kenaikan: data.gelar_kenaikan,
                    kd_perwakilan: data.perwakilan,
                    tmt_gelar: data.tmt_gelar,
                    kd_jabatan: data.kd_jabatan,
                    no_keputusan: data.no_keputusan,
                    tanggal_keputusan: data.tgl_keputusan,
                    dokumen_keputusan: dokumen_keputusan_id 
                };

                const url = `${process.env.REACT_APP_API_URL}/api/v1/promosi-diplomatik/dubes_dewatap/update/${data.id}`;
                let result_dubes_dewatap = await ApiService.put(url, bodyRequest, config);
                
                if (result_dubes_dewatap.status === true) {
                    dispatch(hideModal());
                    dispatch(reset('FormPemberianDubesDewatap'));
                    ToastNotification('success', 'Data Berhasil Di Update');
                    dispatch(stopLoading());
                    dispatch(getPemberianDubesDewatap(1, 10));
                } else {
                    dispatch(stopLoading());

                    ToastNotification('error', result_dubes_dewatap?.message || 'Data Gagal Di Update');
                    console.error('Error Error updating Promosi Dubes Dewatap:', result_dubes_dewatap?.message);
                }
            } catch (error) {
                dispatch(stopLoading());

                ToastNotification('error', error?.response?.data?.message || 'Data Gagal Di Update');
                console.error('Error updating Promosi Dubes Dewatap:', error);
            }
        };
    };

    const save = (data, files) => {
        return async (dispatch, getState) => {
            try {
                dispatch(
                    showLoading({
                        button: true
                    })
                );

                const formDataFile = new FormData();
                formDataFile.append('nama_table', 'dokumen_keputusan');
                formDataFile.append('files', data.dokumen_keputusan);

                const configFile = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
                        'Content-Type': 'multipart/form-data'
                    }
                };

                const urlFile = `${process.env.REACT_APP_API_URL}/api/v1/filestore`;
                let result = await ApiService.post(urlFile, formDataFile, configFile);
                    
                if (result.status===200) {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
                            'Content-Type': 'application/json'
                        }
                    };

                    const bodyRequest = {
                        no_sk: data.no_sk,
                        tanggal_sk: data.tgl_sk,
                        nip: data.nip,
                        gelar_kenaikan: data.gelar_kenaikan,
                        kd_perwakilan: data.perwakilan,
                        tmt_gelar: data.tmt_gelar,
                        kd_jabatan: data.kd_jabatan,
                        no_keputusan: data.no_keputusan,
                        tanggal_keputusan: data.tgl_keputusan,
                        dokumen_keputusan: result?.data?.id
                      };
    
                    const url = `${process.env.REACT_APP_API_URL}/api/v1/promosi-diplomatik/dubes_dewatap`;
                    let result_dubes_dewatap = await ApiService.post(url, bodyRequest, config);
                    
                    if (result_dubes_dewatap.status === true) {
                        dispatch(hideModal());
                        dispatch(reset('FormPemberianDubesDewatap'));
                        ToastNotification('success', 'Data Berhasil Di Simpan');
                        dispatch(stopLoading());
                        dispatch(getPemberianDubesDewatap(1, 10)); // Adjust the pagination as needed
                    } else {
                        dispatch(stopLoading());

                        ToastNotification('error', result_dubes_dewatap?.message || 'Data Gagal Di Simpan');
                        console.error('Error saving Promosi Dubes Dewatap:', result_dubes_dewatap?.message);
                    }
                } else {
                    dispatch(stopLoading());

                    ToastNotification('error', result?.message || 'Data Gagal Di Simpan');
                    console.error('Error saving Promosi Dubes Dewatap:', result?.message);
                }
            } catch (error) {
                dispatch(stopLoading());

                ToastNotification('error', error?.response?.data?.message || 'Data Gagal Di Simpan');
                console.error('Error saving Promosi Dubes Dewatap:', error);
            }
        };
    };

    const approval = (action, payload) => {
        return async (dispatch, getState) => {
            try {
                dispatch(
                    showLoading({
                        button: true
                    })
                );

                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
                        'Content-Type': 'application/json'
                    }
                };

                const url = `${process.env.REACT_APP_API_URL}/api/v1/promosi-diplomatik/dubes_dewatap/approval/${payload.id}`;
                let result = await ApiService.put(url, {
                    "status" : action,
                    "alasan_revisi" : payload.alasan_revisi || "-",
                    "alasan_ditolak" : payload.alasan_ditolak || "-"
                }, config);

                dispatch(stopLoading());
                dispatch(getPemberianDubesDewatap(1, 10));
                dispatch(hideModal())
                dispatch(hideModalKonfirmasi())
                if (result.status===true) {
                    ToastNotification('success', result?.message || 'Data Berhasil Dipdate');
                } else {
                    ToastNotification('error', result?.message || 'Data Gagal Dipdate');
                }
            } catch (error) {
                dispatch(stopLoading());
                dispatch(getPemberianDubesDewatap(1, 10));
                dispatch(hideModal())
                dispatch(hideModalKonfirmasi())

                ToastNotification('error', error?.response?.data?.message || 'Data Gagal Dipdate');
                console.error('Error saving Promosi Dubes Dewatap:', error);
            }
        };
    };

    const uploadSK = (data) => {
        return async (dispatch, getState) => {
            try {
                dispatch(
                    showLoading({
                        button: true
                    })
                );

                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
                        'Content-Type': 'multipart/form-data'
                    }
                };

                const formData = new FormData();

                if(data.dokumen_keputusan){
                    formData.append('files', data.dokumen_keputusan);

                    const url = `${process.env.REACT_APP_API_URL}/api/v1/promosi-diplomatik/dubes_dewatap/upload/sk/${data.id}`;
                    let result = await ApiService.put(url, formData, config);

                    dispatch(hideModal());
                    dispatch(reset('ViewPemberianDubesDewatap'));
                    dispatch(stopLoading());
                    dispatch(getPemberianDubesDewatap(1, 10));
                    if (result.status===true) {
                        ToastNotification('success', result?.message || 'Data Berhasil Diupdate');
                    } else {
                        ToastNotification('error', result?.message || 'Data Gagal Diupdate');
                    }
                }else{
                    ToastNotification("info","Dokumen Belum Dipilih")
                }
            } catch (error) {
                dispatch(stopLoading());

                ToastNotification('error', error?.response?.data?.message || 'Data Gagal Diupdate');
                console.error('Error updating Promosi Dubes Dewatap:', error);
            }
        }
    }

    const getDetailPegawai = (nip) => {
        return async (dispatch) => {
            try {
                dispatch(
                    showLoading({
                        screen: true
                    })
                );
                
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` },
                };

                let url = `${process.env.REACT_APP_API_URL}/api/v1/pegawai/detail-pegawai-diplomatik-promosi/${nip}`;
                let result = await ApiService.get(url, config); // Menunggu hasil dari ApiService.get

                if (result.status===true) {
                    dispatch(change("FormPemberianDubesDewatap", 'gelar_terakhir', result?.data?.gelar?.kd_tkt_pdln || ""))
                    dispatch(change("FormPemberianDubesDewatap", 'gelar_kenaikan', "02A1101B01" || ""))
                    // dispatch(change("FormPemberianDubesDewatap", 'jabatan', result?.data?.jabatan?.kd_jabatan || ""))
                    dispatch(change("FormPemberianDubesDewatap", 'tmt_gelar', result?.data?.gelar?.tmt_sk ? formatDate(result?.data?.gelar?.tmt_sk) :  ""))
                }

                dispatch(stopLoading());
            } catch (error) {
                console.log("error "+ error);
                dispatch(stopLoading());
            }
        };
    };

    return { removePemberianDubesDewatap, getPemberianDubesDewatap, prosesData, getPemberianDetailDubesDewatap, uploadSK, approval, getDetailPegawai};
};
