import { storageKey } from 'reduxStore/constant';
import ApiService from 'services/ApiService';

export const getMasterPangkat = async () => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/api/v1/master-data/pangkat-golongan?limit=100`, config);
};
