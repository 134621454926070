import ApiService from 'services/ApiService';
import { storageKey } from 'reduxStore/constant';

export const getAllJF = async ({ page = 1, limit = 10, search = '', unker = '', type = '' }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    let url = `${process.env.REACT_APP_API_URL}/api/v1/master-data/jabatan-fungsional?`;

    if (search) {
        url += `filter=${search}&unit_kerja=${unker}&limit=20&type=${type}`;
    } else if (page && limit) {
        url += `filter=&page=${page}&limit=${limit}&unit_kerja=${unker}&type=${type}`;
    }

    return ApiService.get(url, config);
};
