import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { IconEdit, IconFile } from '@tabler/icons';
import { IconButtonStyle } from 'ui-component/style/button';
import KonfirmasiDelete from 'components/dialog';

const ActionsTable = ({ handleClickEdit, handleClickDelete, handleClickView, status, module }) => {
    const userRole = localStorage.getItem('userRole');
    // const isSuperAdmin = userRole === 'Super Admin';
    // const canEditOrDelete = isSuperAdmin || 
    //     ((['Admin Satker', 'Admin BSDM'].includes(userRole) || 
    //         (userRole === "Admin AJGP" && module !== "nonatase" && module !== "pengabdian" && module !== "anumerta" && 
    //             module !=="pengangkatan_administrator_pengawas" && module !=="pemberhentian_administrator_pengawas")) && 
    //             (status !== null && status !== "Selesai" && (status === "Draft" || status === "Pengusulan" || status === undefined || status.includes("Permintaan Revisi"))));
    const canEditOrDelete = userRole === "Super Admin" || userRole === "Admin Satker" || userRole === "Admin AJGP" ? status === "Draft" || status === "Pengusulan" || status === undefined || status.includes("Permintaan Revisi") : status === undefined || status.includes("Permintaan Revisi");

    return (
        <div>
            <Tooltip title="Preview">
                <IconButton size="small" sx={IconButtonStyle} onClick={() => handleClickView()}>
                    <IconFile color="#FFFFFF" size={20} />
                </IconButton>
            </Tooltip>
            &nbsp;
            {canEditOrDelete && (
                <>
                    <Tooltip placement="top" title="Edit" onClick={() => handleClickEdit()}>
                        <IconButton size="small" sx={IconButtonStyle}>
                            <IconEdit color="#FFFFFF" size={19} />
                        </IconButton>
                    </Tooltip>
                    &nbsp;
                    <KonfirmasiDelete title="" deskripsi="" onClick={() => handleClickDelete()} />
                </>
            )}
        </div>
    );
};

export default ActionsTable;
