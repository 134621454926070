import { useState } from 'react'

const SortColumnFunc = ({ orderBy, setOrderBy, asc }) => {
    const [order, setOrder] = useState(asc ? 'asc' : 'desc')

    const handleRequestSort = property => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const createSortHandler = property => () => {
        handleRequestSort(property)
    }

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedThis.map(el => el[0])
    }

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1
        }
        if (b[orderBy] > a[orderBy]) {
            return 1
        }
        return 0
    }

    const getComparator = (order, orderBy) =>
        order === 'desc' ? (a, b) => -descendingComparator(a, b, orderBy) : (a, b) => descendingComparator(a, b, orderBy)

    return { createSortHandler, stableSort, getComparator, order }
}

export default SortColumnFunc
