/* eslint-disable no-unused-vars */
import { Link } from 'react-router-dom';
import { ButtonBase, Typography } from '@mui/material';
import config from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.homePath}>
        <Typography sx={{ color: 'white', fontWeight: 500, fontSize: '16px' }}>E-SK Administrasi</Typography>
    </ButtonBase>
);

export default LogoSection;
