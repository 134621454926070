import { showModal } from 'reduxStore';

export const openModal = (row = {}) => {
    return async (dispatch) => {
        dispatch(
            showModal({
                isOpen: true,
                isEdit: row?.isEdit || false,
                data: row?.data || {},
                form: row?.form || '',
                title: row?.title || '',
                fullscreen: row?.fullscreen || false
            })
        );
    };
};
