import axios from 'axios';

const ApiService = () => {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`
        }
    });

    instance.interceptors.response.use(
        (res) => res.data,
        (err) => {
            if (err?.response?.status === 422) {
                // window.location.href = '/login';
            }
            if (err?.response?.status === 503) {
                // handle if status code = 503
                console.log('Got Error 503');
            }
            if (err?.response?.status === 500) {
                // handle if status code = 500
                console.log('Got Error 500');
            }
            return Promise.reject(err);
        }
    );
    return instance;
};

export default ApiService();
