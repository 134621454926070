import { borderRadius } from 'reduxStore/constant';
import { useTheme } from '@mui/material/styles';

export const TextFieldStyle = () => {
    const theme = useTheme();

    return {
        width: '100%',
        '& input, & textarea, & .MuiSelect-select, & .MuiAutocomplete-inputRoot': {
            backgroundColor: '#FFFFFF',
            border: 'none'
        },
        '& .MuiOutlinedInput-root': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.secondary.dark
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.secondary.dark
            }
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
        }
    };
};

export const TextFieldStyle1 = () => {
    const theme = useTheme();

    return {
        width: '100%',
        '& input, & textarea, & .MuiSelect-select, & .MuiAutocomplete-inputRoot, & .MuiInputBase-root': {
            backgroundColor: '#FFFFFF',
            border: 'none'
        },
        '& .MuiOutlinedInput-root': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.dark
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.dark
            }
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
        }
    };
};

export const TextFieldStyle2 = () => {
    const theme = useTheme();

    return {
        width: '100%',
        borderRadius: borderRadius,
        backgroundColor: '#FFFFFF',
        '& .MuiOutlinedInput-root, & .MuiOutlinedInput-input, &.MuiOutlinedInput-notchedOutline': {
            backgroundColor: '#FFFFFF',
            '&:hover .MuiOutlinedInput-input': {
                borderColor: theme.palette.primary.dark,
                backgroundColor: '#FFFFFF'
            },
            '&.Mui-focused .MuiOutlinedInput-input': {
                borderColor: theme.palette.primary.dark,
                backgroundColor: '#FFFFFF'
            }
        },
        '& .MuiOutlinedInput-root': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.dark
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.dark
            }
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
        }
    };
};
