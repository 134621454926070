// import { Stack } from '@mui/system'
import { Divider, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import MainCard from 'ui-component/card/MainCard';
import logo from 'assets/images/logo_eSk/logoKemluVertikal.png';

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //

// const AuthCardWrapper = ({ children, ...other }) => (
//     <MainCard
//         sx={{
//             maxWidth: { xs: 400, lg: 375 },
//             margin: { xs: 2.5, md: 3 },
//             '& > *': {
//                 flexGrow: 1,
//                 flexBasis: '50%'
//             },
//             borderTopLeftRadius: '12px',
//             borderTopRightRadius: '12px',
//             background: `linear-gradient(360deg, #31708FB0 0%, #31708F 100%)`
//         }}
//         content={false}
//         {...other}
//     >
//         <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
//     </MainCard>
// );

// AuthCardWrapper.propTypes = {
//     children: PropTypes.node
// };

// export default AuthCardWrapper;

// const AuthCardWrapper = ({ children, ...other }) => (
//     <MainCard content={false} {...other} sx={{ height: '500px', width: { xs: 400, sm: 775 } }}>
//         <Grid container sx={{ height: '100%', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 1)' }}>
//             <Grid
//                 item
//                 xs={12}
//                 sm={5}
//                 sx={{
//                     height: '100%',
//                     backgroundImage: `linear-gradient(360deg, rgba(49, 113, 144, 1), rgba(49, 113, 144, 0)), url(${backgroundlogoV})`,
//                     backgroundSize: 'cover',
//                     backgroundRepeat: 'no-repeat',
//                     backgroundPosition: 'center'
//                 }}
//             >
//                 <CardContent
//                     sx={{
//                         display: 'flex',
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                         height: '100%'
//                     }}
//                 >
//                     <Stack direction="column" sx={{ alignItems: 'center', justifyContent: 'center' }}>
//                         <img src={logo} alt="Kemlu Logo" width="170" />
//                         <Divider orientation="horizontal" variant="middle" sx={{ width: 220, marginY: 2 }} />
//                         <Typography textAlign="center" variant="h2" color="#F6F7F9">
//                             E-Administrasi Jabatan
//                         </Typography>
//                     </Stack>
//                 </CardContent>
//             </Grid>

//             <Grid item xs={12} sm={7}>
//                 <CardContent>{children}</CardContent>
//             </Grid>
//         </Grid>
//     </MainCard>
// );

const AuthCardWrapper = ({children, ...other}) => (
	<MainCard {...other} sx={{minHeight: '100px'}}>
		<Grid item xs={7} sm={12}>
			<Stack direction='column' sx={{alignItems: 'center'}}>
				<Stack
					direction='column'
					sx={{alignItems: 'center', justifyContent: 'center', mb: 2}}>
					<img src={logo} alt='Kemlu Logo' width='200' />
					<Divider orientation='horizontal' variant='middle' sx={{width: 80, marginY: 1}} />
					<Typography textAlign='center' variant='h2' color='#31708F'>
                        E-Administrasi Jabatan
					</Typography>
				</Stack>

				<div>{children}</div>
			</Stack>
		</Grid>
	</MainCard>
)

AuthCardWrapper.propTypes = {
    children: PropTypes.node
};

export default AuthCardWrapper;


