import ApiService from 'services/ApiService';
import { storageKey } from 'reduxStore/constant';

export const getUnitOrganisasi = async ({ filter, page, limit, sortBy }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    const queryParams = new URLSearchParams();

    if (filter) queryParams.append('filter', filter);
    if (page) queryParams.append('page', page);
    if (limit) queryParams.append('limit', limit);
    if (sortBy) queryParams.append('sortBy', sortBy);

    return ApiService.get(`${process.env.REACT_APP_API_URL}/api/v1/unitkerja?${queryParams.toString()}`, config);

    // return ApiService.get(
    //     `${process.env.REACT_APP_API_URL}/api/v1/unitkerja?filter=${filter}&page=${page}&limit=${limit}&sortBy=${sortBy}`,
    //     config
    // );
};
export const getAllUnitKerja = async () => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/api/v1/master-data/unit-kerja?limit=100`, config);
};
