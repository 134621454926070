import React from 'react';
import { Autocomplete, TextField, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const RenderSelect = ({ input, meta, options, handleScroll,onClose, loading, ...props }) => {
    // Function to clear the input value
    const handleClear = () => {
        input.onChange(null);
        onClose()
    //    console.log("MASUK GAIS")
    };

    return (
        <div style={{ position: 'relative' }}> {/* Ensure container is relative */}
            <Autocomplete
                {...props}
                options={options}
                getOptionLabel={(option) => option.label || ''}
                value={options.find((option) => option.value === input.value) || null}
                onChange={(event, newValue) => {
                    input.onChange(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        error={meta.touched && meta.error}
                        helperText={meta.touched && meta.error}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading && <CircularProgress color="inherit" size={20} />}
                                    {params.InputProps.endAdornment}
                                    {input.value && !props.disabled &&(
                                        <IconButton
                                            onClick={handleClear}
                                            edge="end"
                                            aria-label="clear"
                                            size="small"
                                            style={{ position: 'absolute', right:35, top: '50%', transform: 'translateY(-50%)' }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
};

export default RenderSelect;
