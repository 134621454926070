import { borderRadius } from 'reduxStore/constant';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import IconFileTypePdf from 'assets/icons/IconFileTypePdf';

const ButtonUploaded = ({ name, noRemove, handleClick, handleRemove }) => (
    <Stack direction="row" spacing={1}>
        <Button
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            sx={{ borderRadius: borderRadius, alignItems: 'center' }}
            startIcon={<IconFileTypePdf size={16} />}
        >
            <Typography
                variant="body2"
                sx={{
                    cursor: 'pointer',
                    color: 'white',
                    fontWeight: 500,
                    textTransform: 'none',
                    mx: 'auto',
                    alignItems: 'center'
                }}
                onClick={handleClick}
            >
                {name}
            </Typography>
        </Button>
        {noRemove ?? (
            <Button onClick={handleRemove} disableElevation variant="contained" color="error" sx={{ borderRadius: borderRadius }}>
                <IconButton size="small" color="inherit" title="Hapus Dokumen" sx={{ p: 0, m: 0 }}>
                    <IconTrash size="18px" />
                </IconButton>
            </Button>
        )}
    </Stack>
);

export default ButtonUploaded;
