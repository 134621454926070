import { Grid } from '@mui/material';
import Buttons from './Buttons';

const ButtonBatalOnModal = ({ error, onClick, tutup, disabled }) => (
    <Grid textAlign="right">
        <Buttons
            outlined
            type="button"
            color={error ? 'error' : 'primary'}
            onClick={onClick}
            disable={disabled ?? false}
            title={tutup ? 'Tutup' : 'Batal'}
        />
    </Grid>
);

export default ButtonBatalOnModal;
