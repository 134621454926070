/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Field } from 'redux-form';
import ReduxFormAutocomplete from '../field/renderSelect'; // Adjust the path as needed
import { getMasterPerwakilan } from 'services/api/master/master-perwakilan';
import useDebounce from 'hooks/useDebounce';
import Loader from 'ui-component/Loader';

const PerwakilanSelector = (props) => {
    const {
        submitting,
        readOnly,
        label = '',
        getValue,
        module = '',
        type = '',
        required = false,
        input = { value: '', onChange: () => {}, name: 'perwakilan' },
        disabled = false
    } = props;

    // console.log(input.value)
    // console.log(input.value)
    const [search, setSearch] = useState(input.value || input.value?.perwakilan || '');
    const searchData = useDebounce(search, 300);

    const [listData, setListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            // if (listData.length > 0 && listData.length % 20 !== 0) {
            //     setLoading(false);
            //     return;
            // }

            const res = await getMasterPerwakilan({
                page: page,
                limit: limit,
                search: search || input.value || input.value?.perwakilan,
                type: type,
                module: module
            });

            if (res && res.status) {
                const newData = res.data.list;
                // if (newData.length > 20) {
                //     setPage((prevPage) => prevPage + 1);
                // }
                setListData((prevList) => {
                    const newItems = newData.filter((newItem) => !prevList.some((prevItem) => prevItem.kd_unker === newItem.kd_unker));
                    return [...prevList, ...newItems];
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, [listData.length, module, page, searchData, type, limit,input.value]);

    useEffect(() => {
        fetchData();
    }, [searchData,input.value]);

    const handleScroll = useCallback(
        async (event) => {
            const listboxNode = event.currentTarget;
            const position = listboxNode.scrollTop + listboxNode.clientHeight;
            const isAtBottom = listboxNode.scrollHeight - position <= 1;
            if (isAtBottom) {
                setLimit((prevLimit) => prevLimit + 20);
                console.log(limit);
            }
            if (isAtBottom && !loading) {
                await fetchData();
            }
        },
        [setLimit, fetchData, loading]
    );

    const handleInputChange = useCallback(
        (e, newInput) => {
            const search = newInput;
            setSearch(search);
            if (input.onChange) {
                const foundData = listData.find((item) => item?.kd_unker === search);
                if (foundData) {
                    input.onChange(foundData?.kd_unker);
                    getValue(foundData);
                }
            }
        },
        [listData, input.onChange, getValue]
    );

    const handleClose = useCallback(() => {
        console.log('Dropdown closed');
        setLoading(true);
        input.onChange(null);
        getValue(null);
        setSearch('');
        setPage(1);
        setLimit(20);
        setListData([]);
        input.value = null;
        setTimeout(() => {
            fetchData();
        }, 300); // Fetch new data if needed
    }, [fetchData]);

    return (
        <>
            {loading && <Loader />}
            <Field
                name={input.name}
                component={ReduxFormAutocomplete}
                options={listData.map((item) => ({
                    // label: `${item.kd_unker} - ${item.nama_unker}`,
                    label: `${item.nama_unker}`,
                    value: item?.kd_unker
                }))}
                onClose={handleClose}
                readOnly={readOnly}
                label={`${label || 'Perwakilan'} ${required ? '*' : ''}`}
                loading={submitting || loading}
                onChange={handleInputChange}
                ListboxProps={{
                    onScroll: handleScroll
                }}
                disabled={disabled}
            />
        </>
    );
};

export default PerwakilanSelector;
