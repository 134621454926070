import { useEffect, useState } from 'react'

const TableHeightFunc = ({ dataTable }) => {
    const [containerHeight, setContainerHeight] = useState('calc(100vh - 200px)')

    const adjustContainerHeight = () => {
        const tableContainer = document.getElementById('tableContainer')
        if (tableContainer) {
            const windowHeight = window.innerHeight
            const tableHeight = tableContainer.scrollHeight

            if (tableHeight < windowHeight) {
                setContainerHeight('auto')
            } else {
                setContainerHeight('calc(100vh - 200px)')
            }
        }
    }

    useEffect(() => {
        adjustContainerHeight()
    }, [dataTable])

    return { containerHeight }
}

export default TableHeightFunc
