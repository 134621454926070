import { borderRadius } from 'reduxStore/constant';
import { Button, Stack, Typography } from '@mui/material';
import { IconUpload } from '@tabler/icons';
import PropTypes from 'prop-types';

const ButtonUpload = ({ file, payload, setPayload, onChange }) => (
    <Stack alignItems="center">
        <Button
            fullWidth
            component="label"
            disableElevation
            variant="contained"
            type="button"
            startIcon={<IconUpload size="16px" />}
            sx={{ borderRadius: borderRadius }}
        >
            <Typography textAlign="center">Upload File</Typography>
            <input
                required
                type="file"
                accept="application/pdf"
                style={{ display: 'none' }}
                value={payload[file]}
                onChange={(e) => {
                    if (onChange) {
                        onChange(e);
                    } else {
                        setPayload({ ...payload, [file]: e.target.files[0] });
                    }
                }}
            />
        </Button>
    </Stack>
);

ButtonUpload.propTypes = {
    file: PropTypes.string.isRequired
};

export default ButtonUpload;
