/* eslint-disable react/prop-types */
import { Stack } from '@mui/material';
import { StackBorderDashedStyle } from 'ui-component/style/stack';

import StackSignedBasah from './StackSignedBasah';
import StackSignedElectronic from './StackSignedElectronic';

const StackSignedOptions = ({ form, payload, setPayload, handleClick, handleDelete, onClickForm }) => (
    <Stack direction="column" spacing={2}>
        <Stack sx={{ ...StackBorderDashedStyle(), border: 'solid', borderColor: 'lightgray' }}>
            <Stack direction="column" spacing={1.5}>
                {form === 'elektronik' && <StackSignedElectronic payload={payload} setPayload={setPayload} />}
                {form === 'basah' && (
                    <StackSignedBasah
                        onClickForm={onClickForm}
                        payload={payload}
                        setPayload={setPayload}
                        handleClick={handleClick}
                        handleDelete={handleDelete}
                    />
                )}
            </Stack>
        </Stack>
    </Stack>
);

export default StackSignedOptions;
