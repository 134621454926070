// project imports
import NavMotion from 'layout/NavMotion'
import MinimalLayout from 'layout/MinimalLayout'
import AuthGuard from 'utils/route-guard/AuthGuard'
import Login from 'views/pages/authentication/authentication/Login'

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <AuthGuard>
                <MinimalLayout />
            </AuthGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            // element: <Login isUnderMaintenance />
            element: <Login isUnderMaintenance={false} />
        }
    ]
}

export default LoginRoutes
